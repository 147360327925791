import { awaitRequests, promise, objectKeysToArray } from '../../../helpers';
import { getCourseAvailableLicensesBy, getCourseUsedLicensesBy } from '../courses-licenses';
import { getRegionalEntities } from './index';

const validateOnSaveRegionalEntity = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- courseLicenses validation
        const validateCoursesLicensesAvailability = () => {
            const { coursesLicenses } = item;

            if (coursesLicenses) {
                const reqs = objectKeysToArray(coursesLicenses).map(courseLicense => {
                    // --
                    const { id: courseLicenseId, courseId, corporateEntityId, licenses, courseName } = courseLicense;

                    const childReqs = [];

                    // get available licenses
                    childReqs.push(
                        dispatch(
                            getCourseAvailableLicensesBy({
                                by: 'corporate-entity',
                                corporateEntityId,
                                courseId,
                            }),
                        ),
                    );

                    // get used licenses
                    childReqs.push(
                        dispatch(
                            getCourseUsedLicensesBy({
                                filterBy: 'corporate-entity',
                                courseId,
                                corporateEntityId,
                                courseLicenseId,
                            }),
                        ),
                    );

                    return awaitRequests(childReqs).then(res => {
                        // available
                        const availableLicenses = res[0];

                        // used
                        const usedLicenses = res[1];

                        // new compare value
                        const newUsedLicenses = usedLicenses + licenses;

                        if (newUsedLicenses > availableLicenses) {
                            return promise({
                                success: false,
                                courseName,
                            });
                        }

                        return promise({ success: true });
                    });
                });

                return awaitRequests(reqs).then(res => {
                    const filtered = res.filter(v => !v.success);

                    if (filtered.length) {
                        return promise({
                            success: false,
                            courseName: filtered[0].courseName,
                        });
                    }

                    return promise({ success: true });
                });
            }

            return promise({ success: true });
        };

        const { id: regionalEntityId, name, corporateEntityId } = item;

        const reqs = [];

        // regional entities data request for validation
        reqs.push(
            dispatch(
                getRegionalEntities({
                    where: [
                        ['name', '==', name],
                        ['corporateEntityId', '==', corporateEntityId],
                    ],
                }),
            ),
        );

        // courses licenses availability validation
        reqs.push(validateCoursesLicensesAvailability());

        return awaitRequests(reqs).then(res => {
            // regionalEntities
            const regionalEntities = res[0];

            if (regionalEntities.length) {
                // isUnique validation
                const isUnique =
                    regionalEntities.filter(regionalEntity => {
                        if (regionalEntity.id === regionalEntityId) {
                            return false;
                        }

                        return true;
                    }).length === 0;

                if (!isUnique) {
                    return promise({
                        success: false,
                        errorMsg: 'La entidad corporativa y entidad regional enviados ya se encuentran registrados.',
                    });
                }
            }

            // courses licenses validation results
            const coursesLicensesValidation = res[1];

            if (coursesLicensesValidation) {
                // --
                if (!coursesLicensesValidation.success) {
                    // --
                    const { courseName } = coursesLicensesValidation;

                    return promise({
                        success: false,
                        errorMsg: `El curso '${courseName}' no cuenta con licencias disponibles.`,
                    });
                }
            }

            return promise({ success: true });
        });
    };
};

export default validateOnSaveRegionalEntity;
