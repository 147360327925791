import { awaitRequests, promise } from '../../../helpers';
import {
    exportExams,
    exportForums,
    exportItinerary,
    exportLiveClasses,
    exportRecordedClasses,
    exportResources,
    exportTasks,
    exportTeamTasks,
    exportTutorings,
} from './index';

const exportAllActivities = data => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (!data.fromCourseId || !data.toGroupId) {
            return promise({ error: 'Missing parameters' }, 'reject');
        }

        const requests = [
            dispatch(exportExams(data)),
            dispatch(exportForums(data)),
            dispatch(exportItinerary(data)),
            dispatch(exportLiveClasses(data)),
            dispatch(exportRecordedClasses(data)),
            dispatch(exportResources(data)),
            dispatch(exportTasks(data)),
            dispatch(exportTeamTasks(data)),
            dispatch(exportTutorings(data)),
        ];

        return awaitRequests(requests);
    };
};

export default exportAllActivities;
