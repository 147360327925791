import { promise } from '../../../helpers';
import { addGrade } from './index';
import { getFieldValue } from '../../../helpers/firebase';
import { addExamSubmission, getExamSubmission, updateExamSubmission } from '../exams-submissions';
import { addTeamTaskSubmission, getTeamTaskSubmission, updateTeamTaskSubmission } from '../team-tasks-submission';
import { addTaskSubmission, getTaskSubmission, updateTaskSubmission } from '../tasks-submissions';
import { getParticipantTeam } from '../teamTasks';

const upsertSubmissionFromGrade = (nGrade, type) => {
    // eslint-disable-next-line
    return async (dispatch, getState, getFirebase) => {
        if (!type || !nGrade.rating) {
            return promise();
        }

        const data = { ...nGrade, lastUpdate: getFieldValue().serverTimestamp() };

        // just update the grade on the submissions
        if (data.id) {
            switch (data.type) {
                case 'exam': {
                    const examSubmission = await dispatch(getExamSubmission({ docId: data.examSubmissionId }));
                    const { id: sId, ...sWithoutId } = examSubmission;
                    dispatch(updateExamSubmission({ docId: sId, data: { ...sWithoutId, rating: data.rating } }));
                    return promise(data);
                }
                case 'task': {
                    const taskSubmission = await dispatch(getTaskSubmission({ docId: data.taskSubmissionId }));
                    const { id: sId, ...sWithoutId } = taskSubmission;
                    dispatch(updateTaskSubmission({ docId: sId, data: { ...sWithoutId, rating: data.rating } }));
                    return promise(data);
                }
                case 'team-task': {
                    const teamTaskSubmission = await dispatch(getTeamTaskSubmission({ docId: data.teamTaskSubmissionId }));
                    const { id: sId, ...sWithoutId } = teamTaskSubmission;
                    dispatch(updateTeamTaskSubmission({ docId: sId, data: { ...sWithoutId, rating: data.rating } }));
                    return promise(data);
                }
                default:
            }
        }

        // need to add a submission
        const submission = {
            courseId: data.courseId,
            groupId: data.groupId,
            lastUpdate: data.lastUpdate,
        };
        switch (data.type) {
            case 'exam': {
                submission.rating = data.rating;
                submission.participantId = data.participantId;
                submission.examId = data.assignmentId;

                const examSubmission = await dispatch(addExamSubmission({ data: submission }));
                data.examSubmissionId = examSubmission.id;
                break;
            }
            case 'task': {
                submission.rating = data.rating;
                submission.participantId = data.participantId;
                submission.taskId = data.assignmentId;

                const taskSubmission = await dispatch(addTaskSubmission({ data: submission }));
                data.taskSubmissionId = taskSubmission.id;
                break;
            }
            case 'team-task': {
                submission.rating = data.rating;
                submission.teamTaskId = data.assignmentId;
                const teamIds = await dispatch(
                    getParticipantTeam({
                        teamTaskId: submission.teamTaskId,
                        where: [['teamMembers', 'array-contains', data.participantId]],
                    }),
                );

                if (!teamIds || teamIds.length === 0) {
                    return promise();
                }

                const [teamId] = teamIds[0];
                submission.teamId = teamId;

                const teamTaskSubmission = await dispatch(addTeamTaskSubmission({ data: submission }));
                data.teamTaskSubmissionId = teamTaskSubmission.id;
                break;
            }
            default:
        }

        return dispatch(addGrade({ data }));
    };
};

export default upsertSubmissionFromGrade;
