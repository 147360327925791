import PropTypes from 'prop-types';
import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { updateProfileManagementRelations } from '../general';
import { getGroupsParticipantsItems, sendGroupsParticipantsInvitations, updateGroupsParticipantsList } from './index';

const updateGroupsParticipantsItem = ({ data, docId, where, participants, prevParticipants }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'groupsParticipants', docId: id, data: d }));

        // -- after update
        const afterUpdate = item => {
            const reqs = [];

            // update participants data
            reqs.push(dispatch(updateGroupsParticipantsList({ docId: item.id, participants })));

            // update participants profileId assignations inside collections
            if (participants && participants.length) {
                reqs.push(
                    awaitRequests(
                        participants.map(participant =>
                            dispatch(
                                updateProfileManagementRelations({
                                    email: participant.email,
                                }),
                            ),
                        ),
                    ),
                );
            }

            return awaitRequests(reqs).then(res => {
                const updatedParticipants = res[0];
                const { participantsSaved } = updatedParticipants;

                return dispatch(sendGroupsParticipantsInvitations({ item, participants, prevParticipants })).then(() => {
                    return promise({ item, participantsSaved });
                });
            });
        };

        // -- single update
        if (data && docId) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getGroupsParticipantsItems({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise({ item: undefined, participantsSaved: [] });
    };
};

updateGroupsParticipantsItem.propTypes = {
    participants: PropTypes.array,
    prevParticipants: PropTypes.array,
};

updateGroupsParticipantsItem.defaultProps = {
    participants: [],
    prevParticipants: [],
};

export default updateGroupsParticipantsItem;
