import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { getResources, saveResourceFiles, deleteResourceFiles } from './index';

const updateResource = ({ docId, where, data, uploadList }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'resources', docId: id, data: d }));

        // -- after update
        const afterUpdate = async item => {
            let updatedItem = item;

            const deleteList = uploadList.map(ul => ul.dbField);

            // upload files
            updatedItem = await dispatch(deleteResourceFiles(updatedItem, deleteList));
            updatedItem = await dispatch(saveResourceFiles({ item, uploadList }));

            return updatedItem;
        };

        // -- single update
        if (docId && data) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getResources({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise();
    };
};

export default updateResource;
