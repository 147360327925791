import { awaitRequests, promise } from '../../../helpers';
import { getFacilitatorsGroupsItems } from '../facilitators-groups';
import { getGroupsParticipantsItem } from '../groups-participants';

const getLearningCommunityCourseLicensesUsage = ({ courseLicenseId, learningCommunityId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        if (courseLicenseId && learningCommunityId) {
            return dispatch(
                getFacilitatorsGroupsItems({
                    where: [
                        ['courseLicenseId', '==', courseLicenseId],
                        ['learningCommunityId', '==', learningCommunityId],
                    ],
                }),
            ).then(items => {
                return awaitRequests(
                    items.map(item => {
                        return dispatch(
                            getGroupsParticipantsItem({
                                where: [
                                    ['groupId', '==', item.groupId],
                                    ['learningCommunityId', '==', item.learningCommunityId],
                                ],
                            }),
                        ).then(gp => {
                            const foundGroupMembers = (gp && gp.participantsIds.length) || 0;

                            return promise(foundGroupMembers + 1); // +1 for facilitator
                        });
                    }),
                ).then(res => promise(res.reduce((a, b) => a + b, 0)));
            });
        }

        return promise(0);
    };
};

export default getLearningCommunityCourseLicensesUsage;
