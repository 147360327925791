import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { addManager, getManager } from '../managers';
import { updateManagersRelations } from '../general';

const updateRegionalEntityManagers = (docId, managers) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (docId && managers && managers.length) {
            const reqs = managers.map(manager => {
                const { name, email } = manager;

                return dispatch(
                    getManager({
                        where: [['email', '==', email]],
                    }),
                ).then(item => {
                    if (item) {
                        return dispatch(
                            firebaseUpdate({
                                collection: 'managers',
                                docId: item.id,
                                data: { name },
                            }),
                        ).then(updatedItem => {
                            return dispatch(updateManagersRelations(name, email)).then(() => promise(updatedItem));
                        });
                    }

                    return dispatch(
                        addManager({
                            data: {
                                name,
                                email,
                            },
                        }),
                    ).then(addedItem => {
                        return dispatch(updateManagersRelations(name, email)).then(() => promise(addedItem));
                    });
                });
            });

            return awaitRequests(reqs).then(responses => {
                const managersIds = responses.map(manager => manager.id);
                const managersSaved = responses.filter(manager => manager.id);

                return dispatch(
                    firebaseUpdate({
                        collection: 'regionalEntities',
                        docId,
                        data: { managersIds },
                    }),
                ).then(() =>
                    promise({
                        success: true,
                        managersSaved,
                    }),
                );
            });
        }

        return promise({ success: false, managersSaved: [] });
    };
};

export default updateRegionalEntityManagers;
