import { promise, runXMLHttpRequest } from '../../../helpers';
import { setInvitationToken } from '../invitations';

const sendCorporateEntityManagerInvitation = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (item && item.managerEmail) {
            // --
            return dispatch(setInvitationToken(item.managerEmail)).then(invitation => {
                const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
                const requestUrl = `${baseUrl}/email-sendInvitation`;

                const params = {
                    token: invitation.token,
                    email: item.managerEmail,
                    section: 'corporate-entity',
                    sectionName: item.name,
                };

                // run email send
                runXMLHttpRequest(requestUrl, params);

                // manual finish
                return promise();
            });
        }

        return promise();
    };
};

export default sendCorporateEntityManagerInvitation;
