import { awaitRequests, promise } from '../../../helpers';
import { updateFacilitatorsGroupsItems } from '../facilitators-groups';
import { updateFacilitatorsItems } from '../facilitators';
import { updateGroupsParticipantsItems } from '../groups-participants';
import { updateLearningCommunityGroups } from '../learning-community-groups';

const afterDeleteLearningCommunity = ({ docId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (docId) {
            const reqs = [];

            reqs.push(
                dispatch(
                    updateFacilitatorsItems({
                        where: [['learningCommunityId', '==', docId]],
                        data: {
                            learningCommunityId: '',
                            learningCommunityName: '--',
                        },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    updateLearningCommunityGroups({
                        where: [['regionalEntityId', '==', docId]],
                        data: {
                            learningCommunityId: '',
                            learningCommunityName: '--',
                        },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    updateGroupsParticipantsItems({
                        where: [['regionalEntityId', '==', docId]],
                        data: {
                            learningCommunityId: '',
                            learningCommunityName: '--',
                        },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    updateFacilitatorsGroupsItems({
                        where: [['regionalEntityId', '==', docId]],
                        data: {
                            regionalEntityId: '',
                            regionalEntityName: '--',
                        },
                    }),
                ),
            );

            return awaitRequests(reqs).then(() => {
                return promise();
            });
        }

        return promise();
    };
};

export default afterDeleteLearningCommunity;
