import { awaitRequests, promise } from '../../../helpers';
import { addCorporateEntityLoginCounters, saveCorporateEntityFiles, sendCorporateEntityManagerInvitation } from './index';
import { updateProfileManagementRelations } from '../general';
import { counterIncrement } from '../admin';

const addCorporateEntity = ({ data, uploadList }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('corporateEntities')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            // upload files
            reqs.push(dispatch(saveCorporateEntityFiles(item, uploadList)));

            // send manager invitations
            reqs.push(dispatch(sendCorporateEntityManagerInvitation(item)));

            // update user profileId inside the management collections
            reqs.push(
                dispatch(
                    updateProfileManagementRelations({
                        email: item.managerEmail,
                    }),
                ),
            );

            reqs.push(dispatch(counterIncrement('corporateEntities')));

            // create login counters for the corporate entity
            reqs.push(dispatch(addCorporateEntityLoginCounters(item.id)));

            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addCorporateEntity;
