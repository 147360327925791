import { awaitRequests, promise } from '../../../helpers';
import { firebaseDelete } from '../firebase';
import { afterDeleteCorporateEntity } from './index';

const deleteCorporateEntity = (docId, where) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // after delete
        const afterDelete = item => {
            const reqs = [];

            if (item && item.id) {
                // --
                reqs.push(dispatch(afterDeleteCorporateEntity({ docId: item.id })));
            }

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- delete by docId
        if (docId) {
            return dispatch(firebaseDelete({ collection: 'corporateEntities', docId })).then(item => afterDelete(item));
        }

        // -- delete where
        if (where) {
            return dispatch(firebaseDelete({ collection: 'corporateEntities', where })).then(deletedItems =>
                awaitRequests(deletedItems.map(item => afterDelete(item))),
            );
        }

        return promise();
    };
};

export default deleteCorporateEntity;
