import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';

const fbConfig = {
    apiKey: 'AIzaSyAEdLKFiKj6vBvvhy7pYEQcz3b511Ej9iw',
    authDomain: 'enfoco-test.firebaseapp.com',
    databaseURL: 'https://enfoco-test.firebaseio.com',
    projectId: 'enfoco-test',
    storageBucket: 'enfoco-test.appspot.com',
    messagingSenderId: '303898491422',
    appId: '1:303898491422:web:0d86b5b3f1edc67ea54c0f',
};

const fb = firebase.initializeApp(fbConfig);

export { fb, fbConfig };
