import { awaitRequests, promise } from '../../../helpers';
import { addFacilitatorManager, getFacilitatorManager } from '../facilitators-managers';
import { firebaseUpdate } from '../firebase';
import { updateManagersRelations } from '../general';

const updateFacilitatorsItemManagers = ({ docId, managers }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (docId && managers && managers.length) {
            const reqs = managers.map(manager => {
                const {
                    name,
                    email,
                    conferenceAccount,
                    conferencePassword,
                    conferenceCancelDate,
                    statusSlug,
                    statusName,
                } = manager;

                return dispatch(
                    getFacilitatorManager({
                        where: [
                            ['email', '==', email],
                            ['facilitatorItemId', '==', docId],
                        ],
                    }),
                ).then(item => {
                    if (item) {
                        // -- update
                        return dispatch(
                            firebaseUpdate({
                                collection: 'facilitatorsManagers',
                                docId: item.id,
                                data: {
                                    name,
                                    conferenceAccount,
                                    conferencePassword,
                                    conferenceCancelDate,
                                    statusSlug,
                                    statusName,
                                },
                            }),
                        ).then(() => {
                            return dispatch(updateManagersRelations(name, email)).then(() => {
                                return promise({
                                    ...item,
                                    id: item.id,
                                });
                            });
                        });
                    }

                    const newManager = {
                        name,
                        email,
                        facilitatorItemId: docId,
                        conferenceAccount,
                        conferencePassword,
                        conferenceCancelDate,
                        statusSlug,
                        statusName,
                    };

                    // -- create
                    return dispatch(
                        addFacilitatorManager({
                            data: newManager,
                        }),
                    ).then(addedItem => {
                        return dispatch(updateManagersRelations(name, email)).then(() => {
                            return promise({
                                ...newManager,
                                id: addedItem.id,
                            });
                        });
                    });
                });
            });

            return awaitRequests(reqs).then(responses => {
                const managersIds = responses.map(manager => manager.id);
                const managersSaved = responses.filter(manager => manager.id);

                return dispatch(
                    firebaseUpdate({
                        collection: 'facilitators',
                        docId,
                        data: { managersIds },
                    }),
                ).then(() => {
                    return promise({ success: true, managersSaved });
                });
            });
        }

        return promise({ success: false, managersSaved: [] });
    };
};

export default updateFacilitatorsItemManagers;
