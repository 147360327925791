import { awaitRequests, promise } from '../../../helpers';
import { sendFacilitatorsItemManagersInvitations, updateFacilitatorsItemManagers } from './index';
import { updateProfileManagementRelations } from '../general';

const addFacilitatorsItem = ({ data, managers }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('facilitators')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            // update facilitatorsManagers data
            reqs.push(dispatch(updateFacilitatorsItemManagers({ docId: item.id, managers })));

            // send managers invitations
            reqs.push(dispatch(sendFacilitatorsItemManagersInvitations(item, managers)));

            // update auth profileId management
            if (managers && managers.length) {
                reqs.push(
                    awaitRequests(
                        managers.map(({ email }) =>
                            dispatch(
                                updateProfileManagementRelations({
                                    email,
                                }),
                            ),
                        ),
                    ),
                );
            }

            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addFacilitatorsItem;
