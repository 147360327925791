import { promise } from '../../../helpers';
import { getDocFromSnapshot } from '../../../helpers/firebase';

const getAdmin = (docId = 'general') => {
    return (dispatch, getState, getFirebase) => {
        return getFirebase()
            .firestore()
            .collection('counters')
            .doc(docId)
            .get()
            .then(doc => promise(getDocFromSnapshot(doc)));
    };
};

export default getAdmin;
