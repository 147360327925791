import { promise } from '../../../helpers';
import { getCourses } from './index';

const validateOnSaveCourse = item => {
    // --
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const { id: courseId, categoryId, name } = item;

        return dispatch(
            getCourses({
                where: [
                    ['name', '==', name],
                    ['categoryId', '==', categoryId],
                ],
            }),
        ).then(items => {
            if (items.length) {
                // "is unique" validation
                const isUnique =
                    items.filter(course => {
                        if (course.id === courseId) {
                            return false;
                        }

                        return true;
                    }).length === 0;

                if (!isUnique) {
                    return promise({
                        success: false,
                        errorMsg: 'El nombre del curso y categoría seleccionados ya se encuentran registrados.',
                    });
                }
            }

            return promise({ success: true });
        });
    };
};

export default validateOnSaveCourse;
