import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { getFieldPath } from '../helpers/firebase';

const storeAs = 'corporateEntities-authCountryId';

function useCorporateEntities({ countryId }) {
    const [query, setQuery] = useState([]);
    useFirestoreConnect(query);

    useEffect(() => {
        if (countryId) {
            const inThisCountry = { where: [['countryId', '==', countryId]] };
            setQuery([{ collection: 'corporateEntities', ...inThisCountry, storeAs }]);
        }
    }, [countryId]);
}

export default function useFilterByRole({ forCorporateEntity = false } = {}) {
    const [filterValues, setFilterValues] = useState();
    const [whereField, setWhereField] = useState();
    const [loading, setLoading] = useState(true);
    const [empty, setEmpty] = useState(false);
    const dispatch = useDispatch();

    const auth = useSelector(({ auth: a }) => a);
    const { slug } = auth.role;
    const countryId = auth.country ? auth.country.id : undefined;
    useCorporateEntities({ countryId });
    const corporateEntities = useSelector(({ firestore: { ordered } }) => ordered[storeAs]);

    useEffect(() => {
        const documentIdField = getFieldPath().documentId();

        if (slug === 'admin') {
            setFilterValues();
            setEmpty(false);
            setWhereField();
            setLoading(false);
        }
        if (slug === 'country') {
            if (!corporateEntities) return;
            setFilterValues(corporateEntities.map(e => e.id));
            setEmpty(corporateEntities.length === 0);
            if (forCorporateEntity) {
                setWhereField(documentIdField);
            } else {
                setWhereField('corporateEntityId');
            }
            setLoading(false);
        }
        if (slug === 'corporate-entity') {
            const corporateEntityId = auth.corporateEntity.id;
            setFilterValues([corporateEntityId]);
            setEmpty(false);
            setWhereField('corporateEntityId');
            setLoading(false);
        }
        if (slug === 'regional-entity') {
            const regionalEntityId = auth.regionalEntity.id;
            setFilterValues([regionalEntityId]);
            setEmpty(false);
            setWhereField('regionalEntityId');
            setLoading(false);
        }
        if (slug === 'learning-community') {
            const learningCommunityId = auth.learningCommunity.id;
            setFilterValues([learningCommunityId]);
            setEmpty(false);
            setWhereField('learningCommunityId');
            setLoading(false);
        }
    }, [auth, slug, dispatch, corporateEntities, forCorporateEntity]);

    const whereFilters = [];
    if (filterValues) {
        whereFilters.push({
            whereField,
            whereEquality: 'in',
            whereValue: filterValues,
        });
    }

    return [loading, empty, whereFilters];
}
