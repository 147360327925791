import { awaitRequests, promise } from '../../../helpers';

const afterDeleteCourseLicense = ({ docId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (docId) {
            return awaitRequests([]);
        }

        return promise();
    };
};

export default afterDeleteCourseLicense;
