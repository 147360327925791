import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';

import AdminTableBody from './body';
import AdminTableHead from './head';
import { ConfirmationModal, SimpleModal } from '..';
import { StyledContainer, StyledTable, StyledEmptyMessage } from './styled';
import { getCourse } from '../../store/actions/courses';
import { getGroupsParticipantsItem } from '../../store/actions/groups-participants';
import { getFacilitator } from '../../store/actions/facilitators';

function AdminTable({ columnsSettings, requestsSettings, withColoredCircles, isMenuOpen, empty }) {
    const [EditForm, setEditForm] = useState();
    const [editableItem, setEditableItem] = useState();
    const [isEditFormEnabled, setIsEditFormEnable] = useState();

    const [deletableItem, setDeletableItem] = useState();
    const [deletableItemLabel, setDeletableItemLabel] = useState();
    const [deletableItemSettings, setDeletableItemSettings] = useState();

    const [requestDeleteConfirmation, setRequestDeleteConfirmation] = useState();
    const [deleteSuccessConfirmation, setDeleteSuccessConfirmation] = useState();

    const [resendItem, setResendItem] = useState();
    const [resendParentItem, setResendParentItem] = useState();
    const [resendAction, setResendAction] = useState();
    const [requestResendConfirmation, setRequestResendConfirmation] = useState();
    const [resendSuccessConfirmation, setResendSuccessConfirmation] = useState();

    const shouldRenderTable = requestsSettings && !empty;

    const dispatch = useDispatch();

    const deleteItemAndManageCounter = (action, item, numberOfItems, collection) => {
        return dispatch(
            action(
                {
                    data: {
                        item: item.originalData,
                        numberOfItems: numberOfItems || 0,
                    },
                },
                collection,
                'delete',
            ),
        ).then(() => {
            setDeleteSuccessConfirmation(true);
        });
    };

    const handleOnClickViewRow = (item, rowSettings) => {
        const { onViewRowClick } = rowSettings;

        if (onViewRowClick instanceof Function) {
            onViewRowClick(item.originalData);
        }
    };

    const handleOnClickEditRow = (item, rowSettings) => {
        if (rowSettings.formComponent) {
            setIsEditFormEnable(true);
            setEditableItem(item.originalData);
            setEditForm(rowSettings.formComponent);
        }
    };

    const handleOnClickDeleteRow = (item, rowSettings) => {
        if (item && item.id && rowSettings && rowSettings.deleteAction) {
            const {
                originalData: { name, title },
            } = item;

            if (name) {
                setDeletableItemLabel(name);
            } else if (title) {
                setDeletableItemLabel(title);
            }

            setDeletableItem(item);
            setDeletableItemSettings(rowSettings);
            setRequestDeleteConfirmation(true);
        }
    };

    const onDeleteConfirmationAccept = () => {
        if (deletableItem && deletableItem.id && deletableItemSettings) {
            const { deleteAction } = deletableItemSettings;
            if (deleteAction && typeof deleteAction === 'function') {
                setRequestDeleteConfirmation(false);
                // only this cases are covered in the function addOrDeleteItemAndUpdateItemCounter
                if (requestsSettings[0].firebase.collection === 'coursesAssignations') {
                    dispatch(getCourse({ docId: deletableItem.originalData.courseId })).then(course => {
                        const numberOfItems = course.booksIds && course.booksIds.length;
                        deleteItemAndManageCounter(
                            deleteAction,
                            deletableItem,
                            numberOfItems,
                            requestsSettings[0].firebase.collection,
                        );
                    });
                } else if (requestsSettings[0].firebase.collection === 'groupsParticipants') {
                    dispatch(getGroupsParticipantsItem({ docId: deletableItem.originalData.id })).then(groupsParticipants => {
                        const numberOfItems = groupsParticipants.participantsIds && groupsParticipants.participantsIds.length;
                        deleteItemAndManageCounter(
                            deleteAction,
                            deletableItem,
                            numberOfItems,
                            requestsSettings[0].firebase.collection,
                        );
                    });
                } else if (requestsSettings[0].firebase.collection === 'facilitators') {
                    dispatch(getFacilitator({ docId: deletableItem.originalData.id })).then(facilitator => {
                        const numberOfItems = facilitator.managersIds && facilitator.managersIds.length;
                        deleteItemAndManageCounter(
                            deleteAction,
                            deletableItem,
                            numberOfItems,
                            requestsSettings[0].firebase.collection,
                        );
                    });
                } else if (
                    requestsSettings[0].firebase.collection === 'learningCommunities' ||
                    requestsSettings[0].firebase.collection === 'books'
                ) {
                    deleteItemAndManageCounter(deleteAction, deletableItem, 1, requestsSettings[0].firebase.collection);
                } else {
                    dispatch(deleteAction(deletableItem.id)).then(() => {
                        setDeleteSuccessConfirmation(true);
                    });
                }
            }
        }
    };

    const onDeleteConfirmationCancel = () => {
        setDeletableItem();
        setDeletableItemLabel();
        setDeletableItemSettings();
        setRequestDeleteConfirmation(false);
    };

    const handleOnClickSendRow = (item, rowSettings, parentItem) => {
        const { onSendRowClick } = rowSettings;

        if (onSendRowClick instanceof Function) {
            setResendAction(rowSettings);
            setResendItem(item);
            setResendParentItem(parentItem);
            setRequestResendConfirmation(true);
        }
    };

    const onResendConfirmationAccept = () => {
        if (resendAction && resendItem && resendItem.originalData) {
            const { onSendRowClick } = resendAction;
            setRequestResendConfirmation(false);

            dispatch(onSendRowClick(resendItem.originalData, resendParentItem.originalData)).then(() => {
                setResendSuccessConfirmation(true);
            });
        }
    };

    const onResendCancel = () => setRequestResendConfirmation(false);

    const renderEditForm = () => {
        if (isEditFormEnabled && editableItem && EditForm) {
            return (
                <SimpleModal title="Editar" showIf={isEditFormEnabled} onHide={() => setIsEditFormEnable(false)}>
                    <div>
                        <EditForm editableItem={editableItem} submitLabel="Guardar" />
                    </div>
                </SimpleModal>
            );
        }

        return undefined;
    };

    if (shouldRenderTable) {
        return (
            <StyledContainer isMenuOpen={isMenuOpen}>
                <div className="table-responsive">
                    <StyledTable className="table table-hover">
                        {/* heading */}
                        <AdminTableHead columnsSettings={columnsSettings} withColoredCircles={withColoredCircles} />

                        {/* body */}
                        <AdminTableBody
                            withColoredCircles={withColoredCircles}
                            onClickViewRow={handleOnClickViewRow}
                            onClickEditRow={handleOnClickEditRow}
                            onClickDeleteRow={handleOnClickDeleteRow}
                            onClickSendRow={handleOnClickSendRow}
                            columnsSettings={columnsSettings}
                            requestsSettings={requestsSettings}
                        />
                    </StyledTable>
                </div>

                {/* confirmation modal: delete request */}
                <ConfirmationModal
                    icon="danger"
                    title="¿Eliminar?"
                    subtitle={deletableItemLabel}
                    show={requestDeleteConfirmation}
                    onCancel={onDeleteConfirmationCancel}
                    onConfirm={onDeleteConfirmationAccept}
                />

                {/* confirmation modal: delete success */}
                <ConfirmationModal
                    icon="success"
                    title="Registro eliminado correctamente:"
                    subtitle={deletableItemLabel}
                    show={deleteSuccessConfirmation}
                    cancelLabel=""
                    onHide={() => setDeleteSuccessConfirmation()}
                    onConfirm={() => setDeleteSuccessConfirmation()}
                />

                {/* confirmation modal: reenviar invitación */}
                <ConfirmationModal
                    icon="warning"
                    title="Reenviar invitación"
                    subtitle={resendItem && resendItem.originalData.email}
                    show={requestResendConfirmation}
                    onCancel={onResendCancel}
                    onConfirm={onResendConfirmationAccept}
                />

                {/* confirmation modal: delete success */}
                <ConfirmationModal
                    icon="success"
                    title="Invitación reenviada correctamente:"
                    subtitle={resendItem && resendItem.originalData.email}
                    show={resendSuccessConfirmation}
                    cancelLabel=""
                    onHide={() => setResendSuccessConfirmation()}
                    onConfirm={() => setResendSuccessConfirmation()}
                />

                {/* edit form modal */}
                {renderEditForm()}
            </StyledContainer>
        );
    }

    if (empty) {
        return (
            <StyledContainer>
                <StyledEmptyMessage>No existen registros disponibles</StyledEmptyMessage>
            </StyledContainer>
        );
    }

    return <></>;
}

AdminTable.propTypes = {
    columnsSettings: PropTypes.any,
    requestsSettings: PropTypes.any,
    withColoredCircles: PropTypes.bool,
    isMenuOpen: PropTypes.bool,
    empty: PropTypes.bool,
};

AdminTable.defaultProps = {
    columnsSettings: undefined,
    requestsSettings: undefined,
    withColoredCircles: false,
    isMenuOpen: true,
    empty: false,
};

const mapStateToProps = state => ({
    isMenuOpen: state.ui.isMenuOpen,
});

const enhance = compose(connect(mapStateToProps));

export default enhance(AdminTable);
