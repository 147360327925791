import { getExamSubmission } from '../exams-submissions';
import { getTaskSubmission } from '../tasks-submissions';
import { getParticipantTeam } from '../teamTasks';
import { getTeamTaskSubmission } from '../team-tasks-submission';

const getActivitiesSubmissions = ({ assignments, participantId }) => {
    return async dispatch => {
        if (!assignments || !participantId) throw new Error('Missing Params');

        const whereQuery = (idField, assignmentId) => ({
            where: [
                ['participantId', '==', participantId],
                [idField, '==', assignmentId],
            ],
        });

        const examsSubmissions = assignments.exams.then(exams =>
            exams.map(exam => dispatch(getExamSubmission(whereQuery('examId', exam.id)))),
        );

        const tasksSubmissions = assignments.tasks.then(tasks =>
            tasks.map(task => dispatch(getTaskSubmission(whereQuery('taskId', task.id)))),
        );

        const teamTasksSubmissions = assignments.teamTasks.then(teamTasks =>
            teamTasks.map(async teamTask => {
                const team = await dispatch(
                    getParticipantTeam({ teamTaskId: teamTask.id, where: [['teamMembers', 'array-contains', participantId]] }),
                );
                return dispatch(
                    getTeamTaskSubmission({
                        where: [
                            ['teamTaskId', '==', teamTask.id],
                            ['team', '==', team.id],
                        ],
                    }),
                );
            }),
        );

        const recordedClasses = assignments.recordedClasses.then(rc =>
            rc.map(({ viewedBy = [] }) => viewedBy.includes(participantId)),
        );

        return { examsSubmissions, recordedClasses, tasksSubmissions, teamTasksSubmissions };
    };
};

export default getActivitiesSubmissions;
