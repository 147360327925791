import { Interval } from 'luxon';
import { promise } from '../../../helpers';
import { getLiveClasses } from './index';

const validateOnSaveLiveClass = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const { id: classId, groupId, startDateTime, endDateTime } = item;

        if (!groupId) {
            return promise({ success: true });
        }

        return dispatch(
            getLiveClasses({
                where: [['groupId', '==', groupId]],
            }),
        ).then(classes => {
            if (classes.length) {
                const found = classes.filter(clazz => {
                    if (clazz.id === classId) {
                        return false;
                    }

                    const requestedInterval = Interval.fromDateTimes(startDateTime, endDateTime);

                    const interval = Interval.fromDateTimes(clazz.startDateTime.toDate(), clazz.endDateTime.toDate());

                    return interval.overlaps(requestedInterval);
                });

                if (found.length) {
                    return promise({
                        success: false,
                        errorMsg: 'Existe una clase con el mismo horario asignado.',
                    });
                }
            }

            return promise({ success: true });
        });
    };
};

export default validateOnSaveLiveClass;
