import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { StyledIframe, StyledModal } from './styled';
import { Button } from '..';

const VideoTutorial = ({ roleSlug, show }) => {
    const dispatch = useDispatch();
    const [showVideo, setShowVideo] = useState(show);
    const onHide = () => {
        dispatch({ type: '[TUTORIAL] SHOW_TUTORIAL', payload: false });
        setShowVideo(false);
    };

    useEffect(() => {
        setShowVideo(show);
    }, [show]);

    let videoSrc;
    switch (roleSlug) {
        case 'participant':
            videoSrc = 'https://www.youtube-nocookie.com/embed/uO4BbuvAqUk';
            break;
        case 'facilitator':
            videoSrc = 'https://www.youtube-nocookie.com/embed/rhJST-HXsFI';
            break;
        case 'admin':
            videoSrc = 'https://www.youtube-nocookie.com/embed/AYtrZzkgz94';
            break;
        case 'corporate-entity':
            videoSrc = 'https://www.youtube-nocookie.com/embed/Hnq5YZTG_Wc';
            break;
        case 'regional-entity':
            videoSrc = 'https://www.youtube-nocookie.com/embed/i1GeztEn6uc';
            break;
        case 'learning-community':
            videoSrc = 'https://www.youtube-nocookie.com/embed/YrBAr5h7PWQ';
            break;
        case 'country ':
            videoSrc = 'https://www.youtube-nocookie.com/embed/fZScgkEZVzA';
            break;
        default:
            break;
    }

    return (
        <StyledModal
            onRequestClose={onHide}
            isOpen={showVideo}
            footer={
                <div className="d-flex justify-content-center align-items-center">
                    <Button className="mx-2" size="xs" label="Cerrar" onClick={onHide} variant="secondary" />
                </div>
            }
        >
            <div className="w-100">
                <StyledIframe
                    width="560"
                    height="315"
                    src={videoSrc}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                />
            </div>
        </StyledModal>
    );
};

VideoTutorial.propTypes = {
    roleSlug: PropTypes.string,
    show: PropTypes.bool,
};

VideoTutorial.defaultProps = {
    roleSlug: '',
    show: false,
};

const mapStateToProps = ({ auth, tutorial }) => ({
    roleSlug: auth.role.slug,
    viewedTutorial: tutorial.viewedTutorial,
    show: !!tutorial.showTutorial,
});

const mapDispatchToProps = (dispatch, { viewedTutorial, roleSlug, show }) => {
    if (!show) {
        if (viewedTutorial.indexOf(roleSlug) === -1) {
            dispatch({ type: '[TUTORIAL] SHOW_TUTORIAL', payload: true });
            dispatch({ type: '[TUTORIAL] VIEWED_TUTORIAL', payload: roleSlug });
            return { roleSlug };
        }
        return { roleSlug };
    }
    return { roleSlug };
};

export default compose(connect(mapStateToProps), connect(null, mapDispatchToProps))(VideoTutorial);
