import { awaitRequests, promise } from '../../../helpers';
import { firebaseDeleteFile } from '../firebase';

const deleteResourceFiles = (item, toRemove = ['file', 'cover']) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // original may or may not come from admin-table component
        const { originalData } = item || {};

        const data = originalData || item;

        if (data) {
            const { file = {}, cover = {} } = data;
            const reqs = [];

            if (toRemove.includes('file') && file && file.storagePath) {
                reqs.push(dispatch(firebaseDeleteFile(file.storagePath)));
            }
            if (toRemove.includes('cover') && cover && cover.storagePath) {
                reqs.push(dispatch(firebaseDeleteFile(cover.storagePath)));
            }

            return awaitRequests(reqs).then(() => data);
        }

        return promise();
    };
};

export default deleteResourceFiles;
