import { promise } from '../../../helpers';
import { addChatRoom, getChatRoom } from '.';

export default function getGroupChatRoom({ courseId, groupId, chatRoomRef, isIndividual, isForum }) {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (groupId && chatRoomRef) {
            // eslint-disable-next-line
            let where = [
                ['courseId', '==', courseId],
                ['groupId', '==', groupId],
                ['ref', '==', chatRoomRef],
            ];

            // single chat filter
            if (isIndividual) {
                where.push(['isIndividualChat', '==', !!isIndividual]);
            }

            // forum chat filter
            else if (isForum) {
                where.push(['isForumChat', '==', !!isForum]);
            }

            return dispatch(getChatRoom({ where })).then(chatRoom => {
                if (!chatRoom) {
                    return dispatch(
                        addChatRoom({
                            data: {
                                courseId,
                                groupId,
                                ref: chatRoomRef,
                                conversation: [],
                                isIndividualChat: !!isIndividual,
                                isForumChat: !!isForum,
                            },
                        }),
                    );
                }

                return promise(chatRoom);
            });
        }

        return promise();
    };
}
