import { Interval } from 'luxon';
import { promise } from '../../../helpers';
import { getTutorings } from './index';

const validateOnSaveTutoring = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const { id: tutoringId, groupId, facilitatorId, startDateTime, endDateTime } = item;

        if (!groupId) {
            return promise({ success: true });
        }

        return dispatch(
            getTutorings({
                where: [
                    ['groupId', '==', groupId],
                    ['facilitatorId', '==', facilitatorId],
                ],
            }),
        ).then(tutorings => {
            if (tutorings.length) {
                const found = tutorings.filter(tutoring => {
                    if (tutoring.id === tutoringId) {
                        return false;
                    }

                    const requestedInterval = Interval.fromDateTimes(startDateTime, endDateTime);

                    const interval = Interval.fromDateTimes(tutoring.startDateTime.toDate(), tutoring.endDateTime.toDate());

                    return interval.overlaps(requestedInterval);
                });

                if (found.length) {
                    return promise({
                        success: false,
                        errorMsg: 'Existe una asesoría/tutoría con el mismo horario asignado.',
                    });
                }
            }

            return promise({ success: true });
        });
    };
};

export default validateOnSaveTutoring;
