import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { updateManagersRelations } from '../general';
import { addManager, getManager } from '../managers';
import { updateLearningCommunity } from './index';

const updateLearningCommunityManagers = (docId, managers) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (docId && managers && managers.length) {
            const reqs = managers.map(manager => {
                const { name, email } = manager;

                return dispatch(getManager({ where: [['email', '==', email]] })).then(item => {
                    if (item) {
                        return dispatch(
                            firebaseUpdate({
                                collection: 'managers',
                                docId: item.id,
                                data: { name },
                            }),
                        ).then(updatedItem => dispatch(updateManagersRelations(name, email)).then(() => promise(updatedItem)));
                    }

                    return dispatch(
                        addManager({
                            data: {
                                name,
                                email,
                            },
                        }),
                    ).then(addedItem => dispatch(updateManagersRelations(name, email)).then(() => promise(addedItem)));
                });
            });

            return awaitRequests(reqs).then(responses => {
                const managersIds = responses.map(manager => manager.id);
                const managersSaved = responses.filter(manager => manager.id);

                return dispatch(updateLearningCommunity({ docId, data: { managersIds } })).then(() =>
                    promise({ success: true, managersSaved }),
                );
            });
        }

        return promise({ success: false, managersSaved: [] });
    };
};

export default updateLearningCommunityManagers;
