import { promise } from '../../../helpers';
import { getCoursesLicenses } from './index';

const getCoursesLicensesBy = ({ by, corporateEntityId, regionalEntityId, learningCommunityId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- get courses licenses from a learning community
        if (by === 'learning-community' && corporateEntityId && learningCommunityId) {
            return dispatch(
                getCoursesLicenses({
                    where: [
                        ['corporateEntityId', '==', corporateEntityId],
                        ['learningCommunityId', '==', learningCommunityId],
                        ['type', '==', 'learning-community'],
                    ],
                }),
            );
        }

        // -- get courses licenses from a regional entity
        if (by === 'regional-entity' && regionalEntityId) {
            return dispatch(
                getCoursesLicenses({
                    where: [
                        ['corporateEntityId', '==', corporateEntityId],
                        ['regionalEntityId', '==', regionalEntityId],
                        ['type', '==', 'regional-entity'],
                    ],
                }),
            );
        }

        return promise([]);
    };
};

export default getCoursesLicensesBy;
