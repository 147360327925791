import { promise } from '../../../helpers';
import { firebaseGet, firebaseUpdate, firebaseUploadFilesArray } from '.';

const firebaseUpdateMultipleFiles = ({ collection, docId, uploadList }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        if (collection && docId && uploadList && uploadList.length) {
            // --
            return dispatch(firebaseGet({ collection, docId })).then(collectionItem => {
                // --
                if (collectionItem) {
                    // --
                    return dispatch(firebaseUploadFilesArray({ docId, uploadList })).then(uploadResponses => {
                        if (uploadResponses.length) {
                            const uploadedFilesData = {};

                            uploadResponses.forEach(uploadResponse => {
                                const { success, storageData, uploadItem } = uploadResponse;
                                const { dbField } = uploadItem;

                                if (success) {
                                    if (!uploadedFilesData[dbField]) {
                                        uploadedFilesData[dbField] = collectionItem[dbField] ? collectionItem[dbField] : [];
                                    }
                                    uploadedFilesData[dbField].push(storageData);
                                }
                            });
                            return dispatch(firebaseUpdate({ collection, docId, data: uploadedFilesData })).then(() => {
                                return promise({ ...collectionItem, ...uploadedFilesData });
                            });
                        }

                        return promise();
                    });
                }

                return promise();
            });
        }

        return promise();
    };
};

export default firebaseUpdateMultipleFiles;
