export default function formatTeamsParticipantsRelations(ids, data) {
    const cleanedTeams = [];

    ids.forEach(teamId => {
        data.forEach(item => {
            if (item.team === teamId) {
                // search if team is already in cleanedTeams
                const checked = cleanedTeams.findIndex(currentTeam => currentTeam.id === teamId);
                if (checked === -1) {
                    // new clean team
                    cleanedTeams.push({ id: teamId, teamMembers: [item.participantId] });
                } else {
                    // team already exists
                    cleanedTeams[checked].teamMembers.push(item.participantId);
                }
            }
        });
    });

    return cleanedTeams;
}
