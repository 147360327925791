import PropTypes from 'prop-types';
import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { updateProfileManagementRelations } from '../general';
import { updateCoursesLicensesBy } from '../courses-licenses';
import {
    getLearningCommunities,
    updateLearningCommunityManagers,
    sendLearningCommunityManagersInvitations,
    updateLearningCommunityRelations,
} from './index';

const updateLearningCommunity = ({ data, docId, where, managers, prevManagers, coursesLicenses }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'learningCommunities', docId: id, data: d }));

        // -- after update
        const afterUpdate = item => {
            const reqs = [];

            // update managers name in collections
            reqs.push(dispatch(updateLearningCommunityManagers(item.id, managers)));

            // update learning community name in collections
            reqs.push(dispatch(updateLearningCommunityRelations(item, docId)));

            // update licenses availability usage
            reqs.push(
                dispatch(updateCoursesLicensesBy({ by: 'learning-community', coursesLicenses, learningCommunityId: item.id })),
            );

            // update managers profileId assignations inside collections
            if (managers && managers.length) {
                reqs.push(
                    awaitRequests(
                        managers.map(manager =>
                            dispatch(
                                updateProfileManagementRelations({
                                    email: manager.email,
                                }),
                            ),
                        ),
                    ),
                );
            }

            return awaitRequests(reqs).then(res => {
                const updatedManagers = (res && res[0]) || {};
                const { managersSaved } = updatedManagers;

                // send email invitations
                return dispatch(sendLearningCommunityManagersInvitations(item, managers, prevManagers)).then(() => {
                    return promise({ item, managersSaved });
                });
            });
        };

        // -- single update
        if (data && docId) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getLearningCommunities({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise({ item: undefined, managersSaved: [] });
    };
};

updateLearningCommunity.propTypes = {
    managers: PropTypes.array,
    prevManagers: PropTypes.array,
};

updateLearningCommunity.defaultProps = {
    managers: [],
    prevManagers: [],
};

export default updateLearningCommunity;
