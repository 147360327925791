import { awaitRequests, promise } from '../../../helpers';
import { getDocFromSnapshot, getItemsFromSnapshot } from '../../../helpers/firebase';

const firebaseDelete = ({ collection, docId, where }) => {
    return (dispatch, getState, getFirebase) => {
        // -- single delete
        if (collection && docId) {
            return getFirebase()
                .firestore()
                .collection(collection)
                .doc(docId)
                .get()
                .then(snapshot => {
                    const item = getDocFromSnapshot(snapshot);

                    if (item) {
                        return getFirebase()
                            .firestore()
                            .collection(collection)
                            .doc(docId)
                            .delete()
                            .then(() => promise(item));
                    }

                    return promise();
                });
        }

        // -- plural delete
        if (collection && where) {
            // eslint-disable-next-line
            let query = getFirebase()
                .firestore()
                .collection(collection);

            if (where && where.length) {
                for (let i = 0; i < where.length; i += 1) {
                    query = query.where(where[i][0], where[i][1], where[i][2]);
                }
            }

            return query.get().then(snapshot => {
                const items = getItemsFromSnapshot(snapshot);

                return awaitRequests(
                    items.map(item =>
                        getFirebase()
                            .firestore()
                            .collection(collection)
                            .doc(item.id)
                            .delete()
                            .then(() => promise(item))
                            .catch(() => promise()),
                    ),
                );
            });
        }

        return promise();
    };
};

export default firebaseDelete;
