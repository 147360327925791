import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper } from './styled';

const ScrollContent = ({ children, width, maxWidth, height, maxHeight, background, className, style }) => {
    return (
        <StyledWrapper
            style={style}
            $maxWidth={maxWidth}
            width={width}
            $maxHeight={maxHeight}
            height={height}
            background={background}
            className={className}
        >
            {children}
        </StyledWrapper>
    );
};

ScrollContent.propTypes = {
    children: PropTypes.node,
    height: PropTypes.string,
    maxHeight: PropTypes.string,
    width: PropTypes.string,
    maxWidth: PropTypes.string,
    background: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.any,
};

ScrollContent.defaultProps = {
    children: undefined,
    height: undefined,
    maxHeight: undefined,
    width: undefined,
    maxWidth: undefined,
    background: '#f2f7fb', // mimic inputs bg
    className: undefined,
    style: undefined,
};

export default ScrollContent;
