import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { updateManagersRelations } from '../general';
import { addParticipant, getParticipant } from '../participants';

const updateGroupsParticipantsList = ({ docId, participants }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        if (docId && participants && participants.length) {
            const reqs = participants.map(participant => {
                const { name, email } = participant;

                return dispatch(
                    getParticipant({
                        where: [
                            ['email', '==', email],
                            ['groupsParticipantsId', '==', docId],
                        ],
                    }),
                ).then(item => {
                    if (item) {
                        return dispatch(
                            firebaseUpdate({
                                collection: 'participants',
                                docId: item.id,
                                data: {
                                    name,
                                    email,
                                },
                            }),
                        ).then(updatedItem =>
                            dispatch(updateManagersRelations(name, email)).then(() => {
                                return promise({
                                    ...updatedItem,
                                    name,
                                    email,
                                });
                            }),
                        );
                    }

                    return dispatch(
                        addParticipant({
                            data: {
                                name,
                                email,
                                groupsParticipantsId: docId,
                            },
                        }),
                    ).then(addedItem =>
                        dispatch(updateManagersRelations(name, email)).then(() => {
                            return promise({
                                ...addedItem,
                                name,
                                email,
                            });
                        }),
                    );
                });
            });

            return awaitRequests(reqs).then(responses => {
                const participantsIds = responses.map(group => group.id);
                const participantsSaved = responses.filter(group => group.id);

                return dispatch(
                    firebaseUpdate({
                        collection: 'groupsParticipants',
                        docId,
                        data: { participantsIds },
                    }),
                ).then(() =>
                    promise({
                        success: true,
                        participantsSaved,
                    }),
                );
            });
        }

        return promise({ success: false, participantsSaved: [] });
    };
};

export default updateGroupsParticipantsList;
