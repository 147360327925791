import { awaitRequests, promise } from '../../../helpers';
import { saveTeamTaskSubmissionFiles } from '.';

const addTeamTaskSubmission = ({ data, uploadList }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('teamTaskSubmissions')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            // upload files
            reqs.push(dispatch(saveTeamTaskSubmissionFiles(item, uploadList)));

            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addTeamTaskSubmission;
