import { promise } from '../../../helpers';
import { updateGrade, getGrade, addGrade } from './index';
import { getFieldValue } from '../../../helpers/firebase';
import { getTeamsParticipantsRelations } from '../teamTasks';
import { getParticipant } from '../participants';
import { getFacilitatorsGroupsItem } from '../facilitators-groups';
import { createFromTemplate } from '../certificates';
import { isCourseFinished } from '../general';

const upsertGradeForSubmission = (submission, type) => {
    // eslint-disable-next-line
    return async (dispatch, getState, getFirebase) => {
        if (!submission || !type || !submission.rating) {
            return promise();
        }

        const setGrade = async (queries, data) => {
            const grade = await dispatch(getGrade({ where: queries }));

            if (grade) {
                return dispatch(updateGrade({ docId: grade.id, data }));
            }

            const newGrade = await dispatch(addGrade({ data }));
            const { courseId, groupId } = data;
            const { participantId } = submission;
            const { corporateEntityId } = await dispatch(
                getFacilitatorsGroupsItem({
                    where: [
                        ['courseId', '==', courseId],
                        ['groupId', '==', groupId],
                    ],
                }),
            );
            dispatch(isCourseFinished({ courseId, corporateEntityId, groupId, participantId })).then(async finished => {
                if (finished) {
                    const { name: participantName } = await dispatch(getParticipant({ docId: participantId }));
                    dispatch(createFromTemplate({ courseId, corporateEntityId, groupId, participantId, participantName }));
                }
            });
            return newGrade;
        };

        const data = {
            courseId: submission.courseId,
            groupId: submission.groupId,
            participantId: submission.participantId,
            rating: submission.rating,
            lastUpdate: getFieldValue().serverTimestamp(),
            type,
        };

        const submissionIdQuery = ['==', submission.id];
        switch (type) {
            case 'exam':
                data.assignmentId = submission.examId;
                data.examSubmissionId = submission.id;
                submissionIdQuery.unshift('examSubmissionId');
                break;
            case 'task':
                data.assignmentId = submission.taskId;
                data.taskSubmissionId = submission.id;
                submissionIdQuery.unshift('taskSubmissionId');
                break;
            case 'team-task': {
                data.assignmentId = submission.teamTaskId;
                data.teamTaskSubmissionId = submission.id;
                data.team = submission.team;
                submissionIdQuery.unshift('teamTaskSubmissionId');

                const teamsParticipants = await dispatch(getTeamsParticipantsRelations(submission.teamTaskId));
                const promises = teamsParticipants.reverseRelations
                    .filter(({ team }) => team === data.team)
                    .map(({ participantId }) => {
                        const participantGrade = { ...data, participantId };
                        const participantQuery = ['participantId', '==', participantId];
                        return setGrade([participantQuery, submissionIdQuery], participantGrade);
                    });

                return Promise.all(promises);
            }
            default:
        }

        return setGrade([submissionIdQuery], data);
    };
};

export default upsertGradeForSubmission;
