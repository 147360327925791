import { promise } from '../../../helpers';
import { deleteSchedule, getSchedules } from './index';

const deleteScheduleForAssignment = assignment => {
    // eslint-disable-next-line
    return async (dispatch, getState, getFirebase) => {
        if (!assignment) {
            return promise();
        }

        const deleteSchedules = async query => {
            const schedules = await dispatch(getSchedules({ where: [query] }));

            if (schedules) {
                schedules.forEach(({ id }) => {
                    dispatch(deleteSchedule(id));
                });
                return true;
            }

            return false;
        };

        const assignmentIdQuery = ['assignmentId', '==', assignment.id];

        return deleteSchedules(assignmentIdQuery);
    };
};

export default deleteScheduleForAssignment;
