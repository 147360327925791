import { promise } from '../../../helpers';
import { getDocFromSnapshot, getItemsFromSnapshot } from '../../../helpers/firebase';

const firebaseGet = ({ collection, docId, where }) => {
    return (dispatch, getState, getFirebase) => {
        // -- single get
        if (collection && docId) {
            return getFirebase()
                .firestore()
                .collection(collection)
                .doc(docId)
                .get()
                .then(snapshot => promise(getDocFromSnapshot(snapshot)));
        }

        // -- plural get
        if (collection && where && where.length) {
            // eslint-disable-next-line
            let query = getFirebase()
                .firestore()
                .collection(collection);

            if (where && where.length) {
                for (let i = 0; i < where.length; i += 1) {
                    query = query.where(where[i][0], where[i][1], where[i][2]);
                }
            }

            return query.get().then(snapshot => getItemsFromSnapshot(snapshot));
        }

        return promise();
    };
};

export default firebaseGet;
