import { promise } from '../../../helpers';
import { firebaseUpdateFiles } from '../firebase';

const saveBookFiles = ({ item, uploadList }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        if (uploadList && uploadList.length) {
            // --
            return dispatch(firebaseUpdateFiles({ collection: 'books', docId: item.id, uploadList })).then(uploadedFilesData => {
                if (uploadedFilesData) {
                    return promise({
                        ...item,
                        uploadedFilesData,
                    });
                }

                return promise(item);
            });
        }

        return promise(item);
    };
};

export default saveBookFiles;
