import { awaitRequests, promise } from '../../../helpers';
import { upsertScheduleForAssignment } from '../schedules';

const addTutoring = ({ data }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('tutorings')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            if (item.startDateTime) {
                if (item.inTutoring) {
                    reqs.push(dispatch(upsertScheduleForAssignment(item, item.inTutoring, 'tutoring')));
                }
            }

            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addTutoring;
