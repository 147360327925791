import { awaitRequests, promise } from '../../../helpers';
import { updateCoursesLicensesBy } from '../courses-licenses';
import { sendRegionalEntityManagersInvitations, updateRegionalEntityManagers } from './index';
import { updateProfileManagementRelations } from '../general';
import { counterIncrement } from '../admin';

const addRegionalEntity = ({ data, managers, coursesLicenses }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('regionalEntities')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            // update managers data
            reqs.push(dispatch(updateRegionalEntityManagers(item.id, managers)));

            // update licenses availability usage
            reqs.push(dispatch(updateCoursesLicensesBy({ by: 'regional-entity', regionalEntityId: item.id, coursesLicenses })));

            // send managers invitations
            reqs.push(dispatch(sendRegionalEntityManagersInvitations(item, managers)));

            // update auth profileId management
            if (managers && managers.length) {
                reqs.push(
                    awaitRequests(
                        managers.map(({ email }) =>
                            dispatch(
                                updateProfileManagementRelations({
                                    email,
                                }),
                            ),
                        ),
                    ),
                );
            }

            reqs.push(dispatch(counterIncrement('regionalEntities')));

            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addRegionalEntity;
