import { promise } from '../../../helpers';
import { updateRecordedClass } from './index';

const saveViewedRecordedClass = ({ profileId, recordedClassId }) => {
    return (dispatch, getState, getFirebase) => {
        if (profileId && recordedClassId) {
            return dispatch(
                updateRecordedClass({
                    docId: recordedClassId,
                    data: {
                        // eslint-disable-next-line
                        viewedBy: getFirebase().firebase_.firestore.FieldValue.arrayUnion(profileId),
                    },
                }),
            ).then(() =>
                promise({
                    id: recordedClassId,
                }),
            );
        }

        return promise();
    };
};

export default saveViewedRecordedClass;
