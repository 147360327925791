import { getFieldValue } from '../../../helpers/firebase';

const counterIncrement = (field, incrementBy = 1, docId = 'general', nestedCollection = undefined, nestedDocId = undefined) => {
    return (dispatch, getState, getFirebase) => {
        let doc = getFirebase()
            .firestore()
            .collection('counters')
            .doc(docId);
        if (nestedCollection && nestedDocId) {
            doc = doc.collection(nestedCollection).doc(nestedDocId);
        }
        return doc.update(field, getFieldValue().increment(incrementBy));
    };
};

export default counterIncrement;
