import { awaitRequests, promise } from '../../../helpers';
import { firebaseUploadFile } from './index';

const firebaseUploadFilesArray = ({ docId, uploadList }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        if (docId && uploadList && uploadList.length) {
            // --
            return awaitRequests(
                uploadList.map(uploadItem => {
                    const { file, dbField, baseFolder, fileFolder } = uploadItem;

                    const attemptUpload = file && dbField && baseFolder && fileFolder;

                    if (attemptUpload) {
                        return dispatch(
                            firebaseUploadFile({
                                refId: docId,
                                file,
                                rootFolder: baseFolder,
                                folder: fileFolder,
                                keepName: true,
                            }),
                        )
                            .then(response => {
                                const { success, storageData } = response;
                                return promise({ success, storageData, uploadItem });
                            })
                            .catch(() => {
                                return promise({ success: false });
                            });
                    }

                    return promise({ success: false });
                }),
            );
        }

        return promise([]);
    };
};

export default firebaseUploadFilesArray;
