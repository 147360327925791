import { upsertScheduleForAssignment } from '.';

const upsertSchedulesForAssignments = ({ assignmentsMap, participantId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const updatedSchedules = [];
        Object.keys(assignmentsMap).forEach(async type => {
            if (assignmentsMap[type]) {
                const assignments = await assignmentsMap[type];
                assignments.forEach(assignment => {
                    updatedSchedules.push(dispatch(upsertScheduleForAssignment(assignment, [participantId], type)));
                });
            }
        });
        return updatedSchedules;
    };
};

export default upsertSchedulesForAssignments;
