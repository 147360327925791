import { updateTeamTaskSubmission } from '.';
import { promise } from '../../../helpers';
import { firebaseDeleteFile } from '../firebase';

const teamTaskSubmissionDeleteFeedbackFile = ({ submission, file }) => {
    // eslint-disable-next-line no-unused-vars
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(updateTeamTaskSubmission({ docId: id, data: d }));

        const nf = submission.feedbackFile.filter(f => f.storagePath !== file.storagePath);

        const newSubmission = { ...submission };
        newSubmission.feedbackFile = nf;
        delete newSubmission.id;

        update(newSubmission, submission.id).then(() => dispatch(firebaseDeleteFile(file.storagePath)));

        return promise();
    };
};

export default teamTaskSubmissionDeleteFeedbackFile;
