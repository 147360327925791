import React from 'react';
import { DateTime } from 'luxon';
import { promise } from '../../../helpers';
import { firebaseGet } from '../../../store/actions/firebase';
import store from '../../../store';
import Preview from '../preview';

export default function useTdValue(fieldValue, fieldType, fieldTypeSettings, row) {
    if (fieldValue && fieldType) {
        const { storageUrl } = fieldValue;

        if (fieldType === 'text') {
            return fieldValue;
        }

        if (fieldType === 'date' && fieldValue.seconds) {
            return DateTime.fromSeconds(fieldValue.seconds).toLocaleString(DateTime.DATE_MED);
        }

        if (fieldType === 'datetime' && fieldValue.seconds) {
            return DateTime.fromSeconds(fieldValue.seconds).toLocaleString(DateTime.DATETIME_MED);
        }

        if (fieldType === 'preview-image' && storageUrl) {
            return <Preview src={storageUrl} />;
        }

        if (fieldType === 'preview-pdf' && storageUrl) {
            return <Preview src={storageUrl} type="pdf" />;
        }

        if (fieldType === 'image' && storageUrl) {
            return <img src={storageUrl} alt="Portada" className="img-thumbnail img-fluid" />;
        }

        if (fieldType === 'firebase-request' && fieldTypeSettings) {
            const { dispatch } = store;
            const { collection, attribute } = fieldTypeSettings;

            if (collection && attribute) {
                return dispatch(firebaseGet({ collection, docId: fieldValue }))
                    .then(item => {
                        if (item[attribute]) {
                            return promise(item[attribute]);
                        }

                        return promise('');
                    })
                    .finally(() => promise(''));
            }
        }

        if (fieldType === 'render' && row && fieldTypeSettings) {
            const { component: TdComponent } = fieldTypeSettings;
            const { originalData: rowData } = row;

            if (TdComponent && rowData) {
                return <TdComponent item={rowData} />;
            }
        }

        return '';
    }

    return fieldValue;
}
