import validator from 'validator';

const validateCourseAssignation = (item, isBatch = false) => {
    const { corporateEntityName, courseName, licenses, dueDate, corporateEntityId, courseId } = item;

    if (isBatch) {
        if (!corporateEntityName || validator.isEmpty(corporateEntityName, { ignore_whitespace: true })) {
            return false;
        }

        if (!courseName || validator.isEmpty(courseName, { ignore_whitespace: true })) {
            return false;
        }
    } else {
        if (!corporateEntityId || validator.isEmpty(corporateEntityId, { ignore_whitespace: true })) {
            return false;
        }

        if (!courseId || validator.isEmpty(courseId, { ignore_whitespace: true })) {
            return false;
        }
    }

    if (!licenses) {
        return false;
    }

    if (!dueDate) {
        return false;
    }

    return true;
};

export default validateCourseAssignation;
