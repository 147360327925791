import { addTeamsForTask, saveTeamTaskFiles } from '.';
import { awaitRequests, promise } from '../../../helpers';

const addTeamTask = ({ data, uploadList, teams }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('teamTasks')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            // set teams
            reqs.push(dispatch(addTeamsForTask(item, teams)));

            // upload files
            reqs.push(dispatch(saveTeamTaskFiles(item, uploadList)));

            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addTeamTask;
