import { promise } from '../../../helpers';
import { getDocFromSnapshot } from '../../../helpers/firebase';
import { getTeamTaskSubmissions } from './index';

const getTeamTaskSubmission = ({ docId, where }) => {
    return (dispatch, getState, getFirebase) => {
        if (docId) {
            return getFirebase()
                .firestore()
                .collection('teamTaskSubmissions')
                .doc(docId)
                .get()
                .then(doc => promise(getDocFromSnapshot(doc)));
        }

        if (where) {
            return dispatch(getTeamTaskSubmissions({ where })).then(items => promise(items[0]));
        }

        return promise(undefined);
    };
};

export default getTeamTaskSubmission;
