import { getTeamTaskSubmission, saveTeamTaskSubmissionFiles } from '.';
import { awaitRequests, promise } from '../../../helpers';
import { getFieldPath } from '../../../helpers/firebase';
import { firebaseUpdate } from '../firebase';
import { getParticipantTeam } from '../teamTasks';
import { upsertGradeForSubmission } from '../grades';

const updateTeamTaskSubmission = ({ docId, where, data, uploadList }) => {
    // eslint-disable-next-line no-unused-vars
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'teamTaskSubmissions', docId: id, data: d }));

        // -- after update
        const afterUpdate = item => {
            const reqs = [];

            // upload files
            reqs.push(dispatch(saveTeamTaskSubmissionFiles(item, uploadList)));

            reqs.push(
                dispatch(
                    getParticipantTeam({
                        teamTaskId: data.teamTaskId,
                        where: [[getFieldPath().documentId(), '==', data.team]],
                    }),
                ).then(teams => {
                    const { teamMembers: participantsIds } = teams[0];
                    const moreReqs = participantsIds.map(participantId => {
                        return dispatch(upsertGradeForSubmission({ ...item, participantId }, 'team-task'));
                    });
                    return Promise.all(moreReqs);
                }),
            );

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- single update
        if (docId && data) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getTeamTaskSubmission({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise();
    };
};

export default updateTeamTaskSubmission;
