const { promise } = require('../../../helpers');

const firebaseUploadFile = ({ refId, file, rootFolder, folder, keepName = false }) => {
    return (dispatch, getState, getFirebase) => {
        const attemptRequest = file && file.name && rootFolder && refId;

        if (attemptRequest) {
            const fileExt = file.name.substr(file.name.lastIndexOf('.') + 1);
            const fileName =
                keepName && file.name ? file.name.replace(/[$[\]#/%]/g, '').replace(/\.+/g, '.') : `${refId}.${fileExt}`;
            let folderRef = folder ? `${rootFolder}/${folder}` : `${rootFolder}`;
            if (keepName) {
                folderRef = `${folderRef}/${refId}`;
            }
            const filePath = `${folderRef}/${fileName}`;

            return getFirebase()
                .storage()
                .ref(filePath)
                .put(file)
                .then(() => {
                    return getFirebase()
                        .storage()
                        .ref(folderRef)
                        .child(fileName)
                        .getDownloadURL()
                        .then(storageUrl => {
                            return promise({
                                success: true,
                                storageData: {
                                    storageUrl,
                                    storagePath: filePath,
                                    storageFileExt: fileExt,
                                    storageFileName: fileName,
                                },
                            });
                        })
                        .catch(err => {
                            return promise({ error: err });
                        });
                });
        }

        return promise({ success: false });
    };
};

export default firebaseUploadFile;
