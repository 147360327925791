import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate, firebaseUpdateNested } from '../firebase';

const updateProfileEmailAndNameRelations = (id, newValue, parameter) => {
    const managerParameter = `manager${parameter.charAt(0).toUpperCase() + parameter.slice(1)}`;
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const reqs = [];
        if (id && newValue && parameter) {
            // update in participants
            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'participants',
                        where: [['profileId', '==', id]],
                        data: { [parameter]: newValue },
                    }),
                ),
            );

            // update in facilitatorsManagers
            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'facilitatorsManagers',
                        where: [['profileId', '==', id]],
                        data: { [parameter]: newValue },
                    }),
                ),
            );

            // update in corporateEntities
            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'corporateEntities',
                        where: [['managerProfileId', '==', id]],
                        data: { [managerParameter]: newValue },
                    }),
                ),
            );

            // update in countries
            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'countries',
                        where: [['managerProfileId', '==', id]],
                        data: { [managerParameter]: newValue },
                    }),
                ),
            );

            // update in learningCommunities.managers
            reqs.push(
                dispatch(
                    firebaseUpdateNested({
                        collection: 'learningCommunities',
                        subcollection: 'managers',
                        where: [['profileId', '==', id]],
                        data: { [parameter]: newValue },
                    }),
                ),
            );

            // update in managers
            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'managers',
                        where: [['profileId', '==', id]],
                        data: { [parameter]: newValue },
                    }),
                ),
            );
        }

        if (reqs.length > 0) {
            return awaitRequests(reqs);
        }

        return promise();
    };
};

export default updateProfileEmailAndNameRelations;
