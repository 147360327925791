import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { updateProfileManagementRelations } from '../general';
import { getCountries, sendCountryManagerInvitation, updateCountryRelations } from './index';

const updateCountry = ({ docId, where, data, prevManagerEmail }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'countries', docId: id, data: d }));

        // -- after update
        const afterUpdate = item => {
            const reqs = [];

            // -- update country data in related collections
            reqs.push(dispatch(updateCountryRelations({ item, docId: item.id })));

            // -- update auth profileId relations
            reqs.push(
                dispatch(
                    updateProfileManagementRelations({
                        email: item.managerEmail,
                    }),
                ),
            );

            // -- send invitation manager when is created or updated
            if (prevManagerEmail !== item.managerEmail) {
                reqs.push(dispatch(sendCountryManagerInvitation({ item })));
            }

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- single update
        if (data && docId) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getCountries({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise();
    };
};

export default updateCountry;
