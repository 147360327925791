import { promise } from '../../../helpers';
import { updateFacilitatorsItem } from './index';

const updateFacilitatorsItems = props => {
    const { where } = props;

    if (where) {
        return updateFacilitatorsItem(props);
    }

    return promise();
};

export default updateFacilitatorsItems;
