import { getParticipantTeam } from '.';
import { promise, reverseTeamsParticipantsRelations } from '../../../helpers';

const getTeamsParticipantsRelations = data => {
    // eslint-disable-next-line no-unused-vars
    return (dispatch, getState, getFirebase) => {
        return dispatch(getParticipantTeam({ teamTaskId: data })).then(teamTask => {
            return promise(reverseTeamsParticipantsRelations(teamTask));
        });
    };
};

export default getTeamsParticipantsRelations;
