import { awaitRequests, promise } from '../../../helpers';
import { counterIncrement } from '../admin';

const afterDeleteCourse = ({ docId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (docId) {
            const reqs = [];

            reqs.push(dispatch(counterIncrement('courses', -1)));

            return awaitRequests(reqs).then(() => {
                return promise();
            });
        }

        return promise();
    };
};

export default afterDeleteCourse;
