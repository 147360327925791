import { awaitRequests, promise, runXMLHttpRequest } from '../../../helpers';
import { getProfiles } from '../profiles';
import { setInvitationToken } from '../invitations';

const sendFacilitatorsItemManagersInvitations = (item, managers, prevManagers) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        const filterUpdatedOnly = (managersItems, prevManagersItems) => {
            // --
            if (managersItems.length && prevManagersItems && prevManagersItems.length) {
                // --
                return managers.filter(manager => {
                    const found = prevManagersItems.filter(pm => pm.email === manager.email);

                    if (found.length) {
                        return false;
                    }

                    return true;
                });
            }

            return managers;
        };

        if (managers && managers.length) {
            const managersList = filterUpdatedOnly(managers, prevManagers);

            return awaitRequests(
                managersList.map(manager =>
                    dispatch(
                        getProfiles({
                            where: [['email', '==', manager.email]],
                        }),
                    ).then(profiles => {
                        if (profiles.length) {
                            return promise({ ...manager, found: true });
                        }

                        return promise({ ...manager, found: false });
                    }),
                ),
            ).then(managersResults => {
                return managersResults.map(manager => {
                    if (manager.found) {
                        const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
                        const requestUrl = `${baseUrl}/email-sendInvitation`;

                        const params = {
                            email: manager.email,
                            section: 'facilitator',
                            sectionName: item.learningCommunityName,
                        };

                        // run email send
                        runXMLHttpRequest(requestUrl, params);

                        // manual finish
                        return promise();
                    }

                    // send create account token
                    return dispatch(setInvitationToken(manager.email)).then(invitation => {
                        // --
                        const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
                        const requestUrl = `${baseUrl}/email-sendInvitation`;

                        const params = {
                            token: invitation.token,
                            email: manager.email,
                            section: 'facilitator',
                            sectionName: item.learningCommunityName,
                        };

                        // run email send
                        runXMLHttpRequest(requestUrl, params);

                        // manual finish
                        return promise();
                    });
                });
            });
        }

        return promise();
    };
};

export default sendFacilitatorsItemManagersInvitations;
