const addCorporateEntityLoginCounters = id => {
    return (dispatch, getState, getFirebase) => {
        // set document data
        const docData = {
            counter: 0,
        };

        // add leading zero if number is less than 10
        const pad = n => {
            return n < 10 ? `0${n}` : n;
        };

        const date = new Date();

        // get the actual year
        const year = () => {
            return date.getFullYear();
        };

        // get actual month
        const month = () => {
            return pad(date.getMonth() + 1);
        };

        return getFirebase()
            .firestore()
            .collection('counters')
            .doc('participantsLoginByCorporateEntity')
            .collection(id)
            .doc(`counter-${year()}-${month()}`)
            .set(docData);
    };
};

export default addCorporateEntityLoginCounters;
