import { awaitRequests, promise } from '../../../helpers';
import { firebaseDeleteFile } from '../firebase';

const deleteCorporateEntityFiles = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // original may or may not come from admin-table component
        const { originalData } = item || {};

        const data = originalData || item;

        if (data) {
            const { logo } = data;
            const reqs = [];

            if (logo.storagePath) {
                reqs.push(dispatch(firebaseDeleteFile(logo.storagePath)));
            }

            return awaitRequests(reqs);
        }

        return promise();
    };
};

export default deleteCorporateEntityFiles;
