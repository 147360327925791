import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { getLiveClasses } from './index';
import { getGroupsParticipantsItem } from '../groups-participants';
import { upsertScheduleForAssignment } from '../schedules';

const updateLiveClass = ({ docId, where, data }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'liveClasses', docId: id, data: d }));

        // -- after update
        const afterUpdate = async item => {
            const reqs = [];

            if (item.groupId) {
                const requestParticipants = await dispatch(
                    getGroupsParticipantsItem({ where: [['groupId', '==', item.groupId]] }),
                );
                if (requestParticipants.participantsIds) {
                    reqs.push(dispatch(upsertScheduleForAssignment(item, requestParticipants.participantsIds, 'live-class')));
                }
            }

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- single update
        if (docId && data) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getLiveClasses({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise();
    };
};

export default updateLiveClass;
