import React from 'react';
import PropTypes from 'prop-types';
import { StyledIcon } from '../styled';

const Icon404 = ({ size, style, className }) => {
    return (
        <StyledIcon size={size}>
            <svg
                className={className}
                style={style}
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 677.54 406.55"
            >
                <g opacity="0.22">
                    <path
                        d="M293.39,79.17C220.33,102.5-11.07-12.52,7,144.58c2.83,23.36,17.88,56.36,5.17,78.17C3,239.08-13.88,248.65-30.18,255c-42.58,17.31-58.44,49.81-47.66,96.18C-49,476.14,108,446.51,190.48,409.45c58.63-27.57,127.77-32.3,188.47-9,47.56,19,77.21,58.83,133,47.16,49.5-9.93,57-56.67,42.52-100.17-8.21-26.92-14.11-38.29,2.18-64.27,13.72-25.81,37.88-45.36,39.74-75.59.41-32.74-14.64-65.74-43.75-78.14-23.83-10.14-45.42-6.05-69-12.28C431.27,102.44,397.58,21,332.55,49c-16.21,7.65-25.34,25.29-43,30.43"
                        transform="translate(81.11 -43.19)"
                        fill="#d6cbb9"
                    />
                </g>
                <g opacity="0.3">
                    <path d="M266.9,62.82" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M-37.24,215.53" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M580,297.4" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M232.25,52.55" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M600.55,261.73" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M-71.89,246.33" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M497.89,85.92" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M261.77,30.74" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M611.59,291.5" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M-75.48,209.11" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M530,106.45" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M527.41,80.78" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M7.42,57.94" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M591.57,411.87" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M-75.74,420.86" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M38.22,47.67" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M592.85,375.94" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                    <path d="M-44.94,438.82" transform="translate(81.11 -43.19)" fill="#d6cbb9" />
                </g>
                <path
                    d="M102.09,272.83a25.41,25.41,0,0,1-8.12,2,8,8,0,0,1-2.78-.29l-.58-.18-.37-.14s.26.12.05,0a10.2,10.2,0,0,1-1.16-.66c-.37-.24,0,0-.26-.19l-.43-.37c-.33-.31-.64-.64-.94-1a15.4,15.4,0,0,1-1.35-2,48.55,48.55,0,0,1-2.62-5.53c-2-4.8-4.09-9.55-7.32-13.69s-7.43-8.6-13-9.26c-4.38-.53-8.87,1.07-13.24,1.35a46,46,0,0,1-10.27-.76,47.75,47.75,0,0,1-8.07-2,21.86,21.86,0,0,1-5.37-2.71,5.12,5.12,0,0,1-.74-.57c-.33-.29-.65-.58-1-.89s-.53-.57-.78-.87l-.17-.21c-.2-.24.18.26,0,0s-.24-.33-.35-.5a14.23,14.23,0,0,1-1.14-2c-.06-.13-.25-.58-.1-.21-.07-.16-.13-.32-.19-.48-.15-.4-.29-.81-.41-1.23a22.9,22.9,0,0,1-.57-2.31c-.07-.39-.14-.77-.2-1.16,0-.21-.07-.43-.1-.64a2.77,2.77,0,0,1,0-.28c-.95-8-.84-16.14-3.87-23.79-.47-1.18-1-2.34-1.55-3.48-1.17-2.38-4.73-.29-3.56,2.08,3.8,7.66,3.88,15.44,4.7,23.77A28.64,28.64,0,0,0,18.79,235a17.73,17.73,0,0,0,7.87,7.55c5.18,2.64,11.07,3.63,16.79,4.26a40.4,40.4,0,0,0,9.89-.08c1.28-.17,2.56-.38,3.83-.6,1.09-.18,2-.34,2.86-.42a14,14,0,0,1,2.89,0c.25,0,0,0,0,0l.3,0c.23,0,.47.09.7.15s.48.14.71.22.61.23.43.15a19.17,19.17,0,0,1,6.31,5.19,47.41,47.41,0,0,1,7.88,13.39c1.63,3.87,3.15,8.25,6.31,11.17,3.57,3.3,8.37,3.47,12.89,2.39a38,38,0,0,0,4.78-1.53,2.12,2.12,0,0,0,1.44-2.53,2.07,2.07,0,0,0-2.54-1.44Z"
                    transform="translate(81.11 -43.19)"
                    fill="#1c1c1b"
                />
                <path
                    d="M21.48,188.26s-.31.41-.14.19l-.3.38c-.16.2-.32.4-.49.59-.34.41-.69.8-1.05,1.18s-.69.69-1.05,1c-.19.18-.38.34-.58.5l-.24.2c-.28.23,0,0-.06.05a35.54,35.54,0,0,1-5.08,3,1.65,1.65,0,0,0-.59,2.25,1.69,1.69,0,0,0,2.26.6A33.12,33.12,0,0,0,19.55,195a26,26,0,0,0,4.78-5.05,1.65,1.65,0,0,0-.59-2.25,1.7,1.7,0,0,0-2.26.59Z"
                    transform="translate(81.11 -43.19)"
                    fill="#1c1c1b"
                />
                <path
                    d="M10.65,179.34c-.05,6.51,1,13,1,19.51a1.65,1.65,0,0,0,3.3,0c0-6.52-1.07-13-1-19.51a1.65,1.65,0,0,0-3.3,0Z"
                    transform="translate(81.11 -43.19)"
                    fill="#1c1c1b"
                />
                <path
                    d="M-.16,184.61c4,5,8.11,10.23,13.68,13.61,1.82,1.1,3.48-1.75,1.66-2.85-5.3-3.22-9.19-8.32-13-13.09a1.65,1.65,0,0,0-2.33,0,1.68,1.68,0,0,0,0,2.33Z"
                    transform="translate(81.11 -43.19)"
                    fill="#1c1c1b"
                />
                <path
                    d="M-5.29,193.85A18.7,18.7,0,0,0,3.77,199a19.06,19.06,0,0,0,10-.64c2-.65,1.15-3.84-.87-3.18a15.73,15.73,0,0,1-8.24.64A15.56,15.56,0,0,1-3,191.52c-1.52-1.49-3.85.84-2.33,2.33Z"
                    transform="translate(81.11 -43.19)"
                    fill="#1c1c1b"
                />
                <path
                    d="M414.74,290.21a27.85,27.85,0,0,1,1.15-8.8,9.38,9.38,0,0,1,2.9-4.35c.08-.07.71-.49,1-.66l.61-.32c.11-.06.7-.32.43-.21a17.58,17.58,0,0,1,2.84-.87,45.55,45.55,0,0,1,6.83-.83c5.47-.37,11-.4,16.44-1.22,3-.46,5.83-1.6,7.29-4.46,1.81-3.57,2.5-7.76,3.81-11.52a25.33,25.33,0,0,1,3.36-6.77,18.43,18.43,0,0,1,4.2-4c6.75-4.58,15.6-4.67,23.53-6.68a24.27,24.27,0,0,0,10.71-5.29c3.44-3.18,5.21-7.71,5.86-12.27a41.76,41.76,0,0,0,.41-4.61c.1-2.65-4-2.65-4.12,0a27.88,27.88,0,0,1-1.28,8,13.16,13.16,0,0,1-1.16,2.67,13.08,13.08,0,0,1-5.61,5.42c-.4.22-.81.42-1.23.61l-.36.16s.26-.11,0,0-.5.2-.74.29c-.93.35-1.86.64-2.81.9-8,2.21-16.77,2.23-24.13,6.56a22.8,22.8,0,0,0-9.23,10.36c-1.91,4.26-2.82,8.84-4.4,13.21l-.18.48c-.19.49.12-.24,0,.08a8.46,8.46,0,0,1-.43.85c-.07.12-.16.24-.22.36-.21.36,0,0,0,0l-.6.6c-.23.23.41-.24,0,0l-.35.23-.32.17c-.47.28.26-.07-.17.08a14.27,14.27,0,0,1-2.11.56l-.64.11c-.13,0-.26,0-.39.06,0,0,.29,0,0,0-.56.07-1.13.13-1.69.18-1.35.13-2.7.21-4.05.29l-8.9.54c-4.52.3-9.52.54-13.51,2.93-4.4,2.64-6.06,7.47-6.65,12.33a42.92,42.92,0,0,0-.27,4.94c0,2.66,4.12,2.66,4.12,0Z"
                    transform="translate(81.11 -43.19)"
                    fill="#1c1c1b"
                />
                <path
                    d="M491.17,207.5a22.36,22.36,0,0,0,12.44,12.44,1.65,1.65,0,0,0,2-1.15,1.69,1.69,0,0,0-1.15-2,1.3,1.3,0,0,1-.23-.1l-.47-.21c-.31-.14-.61-.3-.91-.46-.55-.29-1.08-.61-1.6-1a14.54,14.54,0,0,1-1.43-1c-.5-.42-1-.85-1.43-1.31s-.89-.93-1.31-1.43a7.54,7.54,0,0,1-.46-.6c-.19-.25-.36-.5-.53-.76a18.89,18.89,0,0,1-1-1.67c-.16-.3-.32-.6-.46-.91l-.18-.39c0-.07-.07-.15-.1-.23.06.13.05.1,0-.08a1.7,1.7,0,0,0-2-1.15,1.66,1.66,0,0,0-1.15,2Z"
                    transform="translate(81.11 -43.19)"
                    fill="#1c1c1b"
                />
                <path
                    d="M500.35,199.87v15.4a1.65,1.65,0,0,0,3.3,0v-15.4a1.65,1.65,0,0,0-3.3,0Z"
                    transform="translate(81.11 -43.19)"
                    fill="#1c1c1b"
                />
                <path
                    d="M510.67,201.49a28,28,0,0,1-3.81,7.56,32.44,32.44,0,0,1-6,6.08,1.66,1.66,0,0,0,0,2.33,1.68,1.68,0,0,0,2.33,0,34.34,34.34,0,0,0,6.55-6.75,31.18,31.18,0,0,0,4.15-8.35,1.65,1.65,0,1,0-3.19-.87Z"
                    transform="translate(81.11 -43.19)"
                    fill="#1c1c1b"
                />
                <path
                    d="M518,208.28c-2.8,4.6-7.53,7.56-12.36,9.51a1.71,1.71,0,0,0-1.16,2,1.67,1.67,0,0,0,2,1.15c5.72-2.3,11.07-5.65,14.34-11,1.1-1.82-1.75-3.48-2.85-1.67Z"
                    transform="translate(81.11 -43.19)"
                    fill="#1c1c1b"
                />
                <path
                    d="M453.24,401.77c0,5.39-40.33,7-44.69,7H106.77c-4.35,0-44.69-1.56-44.69-7l36.8-55.16c0-5.38,3.54-9.75,7.89-9.75H408.55c4.36,0,7.89,4.37,7.89,9.75Z"
                    transform="translate(81.11 -43.19)"
                    fill="#b4b4b4"
                />
                <polygon points="276.71 346.3 199.15 346.3 212.72 313.44 282.87 313.44 276.71 346.3" fill="#3c3c3a" />
                <polygon points="400.83 346.3 478.39 346.3 464.82 313.44 394.67 313.44 400.83 346.3" fill="#3c3c3a" />
                <polygon points="487.01 346.3 514.32 346.3 491.98 313.44 471.37 313.44 487.01 346.3" fill="#3c3c3a" />
                <polygon points="190.53 346.3 163.22 346.3 185.57 313.44 206.17 313.44 190.53 346.3" fill="#3c3c3a" />
                <polygon points="394.01 346.3 283.53 346.3 289.69 313.44 387.85 313.44 394.01 346.3" fill="#3c3c3a" />
                <rect x="169.96" y="319.44" width="337.63" height="5.03" fill="#b4b4b4" />
                <rect x="166.3" y="331.76" width="344.95" height="5.03" fill="#b4b4b4" />
                <path
                    d="M455.3,414.55c0,4.35-.9,7.88-5.25,7.88H65.27c-4.36,0-5.25-3.53-5.25-7.88v-6.76c0-4.36.89-7.88,5.25-7.88H450.05c4.35,0,5.25,3.52,5.25,7.88Z"
                    transform="translate(81.11 -43.19)"
                    fill="#3c3c3a"
                />
                <path
                    d="M329.78,411.36a4.11,4.11,0,0,1-4.1,4.11h-136a4.11,4.11,0,0,1-4.11-4.11h0a4.11,4.11,0,0,1,4.11-4.11h136a4.11,4.11,0,0,1,4.1,4.11Z"
                    transform="translate(81.11 -43.19)"
                    fill="#707070"
                />
                <rect x="176.05" y="86.06" width="325.44" height="217.8" rx="7.89" fill="#3c3c3a" />
                <path
                    d="M257.66,138.7a1.76,1.76,0,0,0,0-3.52,1.76,1.76,0,0,0,0,3.52Z"
                    transform="translate(81.11 -43.19)"
                    fill="#fff"
                />
                <path
                    d="M397.6,326.47a6.79,6.79,0,0,1-6.78,6.78H124.5a6.79,6.79,0,0,1-6.78-6.78V152.73A6.78,6.78,0,0,1,124.5,146H390.82a6.78,6.78,0,0,1,6.78,6.78Z"
                    transform="translate(81.11 -43.19)"
                    fill="#fff"
                />
                <line
                    x1="298.03"
                    y1="173.18"
                    x2="274.8"
                    y2="196.41"
                    fill="none"
                    stroke="#325975"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.3"
                />
                <line
                    x1="298.03"
                    y1="196.41"
                    x2="274.8"
                    y2="173.18"
                    fill="none"
                    stroke="#325975"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.3"
                />
                <line
                    x1="402.74"
                    y1="173.18"
                    x2="379.51"
                    y2="196.41"
                    fill="none"
                    stroke="#325975"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.3"
                />
                <line
                    x1="402.74"
                    y1="196.41"
                    x2="379.51"
                    y2="173.18"
                    fill="none"
                    stroke="#325975"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.3"
                />
                <path
                    d="M311.21,267.63v22.93a8.22,8.22,0,0,1-8.22,8.21h-4.43a8.22,8.22,0,0,1-8.21-8.21V267.63"
                    transform="translate(81.11 -43.19)"
                    fill="#f29bb4"
                />
                <polygon points="383.82 225.64 381.89 252.33 379.96 225.64 383.82 225.64" fill="#ef82a2" />
                <line
                    x1="269.16"
                    y1="224.41"
                    x2="408.38"
                    y2="224.41"
                    fill="none"
                    stroke="#325975"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.75"
                />
                <path
                    d="M165.27,259.44a5.5,5.5,0,1,0-5.5-5.5,5.57,5.57,0,0,0,5.5,5.5Z"
                    transform="translate(81.11 -43.19)"
                    fill="#f7c3d2"
                />
                <path
                    d="M350.06,259.44a5.5,5.5,0,1,0-5.5-5.5,5.56,5.56,0,0,0,5.5,5.5Z"
                    transform="translate(81.11 -43.19)"
                    fill="#f7c3d2"
                />
                <path
                    d="M370.14,102.34V170.1c0,3.22,5,3.22,5,0V102.34c0-3.21-5-3.22-5,0Z"
                    transform="translate(81.11 -43.19)"
                    fill="#d90024"
                />
                <path d="M372.64,191.08c3.22,0,3.23-5,0-5s-3.22,5,0,5Z" transform="translate(81.11 -43.19)" fill="#d90024" />
            </svg>
        </StyledIcon>
    );
};

Icon404.propTypes = {
    size: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
};
Icon404.defaultProps = {
    size: undefined,
    style: undefined,
    className: undefined,
};

export default Icon404;
