import { runXMLHttpRequest } from '../../../helpers';
import { getParticipant } from '../participants';
import { getCourse } from '../courses';
import { firebaseGetFile } from '../firebase';

const sendCertificate = item => {
    // eslint-disable-next-line
    return async (dispatch, getState, getFirebase) => {
        if (item) {
            // --
            const participant = await dispatch(getParticipant({ docId: item.participantId }));
            const course = await dispatch(getCourse({ docId: item.courseId }));
            if (!participant || !course) return false;

            if (!participant.email || !item.file) return false;

            const certificateDownloadURL = await dispatch(firebaseGetFile(item.file.storagePath));

            const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
            const requestUrl = `${baseUrl}/email-sendCertificate`;

            const params = {
                name: participant.name || '',
                email: participant.email,
                courseName: course.name || '',
                certificateDownloadURL,
            };

            // run email send
            runXMLHttpRequest(requestUrl, params);

            return true;
        }

        return false;
    };
};

export default sendCertificate;
