import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { getExams, saveExamFiles } from './index';
import { getGroupsParticipantsItem } from '../groups-participants';
import { upsertScheduleForAssignment } from '../schedules';

const updateExam = ({ docId, where, data, uploadList }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'exams', docId: id, data: d }));

        // -- after update
        const afterUpdate = async item => {
            const reqs = [];

            // upload files
            reqs.push(dispatch(saveExamFiles(item, uploadList)));
            if (item.groupId) {
                const requestParticipants = await dispatch(
                    getGroupsParticipantsItem({ where: [['groupId', '==', item.groupId]] }),
                );
                if (requestParticipants.participantsIds) {
                    reqs.push(dispatch(upsertScheduleForAssignment(item, requestParticipants.participantsIds, 'exam')));
                }
            }

            return awaitRequests(reqs).then(updatedItem => promise(updatedItem));
        };

        // -- single update
        if (docId && data) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getExams({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise();
    };
};

export default updateExam;
