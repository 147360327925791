import { getExams } from '../exams';
import { getRecordedClasses } from '../recorded-classes';
import { getTasks } from '../tasks';
import { getTeamTasks } from '../teamTasks';

const getActivitiesInCourse = ({ courseId, groupId }) => {
    return dispatch => {
        if (!courseId || !groupId) throw new Error('Missing Params');

        const whereQuery = {
            where: [
                ['courseId', '==', courseId],
                ['groupId', '==', groupId],
            ],
        };

        const exams = dispatch(getExams(whereQuery));
        const recordedClasses = dispatch(getRecordedClasses(whereQuery));
        const tasks = dispatch(getTasks(whereQuery));
        const teamTasks = dispatch(getTeamTasks(whereQuery));

        return { exams, recordedClasses, tasks, teamTasks };
    };
};

export default getActivitiesInCourse;
