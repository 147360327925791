import { getActivitiesSubmissions, getActivitiesInCourse } from './index';
import { getCourse } from '../courses';
import { getCourseAssignation } from '../courses-assignations';

const isCourseFinished = ({ courseId, corporateEntityId, groupId, participantId }) => {
    return async dispatch => {
        if (!courseId || !corporateEntityId || !groupId || !participantId) throw new Error('Missing Params');

        const course = await dispatch(getCourse({ docId: courseId }));
        if (!course) throw new Error('Cannot find course');
        if (!course.isQuickCourse) return null; // no checar curso que no sea rápido

        const courseAssignation = await dispatch(
            getCourseAssignation({
                where: [
                    ['courseId', '==', courseId],
                    ['corporateEntityId', '==', corporateEntityId],
                ],
            }),
        );

        if (!courseAssignation.certificateTemplateFile) return null; // no checar si no tiene una plantilla de certificado
        const minRating = course.minQualification || 60;

        const assignments = dispatch(getActivitiesInCourse({ courseId, groupId }));
        const submissions = dispatch(getActivitiesSubmissions({ assignments, participantId }));

        const allSubs = Object.values(submissions).flatMap(subs => {
            if (subs.length === 0) return true;

            return Promise.all(subs).then(subsValues =>
                subsValues
                    .map(sub => {
                        if (!sub) return false;
                        if (sub === true) return true;
                        if (!sub.rating) return false;
                        return sub.rating >= minRating;
                    })
                    .every(v => v),
            );
        });

        return allSubs.every(v => v);
    };
};

export default isCourseFinished;
