import { awaitRequests, promise } from '../../../helpers';
import { getFacilitatorManager } from './index';

const findFacilitatorsManagersByIds = facilitatorsManagersIds => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (facilitatorsManagersIds && facilitatorsManagersIds.length) {
            return awaitRequests(facilitatorsManagersIds.map(docId => dispatch(getFacilitatorManager({ docId }))));
        }

        return promise([]);
    };
};

export default findFacilitatorsManagersByIds;
