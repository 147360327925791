import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { updateLearningCommunityGroupRelations } from './index';
import { addGroup, getGroup } from '../groups';

const updateLearningCommunityGroupList = ({ docId, groups, prevGroups }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        const cleanDataFromRemovedGroups = savedGroupsId => {
            //  --
            if (prevGroups && prevGroups.length) {
                // --
                const groupsToClean = prevGroups.filter(group => group.id && savedGroupsId.indexOf(group.id) === -1);

                const reqs = [];

                reqs.push(
                    awaitRequests(
                        groupsToClean.map(group =>
                            dispatch(
                                firebaseUpdate({
                                    collection: 'groupsParticipants',
                                    where: [['groupId', '==', group.id]],
                                    data: { groupId: '', groupName: '--' },
                                }),
                            ),
                        ),
                    ),
                );

                reqs.push(
                    awaitRequests(
                        groupsToClean.map(group =>
                            dispatch(
                                firebaseUpdate({
                                    collection: 'facilitatorsGroups',
                                    where: [['groupId', '==', group.id]],
                                    data: { groupId: '', groupName: '--' },
                                }),
                            ),
                        ),
                    ),
                );

                return awaitRequests(reqs).then(() => {
                    return promise();
                });
            }

            return promise();
        };

        if (docId && groups && groups.length) {
            const reqs = groups.map(group => {
                const { id, name } = group;

                if (id) {
                    return dispatch(getGroup({ docId: id })).then(item => {
                        if (item) {
                            return dispatch(
                                firebaseUpdate({
                                    collection: 'groups',
                                    docId: id,
                                    data: { name },
                                }),
                            ).then(() => {
                                return dispatch(updateLearningCommunityGroupRelations({ groupName: name, groupId: id })).then(
                                    () =>
                                        promise({
                                            learningCommunityGroupId: docId,
                                            id,
                                            name,
                                        }),
                                );
                            });
                        }

                        return promise();
                    });
                }

                return dispatch(
                    addGroup({
                        data: {
                            name,
                            learningCommunityGroupId: docId,
                        },
                    }),
                );
            });

            return awaitRequests(reqs).then(responses => {
                const groupsIds = responses.filter(group => group.id).map(group => group.id);
                const groupsAdded = responses.filter(group => group.id);

                const afterCleanDataFromRemovedGroups = cleanDataFromRemovedGroups(groupsIds);

                return afterCleanDataFromRemovedGroups.then(() => {
                    return dispatch(
                        firebaseUpdate({
                            collection: 'learningCommunityGroups',
                            docId,
                            data: { groupsIds },
                        }),
                    ).then(() =>
                        promise({
                            groupsIds,
                            groupsAdded,
                        }),
                    );
                });
            });
        }

        const afterCleanDataFromRemovedGroups = cleanDataFromRemovedGroups([]);

        return afterCleanDataFromRemovedGroups.then(() => {
            return dispatch(
                firebaseUpdate({
                    collection: 'learningCommunityGroups',
                    docId,
                    data: { groupsIds: [] },
                }),
            ).then(() =>
                promise({
                    groupsIds: [],
                    groupsAdded: [],
                }),
            );
        });
    };
};

export default updateLearningCommunityGroupList;
