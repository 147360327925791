import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { StyledContainer, StyledLabel } from './styled';
import RenderIf from '../../render-if';

function DailyTasks({ labels }) {
    const history = useHistory();
    const { courseId } = useParams();

    const hasLabels = !!labels && labels.length > 0;
    if (!hasLabels) return null;

    const redirect = (type, activityId) => {
        switch (type) {
            case 'task':
                return history.push(`/curso/${courseId}/tarea/${activityId}`);
            case 'team-task':
                return history.push(`/curso/${courseId}/tareas-en-equipo/${activityId}`);
            case 'tutoring':
                return history.push(`/curso/${courseId}/asesoria/${activityId}`);
            case 'exam':
                return history.push(`/curso/${courseId}/examen/${activityId}`);
            case 'live-class':
                return history.push(`/curso/${courseId}/clases-presenciales`);
            case 'forum':
                return history.push(`/curso/${courseId}/chat/foro/${activityId}`);
            default:
                return '';
        }
    };

    return (
        <StyledContainer>
            <RenderIf isTrue={hasLabels}>
                {/* labels - activities */}
                {labels.map(v => {
                    const key = `key-${v.label}`;
                    return (
                        <StyledLabel type={v.type} key={key} onClick={() => redirect(v.type, v.id)}>
                            {v.label}
                        </StyledLabel>
                    );
                })}
            </RenderIf>
        </StyledContainer>
    );
}

DailyTasks.propTypes = {
    labels: PropTypes.array,
};
DailyTasks.defaultProps = {
    labels: [],
};

export default DailyTasks;
