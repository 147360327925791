import { formatTeamsParticipantsRelations } from '../../../helpers';

const addTeamsForTask = (newDoc, teams) => {
    return (dispatch, getState, getFirebase) => {
        const { ids, data } = teams;

        // we have to transform the data from [{ participantId: x, team: 'team1' }, { participantId: y, team: 'team2' }]
        // to ['x', 'y'] for each correspondig team
        const cleanedTeams = formatTeamsParticipantsRelations(ids, data);

        const batch = getFirebase()
            .firestore()
            .batch();

        const db = getFirebase()
            .firestore()
            .collection('teamTasks')
            .doc(newDoc.id)
            .collection('teams');

        cleanedTeams.forEach(cleanedTeam => {
            const docRef = db.doc(cleanedTeam.id);
            batch.set(docRef, { teamMembers: cleanedTeam.teamMembers });
        });

        return batch.commit();
    };
};

export default addTeamsForTask;
