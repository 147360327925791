import { awaitRequests } from '../../../helpers';
import { getCourses } from '../courses';
import { getCoursesAssignations } from '../courses-assignations';
import { addOrDeleteItemAndUpdateItemCounter } from '../general';

const manageBooksCounter = ({ data }, parameter, operation) => {
    // eslint-disable-next-line no-unused-vars
    return (dispatch, getState, getFirebase) => {
        const reqs = [];
        return dispatch(getCourses({ where: [['booksIds', 'array-contains', data.item.id]] })).then(courses => {
            if (courses.length) {
                courses.forEach(course => {
                    reqs.push(
                        dispatch(getCoursesAssignations({ where: [['courseId', '==', course.id]] })).then(coursesAssignations => {
                            coursesAssignations.forEach(c => {
                                const item = {
                                    bookId: data.item.id,
                                    corporateEntityId: c.corporateEntityId,
                                };
                                reqs.push(
                                    dispatch(
                                        addOrDeleteItemAndUpdateItemCounter(
                                            { data: { item, numberOfItems: 1 } },
                                            parameter,
                                            operation,
                                        ),
                                    ),
                                );
                            });
                        }),
                    );
                });
                return awaitRequests(reqs);
            }
            const item = {
                bookId: data.item.id,
            };
            return dispatch(addOrDeleteItemAndUpdateItemCounter({ data: { item, numberOfItems: 1 } }, parameter, operation));
        });
    };
};

export default manageBooksCounter;
