import { awaitRequests, promise } from '../../../helpers';
import { sendLearningCommunityManagersInvitations, updateLearningCommunityManagers } from './index';
import { updateProfileManagementRelations } from '../general';
import { updateCoursesLicensesBy } from '../courses-licenses';

const addLearningCommunity = ({ data, managers, coursesLicenses }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('learningCommunities')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            // update managers and send invitations
            reqs.push(
                dispatch(updateLearningCommunityManagers(item.id, managers)).then(() =>
                    dispatch(sendLearningCommunityManagersInvitations(item, managers)),
                ),
            );

            // update licenses availability usage
            reqs.push(dispatch(updateCoursesLicensesBy({ coursesLicenses, learningCommunityId: item.id })));

            // update managers profileId assignations inside collections
            if (managers && managers.length) {
                reqs.push(
                    awaitRequests(
                        managers.map(manager =>
                            dispatch(
                                updateProfileManagementRelations({
                                    email: manager.email,
                                }),
                            ),
                        ),
                    ),
                );
            }

            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addLearningCommunity;
