import { getMenuByRoleSlug, promise } from '../../../helpers';
import { setMenuSession } from './index';
import { getCourse } from '../courses';

const setMenuSessionBy = ({ roleSlug, courseId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- get old active menu and returns to the store
        const setActiveMenuState = menuItems => {
            // -- get prev menu
            const prevMenu = (getState().auth && getState().auth.menu) || [];

            if (prevMenu && prevMenu.length) {
                // --
                return menuItems.map(menuItem => {
                    const matchedItem = prevMenu.filter(item => item.url === menuItem.url);

                    if (matchedItem && matchedItem[0] && matchedItem[0].isActive) {
                        return { ...menuItem, isActive: true };
                    }

                    return { ...menuItem, isActive: false };
                });
            }

            return menuItems;
        };

        // -- attach course menu items to sidebar
        if (courseId && roleSlug) {
            return dispatch(getCourse({ docId: courseId })).then(course => {
                if (course) {
                    const roleMenus = getMenuByRoleSlug(roleSlug).map(menuItem => ({
                        ...menuItem,
                        isActive: false,
                    }));

                    const courseMenuItem = [
                        {
                            name: course.name,
                            url: roleSlug === 'admin' ? `/admin/curso/${courseId}` : `/curso/${courseId}`,
                            icon: 'book',
                            isActive: true,
                        },
                    ];

                    dispatch(setMenuSession([...roleMenus, ...courseMenuItem]));
                }

                return promise();
            });
        }

        // -- set menu only by role slug
        if (roleSlug) {
            const menu = setActiveMenuState(getMenuByRoleSlug(roleSlug));

            dispatch(setMenuSession(menu));
        }

        return promise();
    };
};

export default setMenuSessionBy;
