import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { getTutorings } from './index';
import { upsertScheduleForAssignment } from '../schedules';

const updateTutoring = ({ docId, where, data }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'tutorings', docId: id, data: d }));

        // -- after update
        const afterUpdate = item => {
            const reqs = [];

            if (item.startDateTime) {
                if (item.inTutoring) {
                    reqs.push(dispatch(upsertScheduleForAssignment(item, item.inTutoring, 'tutoring')));
                }
            }

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- single update
        if (docId && data) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getTutorings({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise();
    };
};

export default updateTutoring;
