import { awaitRequests, promise } from '../../../helpers';
import { deleteCorporateEntityFiles } from './index';
import { updateCoursesAssignations } from '../courses-assignations';
import { updateFacilitatorsGroupsItems } from '../facilitators-groups';
import { updateFacilitatorsItems } from '../facilitators';
import { updateGroupsParticipantsItems } from '../groups-participants';
import { updateLearningCommunities } from '../learning-communities';
import { updateLearningCommunityGroups } from '../learning-community-groups';
import { updateRegionalEntities } from '../regional-entities';
import { counterIncrement } from '../admin';

const afterDeleteCorporateEntity = ({ docId, item }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (docId) {
            const reqs = [];

            // clear name in coursesAssignations after delete corporate entity
            reqs.push(
                dispatch(
                    updateCoursesAssignations({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityId: '', corporateEntityName: '--' },
                    }),
                ),
            );

            // clear name in regionalEntities after delete corporate entity
            reqs.push(
                dispatch(
                    updateRegionalEntities({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityId: '', corporateEntityName: '--' },
                    }),
                ),
            );

            // clear name in learningCommunities after delete corporate entity
            reqs.push(
                dispatch(
                    updateLearningCommunities({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityId: '', corporateEntityName: '--' },
                    }),
                ),
            );

            // clear name in facilitators after delete corporate entity
            reqs.push(
                dispatch(
                    updateFacilitatorsItems({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityId: '', corporateEntityName: '--' },
                    }),
                ),
            );

            // clear name in groupsParticipants after delete corporate entity
            reqs.push(
                dispatch(
                    updateGroupsParticipantsItems({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityId: '', corporateEntityName: '--' },
                    }),
                ),
            );

            // clear name in learningCommunityGroups after delete corporate entity
            reqs.push(
                dispatch(
                    updateLearningCommunityGroups({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityId: '', corporateEntityName: '--' },
                    }),
                ),
            );

            // clear name in facilitatorsGroups after delete corporate entity
            reqs.push(
                dispatch(
                    updateFacilitatorsGroupsItems({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityId: '', corporateEntityName: '--' },
                    }),
                ),
            );

            // delete corporate entity files
            reqs.push(deleteCorporateEntityFiles(item));

            reqs.push(dispatch(counterIncrement('corporateEntities', -1)));

            return awaitRequests(reqs).then(() => {
                return promise();
            });
        }

        return promise();
    };
};

export default afterDeleteCorporateEntity;
