import { promise } from '../../../helpers';
import { getDocFromSnapshot } from '../../../helpers/firebase';

const getTeamTask = ({ docId }) => {
    return (dispatch, getState, getFirebase) => {
        if (docId) {
            return getFirebase()
                .firestore()
                .collection('teamTasks')
                .doc(docId)
                .get()
                .then(doc => promise(getDocFromSnapshot(doc)));
        }

        return promise(undefined);
    };
};

export default getTeamTask;
