export default function reverseTeamsParticipantsRelations(teamsParticipantsRelations) {
    const reverseRelations = [];
    const teams = [];

    teamsParticipantsRelations.forEach(relation => {
        relation.teamMembers.forEach(member => {
            reverseRelations.push({ participantId: member, team: relation.id });
        });
        teams.push(relation.id);
    });

    return { teams, reverseRelations };
}
