import { awaitRequests, promise } from '../../../helpers';
import { firebaseDeleteFile } from '../firebase';

const deleteBookFiles = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // original may or may not come from admin-table component
        const { originalData } = item || {};

        const data = originalData || item;

        if (data) {
            const { document, photo } = data;
            const reqs = [];

            if (document.storagePath) {
                reqs.push(dispatch(firebaseDeleteFile(document.storagePath)));
            }

            if (photo.storagePath) {
                reqs.push(dispatch(firebaseDeleteFile(photo.storagePath)));
            }

            return awaitRequests(reqs);
        }

        return promise();
    };
};

export default deleteBookFiles;
