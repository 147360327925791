import { awaitRequests, promise } from '../../../helpers';

const updateCourseRelations = ({ item, docId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (item && docId) {
            const reqs = [];

            return awaitRequests(reqs);
        }

        return promise();
    };
};

export default updateCourseRelations;
