import { addBook, deleteBook } from '../books';
import { getCorporateEntity } from '../corporate-entities';
import { getCountry } from '../countries';
import { addCourseAssignation, deleteCourseAssignation } from '../courses-assignations';
import { addFacilitatorsItem, deleteFacilitatorsItem } from '../facilitators';
import { addGroupsParticipantsItem, deleteGroupsParticipantsItem } from '../groups-participants';
import { addLearningCommunity, deleteLearningCommunity } from '../learning-communities';

const addOrDeleteItemAndUpdateItemCounter = ({ data, extra, coursesLicenses }, parameter, operation) => {
    // eslint-disable-next-line no-unused-vars
    return (dispatch, getState, getFirebase) => {
        const { auth } = getState();
        const { role } = auth;
        let corporateEntityRef = null;
        let countryRef = null;
        let regionalEntityRef = null;
        let learningCommunityRef = null;
        let adminRef = null;
        let currentCounter = `${parameter}Counter`;

        // special case: courses
        if (parameter === 'coursesAssignations') {
            currentCounter = 'booksCounter';
        }

        let numberOfItems = 0;

        // checking number of items
        if (data.numberOfItems <= 0) numberOfItems = 1;
        else numberOfItems = data.numberOfItems;

        // to perform correct operation
        const calculateValue = param => {
            switch (operation) {
                case 'add':
                    return param + numberOfItems;
                case 'delete':
                    if (param === 0) return param;
                    return param - numberOfItems;
                default:
                    return param;
            }
        };

        // get the reference to a doc
        // eslint-disable-next-line consistent-return
        const getDocReference = (collection, docId) => {
            return getFirebase()
                .firestore()
                .collection(collection)
                .doc(docId);
        };

        // after update the counter, go to correspondent action
        // eslint-disable-next-line consistent-return
        const afterUpdateCounter = () => {
            if (parameter === 'facilitators') {
                if (operation === 'add') return dispatch(addFacilitatorsItem({ data: data.item, managers: extra }));
                if (operation === 'delete') return dispatch(deleteFacilitatorsItem(data.item.id));
            }
            if (parameter === 'groupsParticipants') {
                if (operation === 'add') return dispatch(addGroupsParticipantsItem({ data: data.item, participants: extra }));
                if (operation === 'delete') return dispatch(deleteGroupsParticipantsItem(data.item.id));
            }
            if (parameter === 'learningCommunities') {
                if (operation === 'add')
                    return dispatch(addLearningCommunity({ data: data.item, managers: extra, coursesLicenses }));
                if (operation === 'delete') return dispatch(deleteLearningCommunity(data.item.id));
            }
            if (parameter === 'coursesAssignations') {
                if (operation === 'add') return dispatch(addCourseAssignation({ data: data.item, uploadList: extra }));
                if (operation === 'delete') return dispatch(deleteCourseAssignation(data.item.id));
            }
            if (parameter === 'books') {
                if (operation === 'add') return dispatch(addBook({ data: data.item, uploadList: extra }));
                // if (operation === 'delete') return dispatch(deleteBook)
            }
            return null;
        };

        // setting references for each entity
        if (role.slug !== 'admin') {
            if (data.item.corporateEntityId)
                corporateEntityRef = getDocReference('corporateEntities', data.item.corporateEntityId);
            if (data.item.regionalEntityId) regionalEntityRef = getDocReference('regionalEntities', data.item.regionalEntityId);
            if (data.item.learningCommunityId)
                learningCommunityRef = getDocReference('learningCommunities', data.item.learningCommunityId);
        }
        adminRef = getDocReference('counters', 'general');
        const result = async () => {
            await getFirebase()
                .firestore()
                .runTransaction(async transaction => {
                    let corporateEntity;
                    let country;
                    let regionalEntity;
                    let learningCommunity;
                    let admin;
                    // first, all read operations
                    if (corporateEntityRef) {
                        corporateEntity = await transaction.get(corporateEntityRef);
                    }
                    if (countryRef) {
                        country = await transaction.get(countryRef);
                    }
                    if (regionalEntityRef) {
                        regionalEntity = await transaction.get(regionalEntityRef);
                    }
                    if (learningCommunityRef) {
                        learningCommunity = await transaction.get(learningCommunityRef);
                    }
                    // eslint-disable-next-line prefer-const
                    admin = await transaction.get(adminRef);

                    // next, all the write operations
                    // special case, when admin adds o deletes a book directly
                    if (role.slug === 'admin' && parameter === 'books' && operation === 'add') {
                        // only update counter in admin
                        const newValue = calculateValue(admin.data().counters[currentCounter]);
                        await transaction.update(adminRef, {
                            [`counters.${currentCounter}`]: newValue,
                        });
                    } else {
                        if (corporateEntity) {
                            // update counter in corporate entity
                            const newCorporateEntityCounterValue = calculateValue(
                                corporateEntity.data().counters[currentCounter],
                            );
                            await transaction.update(corporateEntityRef, {
                                [`counters.${currentCounter}`]: newCorporateEntityCounterValue,
                            });
                        }
                        if (country) {
                            if (role.slug === 'country' && parameter === 'coursesAssignations') {
                                // do nothing here
                            } else {
                                // update counter in country
                                const newCountryCounterValue = calculateValue(country.data().counters[currentCounter]);
                                await transaction.update(countryRef, {
                                    [`counters.${currentCounter}`]: newCountryCounterValue,
                                });
                            }
                        }
                        if (regionalEntity) {
                            // update counter in regional entity
                            const newRegionalEntityCounterValue = calculateValue(regionalEntity.data().counters[currentCounter]);
                            await transaction.update(regionalEntityRef, {
                                [`counters.${currentCounter}`]: newRegionalEntityCounterValue,
                            });
                        }
                        if (learningCommunity) {
                            // update counter in learning community
                            const newLearningCommunityCounterValue = calculateValue(
                                learningCommunity.data().counters[currentCounter],
                            );
                            await transaction.update(learningCommunityRef, {
                                [`counters.${currentCounter}`]: newLearningCommunityCounterValue,
                            });
                        }
                        if (
                            (parameter === 'coursesAssignations' && role.slug === 'admin') ||
                            (parameter === 'coursesAssignations' && role.slug === 'country')
                        ) {
                            // do nothing
                        } else {
                            // update admin counter
                            const newValue = calculateValue(admin.data().counters[currentCounter]);
                            await transaction.update(adminRef, {
                                [`counters.${currentCounter}`]: newValue,
                            });
                        }
                    }
                });
        };
        if (role.slug === 'admin' && parameter === 'books' && operation === 'delete') {
            return dispatch(getCorporateEntity({ docId: data.item.corporateEntityId })).then(currentCorporateEntity => {
                if (currentCorporateEntity) {
                    return dispatch(getCountry({ docId: currentCorporateEntity.countryId })).then(country => {
                        countryRef = getFirebase()
                            .firestore()
                            .collection('countries')
                            .doc(country.id);
                        return result().then(() => {
                            return dispatch(deleteBook(data.item.bookId));
                        });
                    });
                }
                return result().then(() => {
                    return dispatch(deleteBook(data.item.bookId));
                });
            });
        }
        if (role.slug === 'admin') {
            let corporateEntityId = '';
            if (typeof data.item !== 'undefined') corporateEntityId = data.item.corporateEntityId;
            return dispatch(getCorporateEntity({ docId: corporateEntityId })).then(entity => {
                if (entity)
                    return dispatch(getCountry({ docId: entity.countryId || '' })).then(country => {
                        countryRef = getFirebase()
                            .firestore()
                            .collection('countries')
                            .doc(country.id);
                        // eslint-disable-next-line consistent-return
                        return result().then(() => {
                            return afterUpdateCounter();
                        });
                    });
                // eslint-disable-next-line consistent-return
                return result().then(() => {
                    return afterUpdateCounter();
                });
            });
        }
        let queryparam;
        if (typeof auth.corporateEntity !== 'undefined') queryparam = { docId: auth.corporateEntity.countryId };
        else queryparam = { docId: auth.country.id };

        return dispatch(getCountry(queryparam)).then(country => {
            if (country) {
                countryRef = getFirebase()
                    .firestore()
                    .collection('countries')
                    .doc(country.id);
            }
            // eslint-disable-next-line consistent-return
            return result().then(() => {
                return afterUpdateCounter();
            });
        });
    };
};

export default addOrDeleteItemAndUpdateItemCounter;
