import { promise } from '../../../helpers';
import { updateCourse } from './index';

const updateCourses = props => {
    const { where } = props;

    if (where) {
        return updateCourse(props);
    }

    return promise();
};

export default updateCourses;
