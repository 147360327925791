import { awaitRequests, promise } from '../../../helpers';
import { getParticipant } from './index';

const findParticipantsByIds = participantsIds => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (participantsIds && participantsIds.length) {
            return awaitRequests(participantsIds.map(docId => dispatch(getParticipant({ docId }))));
        }

        return promise([]);
    };
};

export default findParticipantsByIds;
