import { awaitRequests, promise } from '../../../helpers';
import { firebaseDelete } from '../firebase';

const deleteInvitation = (docId, where) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // after delete
        const afterDelete = item => {
            const reqs = [];

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- delete by docId
        if (docId) {
            return dispatch(firebaseDelete({ collection: 'invitations', docId })).then(item => afterDelete(item));
        }

        // -- delete where
        if (where) {
            return dispatch(firebaseDelete({ collection: 'invitations', where })).then(deletedItems =>
                awaitRequests(deletedItems.map(item => afterDelete(item))),
            );
        }

        return promise();
    };
};

export default deleteInvitation;
