import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { WeekDayPicker } from 'react-rainbow-components';
import { StyledErrorMessage } from '../input/styled';
import { Select, RenderIf } from '../index';

function WeekDaySelector({ value, error, disabled, onChange, className }) {
    const hasError = !!error;

    const [week, setWeek] = useState(value.weekValue);
    const [day, setDay] = useState(value.weekdayValue);

    useEffect(() => {
        setWeek(value.week);
        setDay(value.day);
    }, [value]);

    const onChangeSelect = e => {
        e.preventDefault();
        onChange({ week: e.target.value, day });
    };

    const onWeekdayChange = weekDay => {
        onChange({ week, day: weekDay });
    };

    const selectOptions = Array.from(Array(52).keys()).map(w => {
        return { value: w, label: `Semana ${w}` };
    });

    return (
        <div className={`mb-4 ${className}`}>
            <Row>
                <Col className="col-6">
                    <Select
                        label="Semana del curso"
                        options={selectOptions}
                        onChange={onChangeSelect}
                        value={week}
                        disabled={disabled}
                        required
                    />
                </Col>
                <Col className="col-6">
                    <WeekDayPicker
                        value={day}
                        required
                        onChange={onWeekdayChange}
                        label="Día de la semana"
                        className="mt-2"
                        bottomHelpText="Selecciona el día para la actividad"
                    />
                </Col>
            </Row>
            <RenderIf isTrue={hasError}>
                <StyledErrorMessage>{error}</StyledErrorMessage>
            </RenderIf>
        </div>
    );
}

WeekDaySelector.propTypes = {
    value: PropTypes.any,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

WeekDaySelector.defaultProps = {
    value: { week: '1', day: 'monday' },
    error: '',
    disabled: false,
    onChange: () => {},
    className: '',
};

export default WeekDaySelector;
