import { awaitRequests, promise, runXMLHttpRequest } from '../../../helpers';
import { firebaseGet } from '../firebase';
import { setInvitationToken } from '../invitations';

const sendGroupsParticipantsInvitations = ({ item, participants, prevParticipants }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        const filterUpdatedOnly = (participantsItems, prevParticipantsItems) => {
            if (participantsItems && participantsItems.length && prevParticipantsItems && prevParticipantsItems.length) {
                return participants.filter(participant => {
                    const found = prevParticipantsItems.filter(pm => pm.email === participant.email);

                    if (found.length) {
                        return false;
                    }

                    return true;
                });
            }

            return participants;
        };

        // --
        if (participants && participants.length) {
            const participantsList = filterUpdatedOnly(participants, prevParticipants);

            const participantsInvitationRequests = participantsList.map(participant => {
                const { email } = participant;

                const hasProfileRequest = dispatch(firebaseGet({ collection: 'profiles', where: [['email', '==', email]] }));

                return hasProfileRequest.then(profiles => {
                    if (profiles.length) {
                        return promise({ ...participant, found: true });
                    }

                    return promise({ ...participant, found: false });
                });
            });

            return awaitRequests(participantsInvitationRequests).then(participantsResults => {
                return participantsResults.map(participant => {
                    if (participant.found) {
                        const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
                        const requestUrl = `${baseUrl}/email-sendInvitation`;

                        const params = {
                            email: participant.email,
                            section: 'participant',
                            sectionName: item.groupName,
                            sectionName2: item.learningCommunityName,
                        };

                        // run email send
                        runXMLHttpRequest(requestUrl, params);

                        // manual finish
                        return promise();
                    }

                    const afterCreateInvitation = dispatch(setInvitationToken(participant.email));

                    return afterCreateInvitation.then(invitation => {
                        const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
                        const requestUrl = `${baseUrl}/email-sendInvitation`;

                        const params = {
                            token: invitation.token,
                            email: participant.email,
                            section: 'participant',
                            sectionName: item.groupName,
                            sectionName2: item.learningCommunityName,
                        };

                        // run email send
                        runXMLHttpRequest(requestUrl, params);

                        // manual finish
                        return promise();
                    });
                });
            });
        }

        return promise();
    };
};

export default sendGroupsParticipantsInvitations;
