import { awaitRequests, promise } from '../../../helpers';
import { saveTaskSubmissionFiles } from '.';
import { upsertGradeForSubmission } from '../grades';

const addTaskSubmission = ({ data, uploadList }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('taskSubmissions')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            // upload files
            reqs.push(dispatch(saveTaskSubmissionFiles(item, uploadList)));
            reqs.push(dispatch(upsertGradeForSubmission(item, 'task')));

            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addTaskSubmission;
