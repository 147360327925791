import { promise } from '../../../helpers';
import { firebaseDeleteFile } from '../firebase';
import { updateExam } from './index';

const examDeleteFile = ({ exam, file }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(updateExam({ docId: id, data: d }));

        const nf = exam.file.filter(f => f.storagePath !== file.storagePath);

        const newExam = { ...exam };
        newExam.file = nf;
        delete newExam.id;

        update(newExam, exam.id).then(() => dispatch(firebaseDeleteFile(file.storagePath)));

        return promise();
    };
};

export default examDeleteFile;
