import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dataset, RadioButtonGroup } from 'react-rainbow-components';
import { Row } from 'react-bootstrap';
import { getUniqueId } from '../../../helpers';
import { RenderIf } from '../..';
import { StyledCard, StyledChart, StyledChartHeading, StyledBadge } from './styled';

const chartOptions = [
    { value: 'line', label: 'Lines' },
    { value: 'bar', label: 'Bars' },
];

const InteractiveChart = ({ title, data, months }) => {
    const [chartType, setChartType] = useState('line');
    const [dataset, setDataset] = useState(null);
    const [titles, setTitles] = useState(null);
    const id = getUniqueId('chart');
    const shouldRenderChart = data && data.length > 0;

    useEffect(() => {
        if (!data) return;
        const ds = data.map((v, index) => {
            const key = `dataset-${index}`;

            return (
                <Dataset
                    key={key}
                    title={v.title}
                    values={v.values}
                    backgroundColor={v.backgroundColor}
                    borderColor={v.borderColor}
                />
            );
        });
        setDataset(ds);

        const t = data.map((v, index) => {
            const key = `dataset-${index}`;

            return <StyledBadge backgroundColor={v.backgroundColor} label={v.title} key={key} />;
        });
        setTitles(t);
    }, [data]);

    return (
        <Row className="mx-0 px-0 w-100 justify-content-center">
            <StyledCard className=" flex-grow-1 rainbow-m-top_x-large rainbow-m-bottom_x-large rainbow-p-around_large">
                {/* chart title  and selection */}
                <StyledChartHeading className="d-flex align-items-center justify-content-between mb-5">
                    <h1>{title}</h1>

                    <RadioButtonGroup
                        id={id}
                        options={chartOptions}
                        value={chartType}
                        variant="brand"
                        onChange={e => setChartType(e.target.value)}
                    />
                </StyledChartHeading>

                {/* chart dataset */}
                <RenderIf isTrue={shouldRenderChart}>
                    <StyledChart labels={months} type={chartType} maintainAspectRatio={false}>
                        {dataset}
                    </StyledChart>
                    <div className="rainbow-p-vertical_large rainbow-align-content_center rainbow-flex_wrap">{titles}</div>
                </RenderIf>
            </StyledCard>
        </Row>
    );
};

InteractiveChart.propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    months: PropTypes.array,
};

InteractiveChart.defaultProps = {
    title: undefined,
    data: undefined,
    months: undefined,
};

export default InteractiveChart;
