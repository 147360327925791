import _ from 'lodash';
import { awaitRequests, promise } from '../../../helpers';
import { firebaseDeleteFile } from '.';

const firebaseDeleteUnusedFiles = ({ collectionItem, uploadedFilesData }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (uploadedFilesData) {
            // get items to delete
            const deletableItems = Object.keys(uploadedFilesData)
                .map(dbField => {
                    const uploadedFile = uploadedFilesData[dbField];
                    const hasPreviousFileData = collectionItem && collectionItem[dbField];

                    if (hasPreviousFileData && !_.isEqual(uploadedFile.storagePath, collectionItem[dbField].storagePath)) {
                        return collectionItem[dbField];
                    }

                    return undefined;
                })
                .filter(item => item !== undefined);

            if (deletableItems.length) {
                // delete items
                const deleteRequests = deletableItems.map(deletableItem => {
                    return dispatch(firebaseDeleteFile(deletableItem.storagePath));
                });

                return awaitRequests(deleteRequests).then(deleteResponses => {
                    return promise(deleteResponses);
                });
            }
        }

        return promise();
    };
};

export default firebaseDeleteUnusedFiles;
