import { awaitRequests, promise } from '../../../helpers';
import { deleteFacilitatorManager } from '../facilitators-managers';

const afterDeleteFacilitatorsItem = ({ managersIds }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (managersIds) {
            if (managersIds.length) {
                const reqs = [];
                managersIds.forEach(manager => {
                    reqs.push(dispatch(deleteFacilitatorManager(manager)));
                });
                return awaitRequests(reqs);
            }
        }

        return promise();
    };
};

export default afterDeleteFacilitatorsItem;
