import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderIf from '../render-if';
import { StyledParagraph, StyledWrapper, StyledAvatar } from './styled';
import { getInitials } from '../../helpers';
import { firebaseGetFile } from '../../store/actions/firebase';

const Avatar = ({ label, imgSrc, size, className, tooltip, name, labelColor, getImageFile }) => {
    const [avatarImage, setAvatarImage] = useState();

    useEffect(() => {
        if (imgSrc && imgSrc !== '') {
            if (imgSrc.includes('avatars/')) {
                getImageFile(imgSrc).then(url => {
                    setAvatarImage(url);
                });
            } else if (imgSrc.includes('avatar')) {
                // set avatar
                setAvatarImage(`/img/icons/avatars/${imgSrc}.svg`);
            } else {
                setAvatarImage(imgSrc);
            }
        } else {
            setAvatarImage();
        }
    }, [imgSrc, getImageFile]);

    const haslabel = !!label;

    return (
        <StyledWrapper className={`${className}`}>
            <StyledAvatar src={avatarImage} title={tooltip} size={size} initials={getInitials(name)} />
            {/* label */}
            <RenderIf isTrue={haslabel}>
                <StyledParagraph size={size} labelColor={labelColor} className="ml-3 mb-0 align-self-center">
                    {label}
                </StyledParagraph>
            </RenderIf>
        </StyledWrapper>
    );
};

Avatar.propTypes = {
    imgSrc: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
    name: PropTypes.string,
    tooltip: PropTypes.string,
    className: PropTypes.string,
    labelColor: PropTypes.string,
    getImageFile: PropTypes.func,
};
Avatar.defaultProps = {
    label: '',
    imgSrc: undefined,
    size: 'medium',
    name: 'User',
    tooltip: '',
    className: '',
    labelColor: '#adb5bd',
    getImageFile: () => {},
};

const mapDispatchToProps = dispatch => {
    return {
        getImageFile: (image, extension) => dispatch(firebaseGetFile(image, extension)),
    };
};

const enhance = compose(connect(null, mapDispatchToProps));

export default enhance(Avatar);
