import { awaitRequests, promise } from '../../../helpers';
import { sendCountryManagerInvitation } from './index';
import { updateProfileManagementRelations } from '../general';

const addCountry = ({ data }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('countries')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            // send invitation
            reqs.push(dispatch(sendCountryManagerInvitation({ item })));

            // update auth profileId in related collections
            reqs.push(
                dispatch(
                    updateProfileManagementRelations({
                        email: item.managerEmail,
                    }),
                ),
            );

            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addCountry;
