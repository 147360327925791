import { awaitRequests, promise } from '../../../helpers';
import { firebaseDelete } from '../firebase';
import { deleteScheduleForAssignment } from '../schedules';

const deleteTutoring = (docId, where) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // after delete
        const afterDelete = item => {
            const reqs = [];

            reqs.push(dispatch(deleteScheduleForAssignment(item)));

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- delete by docId
        if (docId) {
            return dispatch(firebaseDelete({ collection: 'tutorings', docId })).then(item => afterDelete(item));
        }

        // -- delete where
        if (where) {
            return dispatch(firebaseDelete({ collection: 'tutorings', where })).then(deletedItems =>
                awaitRequests(deletedItems.map(item => afterDelete(item))),
            );
        }

        return promise();
    };
};

export default deleteTutoring;
