import { promise } from '../../../helpers';
import { updateRegionalEntity } from './index';

const updateRegionalEntities = props => {
    const { where } = props;

    if (where) {
        return updateRegionalEntity(props);
    }

    return promise();
};

export default updateRegionalEntities;
