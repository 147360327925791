import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';

const updateManagersRelations = (name, email) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (email && name) {
            const reqs = [];

            // update manager data in countries
            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'countries',
                        where: [['managerEmail', '==', email]],
                        data: { managerName: name },
                    }),
                ),
            );

            // update manager data in corporate entities
            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'corporateEntities',
                        where: [['managerEmail', '==', email]],
                        data: { managerName: name },
                    }),
                ),
            );

            // update manager data in managers
            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'managers',
                        where: [['email', '==', email]],
                        data: { name },
                    }),
                ),
            );

            // update manager data in facilitators managers
            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'facilitatorsManagers',
                        where: [['email', '==', email]],
                        data: { name },
                    }),
                ).then(facilitatorsManagers =>
                    // update relations names in facilitator groups using the manager id
                    awaitRequests(
                        facilitatorsManagers.map(facilitatorManager =>
                            dispatch(
                                firebaseUpdate({
                                    collection: 'facilitatorsGroups',
                                    where: [['facilitatorId', '==', facilitatorManager.id]],
                                    data: { facilitatorName: name },
                                }),
                            ),
                        ),
                    ),
                ),
            );

            // update manager data in participants
            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'participants',
                        where: [['email', '==', email]],
                        data: { name },
                    }),
                ),
            );

            return awaitRequests(reqs);
        }

        return promise();
    };
};

export default updateManagersRelations;
