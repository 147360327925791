import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { getTaskSubmissions, saveTaskSubmissionFiles } from './index';
import { upsertGradeForSubmission } from '../grades';

const updateTaskSubmission = ({ docId, where, data, uploadList }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'taskSubmissions', docId: id, data: d }));

        // -- after update
        const afterUpdate = item => {
            const reqs = [];

            // upload files
            reqs.push(dispatch(saveTaskSubmissionFiles(item, uploadList)));
            reqs.push(dispatch(upsertGradeForSubmission(item, 'task')));

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- single update
        if (docId && data) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getTaskSubmissions({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise();
    };
};

export default updateTaskSubmission;
