const sendPasswordReset = ({ email }) => {
    return async (dispatch, getState, getFirebase) => {
        const { hostname } = window.location;
        // send create account token
        return getFirebase()
            .auth()
            .sendPasswordResetEmail(email, { url: `https://${hostname}/salir` });
    };
};

export default sendPasswordReset;
