import { awaitRequests } from '../../../helpers';
import { findParticipantsByIds } from './index';
import { getProfile } from '../profiles';

const findParticipantsProfilesByIds = participantsIds => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        return dispatch(findParticipantsByIds(participantsIds)).then(participants => {
            return awaitRequests(participants.map(({ profileId }) => dispatch(getProfile({ docId: profileId }))));
        });
    };
};

export default findParticipantsProfilesByIds;
