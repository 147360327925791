import { promise } from '../../../helpers';
import { getItemsFromSnapshot } from '../../../helpers/firebase';

const getParticipantTeam = ({ teamTaskId, where }) => {
    return (dispatch, getState, getFirebase) => {
        let queryIds = getFirebase()
            .firestore()
            .collection('teamTasks')
            .doc(teamTaskId)
            .collection('teams');

        if (where && where.length) {
            for (let i = 0; i < where.length; i += 1) {
                queryIds = queryIds.where(where[i][0], where[i][1], where[i][2]);
            }
        }

        const getTeamsIds = () => {
            return queryIds.get().then(snapshot => promise(getItemsFromSnapshot(snapshot)));
        };

        return getTeamsIds();
    };
};

export default getParticipantTeam;
