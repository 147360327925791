import { promise } from '../../../helpers';
import { getLearningCommunityGroups } from './index';

const validateOnSaveLearningCommunityGroup = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const { id: learningCommunityGroupId, corporateEntityId, regionalEntityId, learningCommunityId } = item;

        return dispatch(
            getLearningCommunityGroups({
                where: [
                    ['corporateEntityId', '==', corporateEntityId],
                    ['regionalEntityId', '==', regionalEntityId],
                    ['learningCommunityId', '==', learningCommunityId],
                ],
            }),
        ).then(items => {
            if (items.length) {
                // "is unique" validation
                const isUnique =
                    items.filter(learningCommunityGroup => {
                        if (learningCommunityGroup.id === learningCommunityGroupId) {
                            return false;
                        }

                        return true;
                    }).length === 0;

                if (!isUnique) {
                    return promise({
                        success: false,
                        errorMsg: 'Ya existe el listado de grupos para la comunidad de aprendizaje seleccionada.',
                    });
                }
            }

            return promise({ success: true });
        });
    };
};

export default validateOnSaveLearningCommunityGroup;
