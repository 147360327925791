import { awaitRequests, promise } from '../../../helpers';
import { getItemsFromSnapshot } from '../../../helpers/firebase';
import { firebaseGet } from './index';

const firebaseUpdate = ({ collection, docId, where, data }) => {
    return (dispatch, getState, getFirebase) => {
        // -- get
        const get = id => dispatch(firebaseGet({ collection, docId: id }));

        // -- update
        const update = (d, id, prevData) => {
            if (prevData) {
                return getFirebase()
                    .firestore()
                    .collection(collection)
                    .doc(id)
                    .update(d)
                    .then(() => promise({ ...prevData, ...d, id }));
            }

            return get(id).then(item => {
                if (item) {
                    return getFirebase()
                        .firestore()
                        .collection(collection)
                        .doc(id)
                        .update(d)
                        .then(() => promise({ ...item, ...d, id }));
                }

                return promise(undefined);
            });
        };

        // -- single update
        if (collection && docId && data) {
            return update(data, docId);
        }

        // -- plural update
        if (collection && where && where.length && data) {
            // eslint-disable-next-line
            let query = getFirebase()
                .firestore()
                .collection(collection);

            if (where && where.length) {
                for (let i = 0; i < where.length; i += 1) {
                    query = query.where(where[i][0], where[i][1], where[i][2]);
                }
            }

            return query.get().then(snapshot => {
                const items = getItemsFromSnapshot(snapshot);

                return awaitRequests(items.map(item => update(data, item.id, item)));
            });
        }

        return promise();
    };
};

export default firebaseUpdate;
