import { PDFDocument, StandardFonts } from 'pdf-lib';
import { DateTime } from 'luxon';
import { getCourseAssignation } from '../courses-assignations';
import { addCertificate } from '.';
import { getFieldValue } from '../../../helpers/firebase';

const createFromTemplate = ({ courseId, corporateEntityId, groupId, participantId, participantName }) => {
    return async (dispatch, getState, getFirebase) => {
        const cAssignation = await dispatch(
            getCourseAssignation({
                where: [
                    ['courseId', '==', courseId],
                    ['corporateEntityId', '==', corporateEntityId],
                ],
            }),
        );
        if (!cAssignation || !cAssignation.certificateTemplateFile) {
            return false;
        }
        return new Promise((resolve, reject) => {
            const storageRef = getFirebase()
                .storage()
                .ref(cAssignation.certificateTemplateFile.storagePath);
            storageRef.getDownloadURL().then(function download(url) {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'arraybuffer';
                xhr.onload = async function load() {
                    // PDF Modification
                    const arrayBuffer = xhr.response;
                    if (!arrayBuffer) reject();
                    const byteArray = new Uint8Array(arrayBuffer);
                    const pdfDoc = await PDFDocument.load(byteArray);
                    const pages = pdfDoc.getPages();
                    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
                    pages[0].drawText(participantName, {
                        x: 125,
                        y: 370,
                        size: 25,
                        font: helveticaFont,
                    });
                    pages[0].drawText(cAssignation.courseName, {
                        x: 125,
                        y: 240,
                        size: 20,
                        font: helveticaFont,
                    });
                    const currentJSDate = new Date();
                    const current = DateTime.fromJSDate(currentJSDate)
                        .toLocal()
                        .toLocaleString({ locale: 'es', ...DateTime.DATE_FULL });
                    pages[0].drawText(current, {
                        x: 300,
                        y: 150,
                        size: 20,
                        font: helveticaFont,
                    });
                    const pdfBytes = await pdfDoc.save();
                    const certificateFile = new File([pdfBytes], 'certificate.pdf', {
                        type: 'application/pdf',
                    });
                    const item = {
                        groupId,
                        courseId,
                        participantId,
                        lastUpdate: getFieldValue().serverTimestamp(),
                    };
                    const uploadList = [];
                    uploadList.push({ file: certificateFile, dbField: 'file', baseFolder: 'certificates', fileFolder: 'files' });
                    resolve(dispatch(addCertificate({ data: item, uploadList })));
                };
                xhr.open('GET', url);
                xhr.send();
            });
        });
    };
};

export default createFromTemplate;
