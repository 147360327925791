import { promise } from '../../../helpers';
import { validateCountry } from '../../../views/admin/countries/form/helpers';
import addCountry from './addCountry';

const addCountriesBatch = batchData => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const asyncRequests = [];
        const invalidItems = [];
        const profileId = getState().auth.profile.id;

        batchData.forEach(data => {
            const { País: name, Encargado: managerName, 'Correo Electrónico': managerEmail } = data;

            const item = {
                name,
                managerName,
                managerEmail,
                counters: {
                    booksCounter: 0,
                    facilitatorsCounter: 0,
                    groupsParticipantsCounter: 0,
                    learningCommunitiesCounter: 0,
                },
                managerProfileId: profileId,
            };

            if (validateCountry(item)) {
                asyncRequests.push(
                    dispatch(addCountry({ data: item }))
                        .then(() => {
                            return promise({
                                success: true,
                                item,
                            });
                        })
                        .catch(() => {
                            return promise({
                                success: false,
                                item,
                            });
                        }),
                );
            } else {
                invalidItems.push(item);
            }
        });

        return Promise.all(asyncRequests).then(responses => {
            const completed = responses.filter(res => res.success).length;
            const failed = responses.filter(res => !res.success).length;
            const invalid = invalidItems.length;

            return promise({
                completed,
                failed,
                invalid,
            });
        });
    };
};

export default addCountriesBatch;
