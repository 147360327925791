import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { getFacilitatorsManagers } from './index';

const updateFacilitatorManager = ({ docId, where, data }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'facilitatorsManagers', docId: id, data: d }));

        // -- after update
        const afterUpdate = item => promise(item);

        // -- single update
        if (data && docId) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getFacilitatorsManagers({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise();
    };
};

export default updateFacilitatorManager;
