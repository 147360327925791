import { awaitRequests, promise } from '../../../helpers';
import { updateCorporateEntities } from '../corporate-entities';

const afterDeleteCountry = ({ docId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (docId) {
            const reqs = [];

            // clear name in corporateEntities after delete country
            reqs.push(
                dispatch(
                    updateCorporateEntities({
                        where: [['countryId', '==', docId]],
                        data: { countryId: '', countryName: '--' },
                    }),
                ),
            );

            return awaitRequests(reqs).then(() => {
                return promise();
            });
        }

        return promise();
    };
};

export default afterDeleteCountry;
