import { promise } from '../../../helpers';
import { getCoursesAssignations } from './index';

const validateOnSaveCourseAssignation = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const { id: courseAssignationId, corporateEntityId, courseId, dueDate } = item;

        return dispatch(
            getCoursesAssignations({
                where: [
                    ['corporateEntityId', '==', corporateEntityId],
                    ['courseId', '==', courseId],
                    ['dueDate', '==', dueDate],
                ],
            }),
        ).then(items => {
            if (items.length) {
                // "is unique" validation
                const isUnique =
                    items.filter(courseAssignation => {
                        if (courseAssignation.id === courseAssignationId) {
                            return false;
                        }

                        return true;
                    }).length === 0;

                if (!isUnique) {
                    return promise({
                        success: false,
                        errorMsg: 'La entidad corporativa, curso y fecha de vencimiento enviados ya se encuentran registrados.',
                    });
                }
            }

            return promise({ success: true });
        });
    };
};

export default validateOnSaveCourseAssignation;
