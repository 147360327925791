import { getFieldValue } from '../../../helpers/firebase';

const updateUnreadMessages = ({ chatRoom, profileId, set, incrementBy }) => {
    // eslint-disable-next-line
    return async (dispatch, getState, getFirebase) => {
        const doc = getFirebase()
            .firestore()
            .collection('chatRooms')
            .doc(chatRoom.id)
            .collection('stats')
            .doc(profileId);

        const docGet = await doc.get();

        if (!docGet.exists) {
            if (typeof incrementBy !== 'undefined') return doc.set({ unreadMessages: incrementBy });
            if (typeof set !== 'undefined') return doc.set({ unreadMessages: set });
        } else {
            if (typeof incrementBy !== 'undefined') return doc.update({ unreadMessages: getFieldValue().increment(incrementBy) });
            if (typeof set !== 'undefined') return doc.update({ unreadMessages: set });
        }
    };
};

export default updateUnreadMessages;
