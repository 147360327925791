import { awaitRequests, promise } from '../../../helpers';
import { getCoursesAssignations, saveCourseAssignationFiles } from './index';
import { firebaseUpdate } from '../firebase';

const updateCourseAssignation = ({ docId, where, data, uploadList }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'coursesAssignations', docId: id, data: d }));

        // -- after update
        const afterUpdate = item => {
            const reqs = [];

            // save book files
            reqs.push(dispatch(saveCourseAssignationFiles({ item, uploadList })));

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- single update
        if (docId && data) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getCoursesAssignations({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise();
    };
};

export default updateCourseAssignation;
