import { getTutorings, addTutoring } from '../tutorings';
import { getGroupsParticipantsItem } from '../groups-participants';
import { exportActivities } from './index';

const exportTutorings = data => {
    return exportActivities({
        ...data,
        getter: getTutorings,
        adder: item => {
            return async dispatch => {
                // necesitamos meter a todos en la tutoria
                const { participantsIds = [] } = await dispatch(
                    getGroupsParticipantsItem({ where: [['groupId', '==', data.toGroupId]] }),
                );
                return dispatch(addTutoring({ data: { ...item.data, inTutoring: participantsIds } }));
            };
        },
        dateField: 'startDateTime',
    });
};

export default exportTutorings;
