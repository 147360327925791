import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';

const updateLearningCommunityGroupRelations = ({ groupName, groupId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (groupName && groupId) {
            const reqs = [];

            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'groupsParticipants',
                        where: [['groupId', '==', groupId]],
                        data: { groupName: groupName || '' },
                    }),
                ),
            );

            return awaitRequests(reqs);
        }

        return promise();
    };
};

export default updateLearningCommunityGroupRelations;
