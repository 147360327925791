import { awaitRequests, promise } from '../../../helpers';
import { updateCoursesAssignations } from '../courses-assignations';
import { updateFacilitatorsGroupsItems } from '../facilitators-groups';
import { updateFacilitatorsItems } from '../facilitators';
import { updateGroupsParticipantsItems } from '../groups-participants';
import { updateLearningCommunities } from '../learning-communities';
import { updateLearningCommunityGroups } from '../learning-community-groups';
import { updateManagersRelations } from '../general';
import { updateRegionalEntities } from '../regional-entities';

const updateCorporateEntityRelations = ({ docId, data }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (docId && data) {
            const { managerName, managerEmail } = data;

            const reqs = [];

            reqs.push(
                dispatch(
                    updateCoursesAssignations({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityName: data.name || '--' },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    updateRegionalEntities({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityName: data.name || '--' },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    updateLearningCommunities({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityName: data.name || '--' },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    updateFacilitatorsItems({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityName: data.name || '--' },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    updateLearningCommunityGroups({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityName: data.name || '--' },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    updateGroupsParticipantsItems({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityName: data.name || '--' },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    updateFacilitatorsGroupsItems({
                        where: [['corporateEntityId', '==', docId]],
                        data: { corporateEntityName: data.name || '--' },
                    }),
                ),
            );

            reqs.push(dispatch(updateManagersRelations(managerName, managerEmail)));

            return awaitRequests(reqs).then(() => promise());
        }

        return promise();
    };
};

export default updateCorporateEntityRelations;
