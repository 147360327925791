import { awaitRequests, promise } from '../../../helpers';

const addSchedule = ({ data }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('schedules')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addSchedule;
