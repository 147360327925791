import { awaitRequests, promise } from '../../../helpers';
import { getItemsFromSnapshot } from '../../../helpers/firebase';

const firebaseUpdateNested = ({ collection, subcollection, where, data }) => {
    return (dispatch, getState, getFirebase) => {
        // -- get documents in the collection

        // -- update
        const update = (d, id, subid, prevData) => {
            return getFirebase()
                .firestore()
                .collection(collection)
                .doc(id)
                .collection(subcollection)
                .doc(subid)
                .update(d)
                .then(() => promise({ ...prevData, ...d, id, subid }));
        };

        // -- update each document in the subcollection
        if (where && where.length && data) {
            getFirebase()
                .firestore()
                .collection(collection)
                .get()
                .then(docs => {
                    docs.forEach(document => {
                        let query = getFirebase()
                            .firestore()
                            .collection(collection)
                            .doc(document.id)
                            .collection(subcollection);
                        if (where && where.length) {
                            for (let i = 0; i < where.length; i += 1) {
                                query = query.where(where[i][0], where[i][1], where[i][2]);
                            }
                        }
                        return query.get().then(snapshot => {
                            const items = getItemsFromSnapshot(snapshot);
                            return awaitRequests(items.map(item => update(data, document.id, item.id, item)));
                        });
                    });
                });
        }

        return promise();
    };
};

export default firebaseUpdateNested;
