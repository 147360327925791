import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { getUniqueId } from '../../helpers';
import RenderIf from '../render-if';
import { StyledFileSelector, StyledFileInputOverlay } from './styled';
import { StyledErrorMessage, StyledMessage } from '../input/styled';

function formatBytes(a, b = 2) {
    if (a === 0) return '0 Bytes';
    const c = b < 0 ? 0 : b;
    const d = Math.floor(Math.log(a) / Math.log(1024));
    const number = parseFloat(a / (1024 ** d).toFixed(c));
    const postfix = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
    return `${number} ${postfix}`;
}

const FileInput = ({
    id,
    accept,
    label,
    placeholder,
    error,
    required,
    onChange,
    withOverlay,
    onOverlayClick,
    inputRef,
    className,
    multiple,
    maxFileSize,
}) => {
    const [isOverlayHovered, setIsOverlayHovered] = useState();
    const [validationError, setValidationError] = useState(false);
    const inputId = id || getUniqueId('file-input');

    const validateFileSize = (value, ...attrs) => {
        if (!maxFileSize) {
            onChange(value, attrs);
            return;
        }
        const valid = Array.from(value).every(file => file.size <= maxFileSize);
        if (valid) {
            setValidationError(false);
            onChange(value, attrs);
        } else {
            setValidationError(`Elige un archivo de menor tamaño.`);
        }
    };

    const hasError = !!error || !!validationError;

    return (
        <div className={`mb-4 ${className}`}>
            <Form.Group controlId={inputId} className="app-line-height-0">
                <Form.Label className="text-app-gray app-font-14 font-weight-500 mb-2">
                    {label}

                    <RenderIf isTrue={required}>
                        <span className="app-font-18 text-primary app-line-height-0">&nbsp;*</span>
                    </RenderIf>
                </Form.Label>

                <div className="position-relative">
                    <StyledFileSelector
                        ref={inputRef}
                        accept={accept}
                        placeholder={placeholder}
                        onChange={validateFileSize}
                        isOverlayHovered={isOverlayHovered}
                        multiple={multiple}
                    />

                    <RenderIf isTrue={withOverlay}>
                        <StyledFileInputOverlay
                            onClick={() => onOverlayClick()}
                            onMouseEnter={() => setIsOverlayHovered(true)}
                            onMouseLeave={() => setIsOverlayHovered(false)}
                        />
                    </RenderIf>
                </div>

                <RenderIf isTrue={!!maxFileSize}>
                    <StyledMessage>Tamaño máximo de archivo es de {formatBytes(maxFileSize)}</StyledMessage>
                </RenderIf>

                <RenderIf isTrue={hasError}>
                    <StyledErrorMessage>{error || validationError}</StyledErrorMessage>
                </RenderIf>
            </Form.Group>
        </div>
    );
};

FileInput.propTypes = {
    id: PropTypes.string,
    accept: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    withOverlay: PropTypes.bool,
    onOverlayClick: PropTypes.func,
    inputRef: PropTypes.any,
    className: PropTypes.string,
    multiple: PropTypes.bool,
    maxFileSize: PropTypes.string,
};

FileInput.defaultProps = {
    id: '',
    label: '',
    accept: undefined,
    placeholder: 'Seleccionar',
    error: '',
    required: false,
    onChange: () => {},
    withOverlay: false,
    onOverlayClick: undefined,
    inputRef: undefined,
    className: '',
    multiple: false,
    maxFileSize: '31457280', // 30 MBs
};

export default FileInput;
