import { promise } from '../../../helpers';
import { firebaseDeleteUnusedFiles, firebaseGet, firebaseUpdate, firebaseUploadFiles } from '.';

const firebaseUpdateFiles = ({ collection, docId, uploadList }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        if (collection && docId && uploadList && uploadList.length) {
            // --
            return dispatch(firebaseGet({ collection, docId })).then(collectionItem => {
                // --
                if (collectionItem) {
                    // --
                    return dispatch(firebaseUploadFiles({ docId, uploadList })).then(uploadResponses => {
                        if (uploadResponses.length) {
                            const uploadedFilesData = {};

                            uploadResponses.forEach(uploadResponse => {
                                const { success, storageData, uploadItem } = uploadResponse;
                                const { dbField } = uploadItem;

                                if (success) {
                                    uploadedFilesData[dbField] = storageData;
                                }
                            });

                            return dispatch(firebaseUpdate({ collection, docId, data: uploadedFilesData })).then(() => {
                                return dispatch(firebaseDeleteUnusedFiles({ collectionItem, uploadedFilesData })).then(() => {
                                    return promise(uploadedFilesData);
                                });
                            });
                        }

                        return promise();
                    });
                }

                return promise();
            });
        }

        return promise();
    };
};

export default firebaseUpdateFiles;
