import { promise } from '../../../helpers';
import { updateSchedule, getSchedule, addSchedule } from './index';

const upsertScheduleForAssignment = (assignment, participantsIds, type) => {
    // eslint-disable-next-line
    return async (dispatch, getState, getFirebase) => {
        if (!assignment || !type || !participantsIds) {
            return promise();
        }

        const setParticipantSchedule = async (query, data, participantId) => {
            const participantQuery = ['participantId', '==', participantId];
            const schedule = await dispatch(getSchedule({ where: [query, participantQuery] }));

            if (schedule) {
                return dispatch(updateSchedule({ docId: schedule.id, data }));
            }

            return dispatch(addSchedule({ data }));
        };

        const data = {
            courseId: assignment.courseId,
            groupId: assignment.groupId,
            assignmentId: assignment.id,
            date: assignment.endDateTime || assignment.initialDate,
            title: assignment.title,
            type,
        };

        if (!data.date) {
            return false;
        }

        const assignmentIdQuery = ['assignmentId', '==', assignment.id];

        const promises = participantsIds.map(participantId => {
            return setParticipantSchedule(assignmentIdQuery, { ...data, participantId }, participantId);
        });

        return Promise.all(promises);
    };
};

export default upsertScheduleForAssignment;
