import { awaitRequests, promise } from '../../../helpers';
import { sendGroupsParticipantsInvitations, updateGroupsParticipantsList } from './index';
import { updateProfileManagementRelations } from '../general';

const addGroupsParticipantsItem = ({ data, participants }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('groupsParticipants')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            // update groups participants list
            reqs.push(
                dispatch(updateGroupsParticipantsList({ docId: item.id, participants })).then(() => {
                    return dispatch(sendGroupsParticipantsInvitations({ item, participants }));
                }),
            );

            // update auth profileId management
            if (participants && participants.length) {
                reqs.push(
                    awaitRequests(
                        participants.map(({ email }) =>
                            dispatch(
                                updateProfileManagementRelations({
                                    email,
                                }),
                            ),
                        ),
                    ),
                );
            }

            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addGroupsParticipantsItem;
