import { DateTime } from 'luxon';

function areDatesEqual(date1, date2) {
    const first = DateTime.fromJSDate(date1);
    const second = DateTime.fromJSDate(date2);

    return +first.startOf('day') === +second.startOf('day');
}

export default areDatesEqual;
