import { awaitRequests, promise } from '../../../helpers';
import { saveExamFiles } from '.';
import { getGroupsParticipantsItem } from '../groups-participants';
import { upsertScheduleForAssignment } from '../schedules';

const addExam = ({ data, uploadList }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('exams')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = async item => {
            const reqs = [];

            // upload files
            reqs.push(dispatch(saveExamFiles(item, uploadList)));
            if (item.groupId) {
                const requestParticipants = await dispatch(
                    getGroupsParticipantsItem({ where: [['groupId', '==', item.groupId]] }),
                );
                if (requestParticipants.participantsIds) {
                    reqs.push(dispatch(upsertScheduleForAssignment(item, requestParticipants.participantsIds, 'exam')));
                }
            }
            return awaitRequests(reqs).then(() => promise(item));
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addExam;
