import React from 'react';
import PropTypes from 'prop-types';

function WeekDayLabel({ value, className }) {
    const mapDays = {
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo',
    };

    return (
        <div className={className}>
            Semana {value.week} ({mapDays[value.day]})
        </div>
    );
}

WeekDayLabel.propTypes = {
    value: PropTypes.shape({
        week: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        day: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
};

WeekDayLabel.defaultProps = {
    className: 'app-font-14 font-weight-500',
};

export default WeekDayLabel;
