import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { firebaseReducer, getFirebase } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { save, load } from 'redux-localstorage-simple';
import { authReducer, notificationsReducer, uiReducer, tutorialReducer } from './reducers';

const middlewares = [thunk.withExtraArgument(getFirebase), save()];

const reducers = combineReducers({
    ui: uiReducer,
    auth: authReducer,
    notifications: notificationsReducer,
    tutorial: tutorialReducer,

    firebase: firebaseReducer,
    firestore: firestoreReducer,
});

const composeEnhancer =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
        : compose;

const createStoreWithLocalStorageMiddleware = composeEnhancer(applyMiddleware(...middlewares))(createStore);

const store = createStoreWithLocalStorageMiddleware(reducers, load());

export default store;
