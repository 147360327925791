import { DateTime } from 'luxon';
import { awaitRequests, promise } from '../../../helpers';

const exportActivities = ({
    fromCourseId,
    toCourseId,
    toGroupId,
    toModelCourseId,
    initialDate,
    getter,
    adder,
    dateField = 'endDateTime',
    isModelCourse = true,
}) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (!fromCourseId || (!toGroupId && !toModelCourseId)) {
            return promise({ error: 'Missing parameters' }, 'reject');
        }

        const getterParams = [['courseId', '==', fromCourseId]];
        if (isModelCourse) {
            getterParams.push(['modelCourse', '==', true]);
        }

        // busca las actividades que sean del curso modelo
        return dispatch(
            getter({
                where: getterParams,
            }),
        ).then(acts => {
            const reqs = [];
            acts.forEach(act => {
                // Separa la información del curso modelo
                const { id, modelCourse, weekday, ...data } = act;
                if (!isModelCourse && modelCourse) return;
                if (toGroupId) data.groupId = toGroupId;
                if (toCourseId) data.courseId = toCourseId;
                if (toModelCourseId) {
                    data.courseId = toModelCourseId;
                    data.modelCourse = true;
                }
                // Agregamos la fecha de la actividad de acuerdo a la opción seleccionada
                if (weekday && initialDate) {
                    // Luxon siempre usa lunes como primer dia, la interfaz usa domingo como primer dia
                    const days = { sunday: -1, monday: 0, tuesday: 1, wednesday: 2, thursday: 3, friday: 4, saturday: 5 };
                    const date = DateTime.fromJSDate(initialDate)
                        .startOf('week')
                        .plus({ weeks: weekday.week, days: days[weekday.day] });
                    data[dateField] = date.toJSDate();
                    // en caso de que sea una fecha inicial, necesitamos poner una fecha final para la actividad
                    if (dateField === 'startDateTime') {
                        data.endDateTime = date.plus({ hours: 1 }).toJSDate();
                    }
                }
                reqs.push(dispatch(adder({ data })));
            });
            return awaitRequests(reqs);
        });
    };
};

export default exportActivities;
