import { promise } from '../../../helpers';
import { firebaseUpdateMultipleFiles } from '../firebase';

const saveExamSubmissionFiles = (item, uploadList) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        if (uploadList && uploadList.length) {
            return dispatch(firebaseUpdateMultipleFiles({ collection: 'examSubmissions', docId: item.id, uploadList })).then(
                uploadedFilesData => {
                    if (uploadedFilesData) {
                        return promise({
                            ...item,
                            ...uploadedFilesData,
                        });
                    }

                    return promise(item);
                },
            );
        }

        return promise();
    };
};

export default saveExamSubmissionFiles;
