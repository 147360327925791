import { promise } from '../../../helpers';

const firebaseGetFile = (filepath, extension) => {
    return (dispatch, getState, getFirebase) => {
        let path = '';
        if (extension) path = `${filepath}.${extension}`;
        else path = filepath;

        if (path !== '') {
            return getFirebase()
                .storage()
                .ref()
                .child(path)
                .getDownloadURL()
                .then(url => {
                    return promise(url);
                })
                .catch(() => {
                    return promise(null);
                });
        }

        return promise(undefined);
    };
};

export default firebaseGetFile;
