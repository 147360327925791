import { promise } from '../../../helpers';
import { getCorporateEntities } from './index';

const validateOnSaveCorporateEntity = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const { id: corporateEntityId, name, countryId, idSAP } = item;

        return dispatch(
            getCorporateEntities({
                where: [
                    ['name', '==', name],
                    ['countryId', '==', countryId],
                    ['idSAP', '==', idSAP],
                ],
            }),
        ).then(items => {
            if (items.length) {
                // "is unique" validation
                const isUnique =
                    items.filter(corporateEntity => {
                        if (corporateEntity.id === corporateEntityId) {
                            return false;
                        }

                        return true;
                    }).length === 0;

                if (!isUnique) {
                    return promise({
                        success: false,
                        errorMsg: 'La entidad corporativa, idSAP y país seleccionados ya se encuentran registrados.',
                    });
                }
            }

            return promise({ success: true });
        });
    };
};

export default validateOnSaveCorporateEntity;
