import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from '../index';
import { StyledSidebarUser, StyledSidebarUserAvatar, StyledSidebarUsersList, StyledSidebarUsersListWrapper } from './styled';

function ChatParticipant({ participant, onParticipantClick }) {
    const [unreadMessagesDoc, setUnreadMessagesDoc] = useState();
    const [unreadMessages, setUnreadMessages] = useState();

    useEffect(() => {
        const { getUnreadMessagesStatsDoc, chatRoomRef } = participant;
        let cancel = false;

        if (getUnreadMessagesStatsDoc) {
            getUnreadMessagesStatsDoc(chatRoomRef).then(doc => {
                if (cancel) return;
                setUnreadMessagesDoc(doc);
            });
        }
        return () => {
            cancel = true;
        };
    }, [participant]);

    useEffect(() => {
        let cancel = false;
        let unsubscribe = () => {};

        if (unreadMessagesDoc) {
            unsubscribe = unreadMessagesDoc.onSnapshot(doc => {
                if (cancel) return;
                if (!doc.exists) return;
                setUnreadMessages(doc.data().unreadMessages);
            });
        }
        return () => {
            cancel = true;
            unsubscribe();
        };
    }, [unreadMessagesDoc]);

    const { name, firstName, lastName, image } = participant;
    const fullName = name || `${firstName} ${lastName}`;

    return (
        <StyledSidebarUser onClick={() => onParticipantClick(participant)}>
            <StyledSidebarUserAvatar imgSrc={image} name={fullName} size="small" />
            <div className="pl-3">{fullName}</div>
            <RenderIf isTrue={!!unreadMessages && unreadMessages > 0}>
                <span className="badge bg-success rounded-pill ml-2 p-2 app-font-14" style={{ color: 'white' }}>
                    {unreadMessages}
                </span>
            </RenderIf>
        </StyledSidebarUser>
    );
}

ChatParticipant.propTypes = {
    participant: PropTypes.any,
    onParticipantClick: PropTypes.func,
};

ChatParticipant.defaultProps = {
    participant: {},
    onParticipantClick: () => {},
};

function ChatParticipantsList({ currentParticipant, chatParticipants, onParticipantClick }) {
    if (currentParticipant && chatParticipants) {
        return (
            <StyledSidebarUsersListWrapper>
                <StyledSidebarUsersList>
                    {/* user to chat */}
                    {chatParticipants
                        .filter(participant => participant.id !== currentParticipant.id)
                        .map((participant, index) => {
                            const key = `chat-participant-${index}`;

                            return (
                                <ChatParticipant participant={participant} key={key} onParticipantClick={onParticipantClick} />
                            );
                        })}
                </StyledSidebarUsersList>
            </StyledSidebarUsersListWrapper>
        );
    }

    return (
        <StyledSidebarUsersListWrapper>
            <StyledSidebarUsersList />
        </StyledSidebarUsersListWrapper>
    );
}

ChatParticipantsList.propTypes = {
    currentParticipant: PropTypes.any,
    chatParticipants: PropTypes.any,
    onParticipantClick: PropTypes.func,
};

ChatParticipantsList.defaultProps = {
    currentParticipant: undefined,
    chatParticipants: undefined,
    onParticipantClick: () => {},
};

export default ChatParticipantsList;
