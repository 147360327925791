import { promise } from '../../../helpers';
import { updateCorporateEntity } from './index';

const updateCorporateEntities = props => {
    const { where } = props;

    if (where) {
        return updateCorporateEntity(props);
    }

    return promise();
};

export default updateCorporateEntities;
