import React from 'react';
import PropTypes from 'prop-types';
import { HelpText } from 'react-rainbow-components';

function HelpPopup({ title, text, popupWidth, variant }) {
    const style = { width: popupWidth };
    return <HelpText title={title} variant={variant} text={<p style={style}>{text}</p>} />;
}

HelpPopup.propTypes = {
    variant: PropTypes.oneOf(['question', 'info', 'error', 'warning']),
    popupWidth: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
};

HelpPopup.defaultProps = {
    variant: 'info',
    popupWidth: '250px',
    title: '',
    text: '',
};

export default HelpPopup;
