import { addCourseAssignation } from '.';
import { promise } from '../../../helpers';
import { validateCourseAssignation } from '../../../views/admin/courses-assignations/form/helpers';
import { getCorporateEntity } from '../corporate-entities';
import { getCourse } from '../courses';

const addCoursesAssignationsBatch = batchData => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const asyncRequests = [];
        const invalidItems = [];

        batchData.forEach(data => {
            const {
                'Entidad Corporativa': corporateEntityName,
                Curso: courseName,
                'Número de licencias': licenses,
                'Fecha de vencimiento': dueDate,
            } = data;

            const item = {
                corporateEntityName,
                courseName,
                licenses,
                dueDate,
            };
            if (validateCourseAssignation(item)) {
                dispatch(getCorporateEntity({ where: [['name', '==', corporateEntityName]] })).then(corporateEntity => {
                    if (corporateEntity === undefined) invalidItems.push(item);
                    else {
                        item.corporateEntityId = corporateEntity.id;
                        dispatch(getCourse({ where: [['name', '==', courseName]] })).then(course => {
                            if (course === undefined) invalidItems.push(item);
                            else {
                                item.courseId = course.id;
                                asyncRequests.push(
                                    dispatch(addCourseAssignation({ data: item }))
                                        .then(() => {
                                            return promise({
                                                success: true,
                                                item,
                                            });
                                        })
                                        .catch(() => {
                                            return promise({
                                                success: false,
                                                item,
                                            });
                                        }),
                                );
                            }
                        });
                    }
                });
            } else {
                invalidItems.push(item);
            }
        });

        return Promise.all(asyncRequests).then(responses => {
            const completed = responses.filter(res => res.success).length;
            const failed = responses.filter(res => !res.success).length;
            const invalid = invalidItems.length;

            return promise({
                completed,
                failed,
                invalid,
            });
        });
    };
};

export default addCoursesAssignationsBatch;
