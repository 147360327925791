import { promise, runXMLHttpRequest } from '../../../helpers';
import { firebaseGet } from '../firebase';
import { setInvitationToken } from '../invitations';

const resendGroupsParticipantsInvitations = ({ email }, { groupName, learningCommunityName }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        if (email) {
            const hasProfileRequest = dispatch(firebaseGet({ collection: 'profiles', where: [['email', '==', email]] }));

            hasProfileRequest.then(profiles => {
                if (profiles.length) {
                    const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
                    const requestUrl = `${baseUrl}/email-sendInvitation`;

                    const params = {
                        email,
                        section: 'participant',
                        sectionName: groupName,
                        sectionName2: learningCommunityName,
                    };

                    // run email send
                    runXMLHttpRequest(requestUrl, params);
                } else {
                    const afterCreateInvitation = dispatch(setInvitationToken(email));

                    return afterCreateInvitation.then(invitation => {
                        const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
                        const requestUrl = `${baseUrl}/email-sendInvitation`;

                        const params = {
                            token: invitation.token,
                            email,
                            section: 'participant',
                            sectionName: groupName,
                            sectionName2: learningCommunityName,
                        };

                        // run email send
                        runXMLHttpRequest(requestUrl, params);
                    });
                }
                return promise();
            });
        }

        return promise();
    };
};

export default resendGroupsParticipantsInvitations;
