import { promise } from '../../../helpers';
import { getCorporateEntity } from '../corporate-entities';
import { getCourse } from '../courses';
import { getFacilitator } from '../facilitators';
import { getFacilitatorManager } from '../facilitators-managers';
import { getGroup } from '../groups';
import { getLearningCommunity } from '../learning-communities';
import { getRegionalEntity } from '../regional-entities';
import { addFacilitatorsGroupsItem } from '.';
import { getLearningCommunityGroup } from '../learning-community-groups';
import { getCourseLicense } from '../courses-licenses';

// eslint-disable-next-line
const addFacilitatorsGroupsBatch = batchData => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const asyncRequests = [];
        // get course licenses

        batchData.forEach(data => {
            const {
                'Entidad Corporativa': corporateEntityName,
                'Entidad Regional': regionalEntityName,
                'Comunidad de aprendizaje': learningCommunityName,
                Grupo: groupName,
                Curso: courseName,
                'Nombre del facilitador': facilitatorName,
            } = data;

            const item = {
                corporateEntityName,
                regionalEntityName,
                learningCommunityName,
                groupName,
                courseName,
                facilitatorName,
            };

            dispatch(getCorporateEntity({ where: [['name', '==', corporateEntityName]] })).then(corpEntity => {
                if (corpEntity !== undefined) {
                    item.corporateEntityId = corpEntity.id;
                    dispatch(
                        getRegionalEntity({
                            where: [
                                ['corporateEntityId', '==', corpEntity.id],
                                ['name', '==', regionalEntityName],
                            ],
                        }),
                    ).then(regEntity => {
                        if (regEntity !== undefined) {
                            item.regionalEntityId = regEntity.id;
                            dispatch(
                                getLearningCommunity({
                                    where: [
                                        ['corporateEntityId', '==', corpEntity.id],
                                        ['regionalEntityId', '==', regEntity.id],
                                        ['name', '==', learningCommunityName],
                                    ],
                                }),
                            ).then(learningCommunity => {
                                if (learningCommunity !== undefined) {
                                    item.learningCommunityId = learningCommunity.id;
                                    dispatch(getFacilitatorManager({ where: [['name', '==', facilitatorName]] })).then(
                                        facilitator => {
                                            if (facilitator !== undefined) {
                                                item.facilitatorId = facilitator.id;
                                                dispatch(
                                                    getFacilitator({
                                                        where: [
                                                            ['corporateEntityId', '==', corpEntity.id],
                                                            ['regionalEntityId', '==', regEntity.id],
                                                            ['learningCommunityId', '==', learningCommunity.id],
                                                            ['managersIds', 'array-contains', facilitator.id],
                                                        ],
                                                    }),
                                                ).then(facilitatorInfo => {
                                                    if (facilitatorInfo !== undefined) {
                                                        dispatch(getCourse({ where: [['name', '==', courseName]] })).then(
                                                            course => {
                                                                if (course !== undefined) {
                                                                    item.courseId = course.id;
                                                                    dispatch(
                                                                        getLearningCommunityGroup({
                                                                            where: [
                                                                                [
                                                                                    'learningCommunityId',
                                                                                    '==',
                                                                                    learningCommunity.id,
                                                                                ],
                                                                            ],
                                                                        }),
                                                                    ).then(lcgroup => {
                                                                        if (lcgroup !== undefined) {
                                                                            dispatch(
                                                                                getGroup({
                                                                                    where: [
                                                                                        [
                                                                                            'learningCommunityGroupId',
                                                                                            '==',
                                                                                            lcgroup.id,
                                                                                        ],
                                                                                        ['name', '==', groupName],
                                                                                    ],
                                                                                }),
                                                                            ).then(group => {
                                                                                if (group !== undefined) {
                                                                                    item.groupId = group.id;
                                                                                    dispatch(
                                                                                        getCourseLicense({
                                                                                            where: [
                                                                                                [
                                                                                                    'corporateEntityId',
                                                                                                    '==',
                                                                                                    corpEntity.id,
                                                                                                ],
                                                                                                [
                                                                                                    'learningCommunityId',
                                                                                                    '==',
                                                                                                    learningCommunity.id,
                                                                                                ],
                                                                                                ['itemType', '==', 'license'],
                                                                                                ['courseName', '==', courseName],
                                                                                            ],
                                                                                        }),
                                                                                    ).then(success => {
                                                                                        if (success !== undefined) {
                                                                                            item.courseLicenseId = success.id;
                                                                                            asyncRequests.push(
                                                                                                dispatch(
                                                                                                    addFacilitatorsGroupsItem({
                                                                                                        data: item,
                                                                                                    }),
                                                                                                )
                                                                                                    .then(() => {
                                                                                                        return promise({
                                                                                                            success: true,
                                                                                                            item,
                                                                                                        });
                                                                                                    })
                                                                                                    .catch(() => {
                                                                                                        return promise({
                                                                                                            success: false,
                                                                                                            item,
                                                                                                        });
                                                                                                    }),
                                                                                            );
                                                                                        }
                                                                                    });
                                                                                }
                                                                            });
                                                                        }
                                                                    });
                                                                }
                                                            },
                                                        );
                                                    }
                                                });
                                            }
                                        },
                                    );
                                }
                            });
                        }
                    });
                }
            });
            // leer entidad corporativa
            // leer entidad regional (con dato corporativa)
            // leer comunidad aprendizaje (con dato regional o corporativa tambien)
            // leer grupo, curso y facilitador (con dato de cualquier entidad)
            // en cada lectura, si regresa undefined, es item invalido
            // caso contrario, se genera el item, y se hace la adicion al asyncRequest con el addFacGroups
        });

        return Promise.all(asyncRequests).then(responses => {
            const completed = responses.filter(res => res.success).length;
            const failed = responses.filter(res => !res.success).length;

            return promise({
                completed,
                failed,
                invalid: 0,
            });
        });
    };
};

export default addFacilitatorsGroupsBatch;
