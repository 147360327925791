import { awaitRequests, promise } from '../../../helpers';
import { deleteCourseLicense, getCoursesLicenses } from '../courses-licenses';
import { updateFacilitatorsItems } from '../facilitators';
import { updateLearningCommunities } from '../learning-communities';
import { updateLearningCommunityGroups } from '../learning-community-groups';
import { updateGroupsParticipantsItems } from '../groups-participants';
import { updateFacilitatorsGroupsItems } from '../facilitators-groups';
import { counterIncrement } from '../admin';

const afterDeleteRegionalEntity = ({ docId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (docId) {
            const reqs = [];

            // delete regional entity licenses
            reqs.push(
                dispatch(getCoursesLicenses({ where: [['regionalEntityId', '==', docId]] })).then(licenses =>
                    awaitRequests(licenses.map(license => dispatch(deleteCourseLicense(license.id)))),
                ),
            );

            // update regional entity names in learning communities
            reqs.push(
                dispatch(
                    updateLearningCommunities({
                        where: [['regionalEntityId', '==', docId]],
                        data: {
                            regionalEntityId: '',
                            regionalEntityName: '--',
                        },
                    }),
                ),
            );

            // update regional entity names in facilitators
            reqs.push(
                dispatch(
                    updateFacilitatorsItems({
                        where: [['regionalEntityId', '==', docId]],
                        data: {
                            regionalEntityId: '',
                            regionalEntityName: '--',
                        },
                    }),
                ),
            );

            // update regional entity names in learningCommunityGroups
            reqs.push(
                dispatch(
                    updateLearningCommunityGroups({
                        where: [['regionalEntityId', '==', docId]],
                        data: {
                            regionalEntityId: '',
                            regionalEntityName: '--',
                        },
                    }),
                ),
            );

            // update regional entity names in groupsParticipants
            reqs.push(
                dispatch(
                    updateGroupsParticipantsItems({
                        where: [['regionalEntityId', '==', docId]],
                        data: {
                            regionalEntityId: '',
                            regionalEntityName: '--',
                        },
                    }),
                ),
            );

            // update regional entity names in facilitatorsGroups
            reqs.push(
                dispatch(
                    updateFacilitatorsGroupsItems({
                        where: [['regionalEntityId', '==', docId]],
                        data: {
                            regionalEntityId: '',
                            regionalEntityName: '--',
                        },
                    }),
                ),
            );

            reqs.push(dispatch(counterIncrement('regionalEntities', -1)));

            return awaitRequests(reqs).then(() => {
                return promise();
            });
        }

        return promise();
    };
};

export default afterDeleteRegionalEntity;
