/* eslint-disable import/first */
import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Layout from './components/layout';
import RouteWithLayout from './components/route-with-layout';

// session pages
const LoginPage = lazy(() => import('./views/session/login'));
const LogoutPage = lazy(() => import('./views/session/logout'));
const PrepareSessionPage = lazy(() => import('./views/session/prepare-session'));
const SetAccountPage = lazy(() => import('./views/session/set-account'));
const PasswordForgotPage = lazy(() => import('./views/session/password-forgot'));

// profile pages
const AccountSettingsPage = lazy(() => import('./views/profile/account'));
const PasswordChangePage = lazy(() => import('./views/profile/password-change'));

// dashboard tab
const AdminDashboardPage = lazy(() => import('./views/admin/dashboard'));

// administration tab
const AdminCountriesPage = lazy(() => import('./views/admin/countries'));
const AdminCorporateEntitiesPage = lazy(() => import('./views/admin/corporate-entities'));
const AdminBooksCategoriesPage = lazy(() => import('./views/admin/books'));
const AdminBookCategoryPage = lazy(() => import('./views/admin/books/category'));
const AdminBookPreviewPage = lazy(() => import('./views/admin/books/preview'));
const AdminBookPreviewPdfPage = lazy(() => import('./views/admin/books/preview/pdf'));
const AdminCoursesPage = lazy(() => import('./views/admin/courses'));
const AdminModelCoursePage = lazy(() => import('./views/admin/model-courses'));
const AdminCoursesAssignationsPage = lazy(() => import('./views/admin/courses-assignations'));

// community tab
const AdminRegionalEntitiesPage = lazy(() => import('./views/admin/regional-entities'));
const AdminLearningCommunitiesPage = lazy(() => import('./views/admin/learning-communities'));
const AdminFacilitatorsPage = lazy(() => import('./views/admin/facilitators'));
const AdminLearningCommunityGroupsPage = lazy(() => import('./views/admin/learning-community-groups'));
const AdminGroupsParticipantsPage = lazy(() => import('./views/admin/groups-participants'));
const AdminFacilitatorsGroupsPage = lazy(() => import('./views/admin/facilitators-groups'));

// licenses
const AdminDashboardLicenses = lazy(() => import('./views/admin/licenses'));
const AdminBooksLicensesPage = lazy(() => import('./views/admin/licenses/books'));
const AdminBooksUsagePage = lazy(() => import('./views/admin/licenses/books-usage'));
const AdminCoursesAssignationLicensePage = lazy(() => import('./views/admin/licenses/courses'));
const AdminCountriesLicensePage = lazy(() => import('./views/admin/licenses/countries'));
const AdminCorporateEntitiesLicensePage = lazy(() => import('./views/admin/licenses/corporate-entities'));
const AdminRegionalEntitiesLicensePage = lazy(() => import('./views/admin/licenses/regional-entities'));
const AdminLearningCommunitiesLicensePage = lazy(() => import('./views/admin/licenses/learning-communities'));

// static pages
const CommonQuestionsPage = lazy(() => import('./views/static/common-questions'));
const ContactSupportPage = lazy(() => import('./views/static/contact-support'));
const HowItWorksPage = lazy(() => import('./views/static/how-it-works'));
const NotFoundPage = lazy(() => import('./views/static/not-found'));
const NoticePrivacyPage = lazy(() => import('./views/static/notice-privacy'));
const TermsAndConditionsPage = lazy(() => import('./views/static/terms-and-conditions'));
const UpdateProfileIdPage = lazy(() => import('./views/static/update-profile-id'));

// course pages
const CoursesPage = lazy(() => import('./views/courses'));
const CourseBookViewerPage = lazy(() => import('./views/courses/books/viewer'));
const CourseCategoriesPage = lazy(() => import('./views/courses/categories'));
const CoursePage = lazy(() => import('./views/courses/course'));
const CourseChatPage = lazy(() => import('./views/courses/chat'));
const CourseItineraryPage = lazy(() => import('./views/courses/itinerary'));
const CourseResourcesPage = lazy(() => import('./views/courses/resources'));
const CourseCalendarPage = lazy(() => import('./views/courses/calendar'));
const CourseRecordedClassesPage = lazy(() => import('./views/courses/recorded-classes'));
const CourseRecordedClassesWatchPage = lazy(() => import('./views/courses/recorded-classes/video-play'));
const CourseLiveClassesPage = lazy(() => import('./views/courses/live-classes'));
const CourseTutoringPage = lazy(() => import('./views/courses/tutorings'));
const CourseTasksPage = lazy(() => import('./views/courses/tasks'));
const CourseTaskPage = lazy(() => import('./views/courses/tasks/task-page'));
const CourseTeamTasksPage = lazy(() => import('./views/courses/team-tasks'));
const CourseTeamTaskPage = lazy(() => import('./views/courses/team-tasks/task-page'));
const CourseExamsPage = lazy(() => import('./views/courses/exams'));
const CourseExamPage = lazy(() => import('./views/courses/exams/exam-page'));
const CourseForumsPage = lazy(() => import('./views/courses/forums'));
const CourseForumPage = lazy(() => import('./views/courses/forums/forum'));
const CourseResultsPage = lazy(() => import('./views/courses/results'));
const CourseCertificatePage = lazy(() => import('./views/courses/certificate'));

const AppRoutes = () => {
    return (
        <Switch>
            {/* session routes */}
            <RouteWithLayout exact path="/" component={LoginPage} />
            <RouteWithLayout exact path="/salir" component={LogoutPage} />
            <RouteWithLayout
                exact
                path="/preparar-sesion"
                component={PrepareSessionPage}
                requiresAuth
                requiresSessionData={false}
            />
            <RouteWithLayout exact path="/set-account/:email/:token" component={SetAccountPage} />
            <RouteWithLayout exact path="/password-forgot" component={PasswordForgotPage} />

            {/* static routes */}
            <RouteWithLayout exact path="/preguntas-frecuentes" component={CommonQuestionsPage} layout={Layout} />
            <RouteWithLayout exact path="/contacto-soporte" component={ContactSupportPage} layout={Layout} />
            <RouteWithLayout exact path="/como-funciona" component={HowItWorksPage} layout={Layout} />
            <RouteWithLayout exact path="/404" component={NotFoundPage} />
            <RouteWithLayout exact path="/avisoprivacidad" component={NoticePrivacyPage} />
            <RouteWithLayout exact path="/aviso-privacidad" component={NoticePrivacyPage} layout={Layout} />
            <RouteWithLayout exact path="/terminoscondiciones" component={TermsAndConditionsPage} />
            <RouteWithLayout exact path="/terminos-condiciones" component={TermsAndConditionsPage} layout={Layout} />
            <RouteWithLayout exact path="/update-profile" component={UpdateProfileIdPage} />

            {/* profile routes */}
            <RouteWithLayout exact path="/cuenta" component={AccountSettingsPage} layout={Layout} />
            <RouteWithLayout exact path="/cuenta/contrasena" component={PasswordChangePage} layout={Layout} />

            {/* admin: administration tab */}
            <RouteWithLayout exact path="/admin/dashboard" component={AdminDashboardPage} layout={Layout} requiresAuth />
            <RouteWithLayout exact path="/admin/paises" component={AdminCountriesPage} layout={Layout} requiresAuth />
            <RouteWithLayout
                exact
                path="/admin/entidades-corporativas"
                component={AdminCorporateEntitiesPage}
                layout={Layout}
                requiresAuth
            />
            <RouteWithLayout exact path="/admin/libros/categorias" component={AdminBooksCategoriesPage} layout={Layout} />
            <RouteWithLayout exact path="/admin/libros/categoria/:categoryId" component={AdminBookCategoryPage} layout={Layout} />
            <RouteWithLayout
                exact
                path="/admin/libros/categoria/:categoryId/:new"
                component={AdminBookCategoryPage}
                layout={Layout}
            />
            <RouteWithLayout exact path="/admin/libro/:bookId/visualizar" component={AdminBookPreviewPage} layout={Layout} />
            <RouteWithLayout
                exact
                path="/admin/libro/:bookId/visualizar/pdf"
                component={AdminBookPreviewPdfPage}
                layout={Layout}
            />
            <RouteWithLayout exact path="/admin/cursos" component={AdminCoursesPage} layout={Layout} requiresAuth />
            <RouteWithLayout
                exact
                path="/admin/cursos/asignaciones"
                component={AdminCoursesAssignationsPage}
                layout={Layout}
                requiresAuth
            />
            <RouteWithLayout exact path="/admin/curso/:courseId" component={AdminModelCoursePage} layout={Layout} requiresAuth />

            {/* admin: community tab */}
            <RouteWithLayout
                exact
                path="/admin/entidades-regionales"
                component={AdminRegionalEntitiesPage}
                layout={Layout}
                requiresAuth
            />
            <RouteWithLayout
                exact
                path="/admin/comunidades-de-aprendizaje"
                component={AdminLearningCommunitiesPage}
                layout={Layout}
                requiresAuth
            />
            <RouteWithLayout exact path="/admin/facilitadores" component={AdminFacilitatorsPage} layout={Layout} requiresAuth />
            <RouteWithLayout
                exact
                path="/admin/grupos"
                component={AdminLearningCommunityGroupsPage}
                layout={Layout}
                requiresAuth
            />
            <RouteWithLayout
                exact
                path="/admin/participantes"
                component={AdminGroupsParticipantsPage}
                layout={Layout}
                requiresAuth
            />
            <RouteWithLayout
                exact
                path="/admin/facilitadores/asignaciones"
                component={AdminFacilitatorsGroupsPage}
                layout={Layout}
                requiresAuth
            />

            <RouteWithLayout exact path="/admin/licencias" component={AdminDashboardLicenses} layout={Layout} requiresAuth />
            <RouteWithLayout path="/admin/licencias/libros" component={AdminBooksLicensesPage} layout={Layout} requiresAuth />
            <RouteWithLayout path="/admin/licencias/uso" component={AdminBooksUsagePage} layout={Layout} requiresAuth />
            <RouteWithLayout path="/admin/licencias/cursos" component={AdminCoursesAssignationLicensePage} layout={Layout} requiresAuth />
            <RouteWithLayout path="/admin/licencias/pais" component={AdminCountriesLicensePage} layout={Layout} requiresAuth />
            <RouteWithLayout path="/admin/licencias/entidad-corporativa" component={AdminCorporateEntitiesLicensePage} layout={Layout} requiresAuth />
            <RouteWithLayout path="/admin/licencias/entidad-regional" component={AdminRegionalEntitiesLicensePage} layout={Layout} requiresAuth />
            <RouteWithLayout path="/admin/licencias/comunidad-de-aprendizaje" component={AdminLearningCommunitiesLicensePage} layout={Layout} requiresAuth />

            {/* course routes */}
            <RouteWithLayout exact path="/cursos" component={CoursesPage} layout={Layout} requiresAuth />
            <RouteWithLayout exact path="/cursos/:categoryId" component={CourseCategoriesPage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId/itinerario" component={CourseItineraryPage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId/calendario" component={CourseCalendarPage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId" component={CoursePage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId/libro/:bookId" component={CourseBookViewerPage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId/recursos" component={CourseResourcesPage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId/recursos/:resourceId" component={CourseResourcesPage} layout={Layout} />
            <RouteWithLayout
                exact
                path="/curso/:courseId/clases-grabadas/:recordedClassId"
                component={CourseRecordedClassesWatchPage}
                layout={Layout}
            />
            <RouteWithLayout
                exact
                path="/curso/:courseId/clases-grabadas"
                component={CourseRecordedClassesPage}
                layout={Layout}
            />
            <RouteWithLayout
                exact
                path="/curso/:courseId/clases-presenciales"
                component={CourseLiveClassesPage}
                layout={Layout}
            />
            {/* course tutoring */}
            <RouteWithLayout exact path="/curso/:courseId/asesorias" component={CourseTutoringPage} layout={Layout} />
            {/*course tasks */}
            <RouteWithLayout exact path="/curso/:courseId/tareas" component={CourseTasksPage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId/tarea/:taskId" component={CourseTaskPage} layout={Layout} />
            {/*course team tasks */}
            <RouteWithLayout exact path="/curso/:courseId/tareas-en-equipo" component={CourseTeamTasksPage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId/tareas-en-equipo/:teamTaskId" component={CourseTeamTaskPage} layout={Layout} />
            {/*course exams */}
            <RouteWithLayout exact path="/curso/:courseId/examenes" component={CourseExamsPage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId/examen/:examId" component={CourseExamPage} layout={Layout} />
            {/*course chat */}
            <RouteWithLayout exact path="/curso/:courseId/chat/general" component={CourseChatPage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId/chat/foros" component={CourseForumsPage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId/chat/foro/:forumId" component={CourseForumPage} layout={Layout} />
            {/*course results table */}
            <RouteWithLayout exact path="/curso/:courseId/resultados" component={CourseResultsPage} layout={Layout} />
            <RouteWithLayout exact path="/curso/:courseId/certificado" component={CourseCertificatePage} layout={Layout} />

            {/*course model routes*/}
            <RouteWithLayout exact path="/admin/curso/:courseId/itinerario" component={CourseItineraryPage} layout={Layout} />
            <RouteWithLayout exact path="/admin/curso/:courseId/recursos" component={CourseResourcesPage} layout={Layout} />
            <RouteWithLayout exact path="/admin/curso/:courseId/recursos/:resourceId" component={CourseResourcesPage} layout={Layout} />
            <RouteWithLayout
                exact
                path="/admin/curso/:courseId/clases-grabadas/:recordedClassId"
                component={CourseRecordedClassesWatchPage}
                layout={Layout}
            />
            <RouteWithLayout
                exact
                path="/admin/curso/:courseId/clases-grabadas"
                component={CourseRecordedClassesPage}
                layout={Layout}
            />
            <RouteWithLayout
                exact
                path="/admin/curso/:courseId/clases-presenciales"
                component={CourseLiveClassesPage}
                layout={Layout}
            />
            <RouteWithLayout exact path="/admin/curso/:courseId/asesorias" component={CourseTutoringPage} layout={Layout} />
            <RouteWithLayout exact path="/admin/curso/:courseId/tareas" component={CourseTasksPage} layout={Layout} />
            <RouteWithLayout exact path="/admin/curso/:courseId/tarea/:taskId" component={CourseTaskPage} layout={Layout} />
            <RouteWithLayout exact path="/admin/curso/:courseId/tareas-en-equipo" component={CourseTeamTasksPage} layout={Layout} />
            <RouteWithLayout exact path="/admin/curso/:courseId/tareas-en-equipo/:teamTaskId" component={CourseTeamTaskPage} layout={Layout} />
            <RouteWithLayout exact path="/admin/curso/:courseId/examenes" component={CourseExamsPage} layout={Layout} />
            <RouteWithLayout exact path="/admin/curso/:courseId/examen/:examId" component={CourseExamPage} layout={Layout} />
            <RouteWithLayout exact path="/admin/curso/:courseId/chat/foros" component={CourseForumsPage} layout={Layout} />
            <RouteWithLayout exact path="/admin/curso/:courseId/chat/foro/:forumId" component={CourseForumPage} layout={Layout} />

            {/* default */}
            <Redirect to="/404" />
        </Switch>
    );
};

export default AppRoutes;
