import randomstring from 'randomstring';
import { promise } from '../../../helpers';
import { addInvitation, getInvitation } from './index';

const setInvitationToken = email => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        return dispatch(
            getInvitation({
                where: [['email', '==', email]],
            }),
        ).then(invitation => {
            if (invitation) {
                return promise(invitation);
            }

            return dispatch(
                addInvitation({
                    data: {
                        email,
                        token: randomstring.generate({
                            length: 33,
                        }),
                    },
                }),
            );
        });
    };
};

export default setInvitationToken;
