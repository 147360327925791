import { awaitRequests, promise } from '../../../helpers';
import { getManager } from './index';

const findManagersByIds = managersIds => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (managersIds && managersIds.length) {
            return awaitRequests(managersIds.map(docId => dispatch(getManager({ docId }))));
        }

        return promise([]);
    };
};

export default findManagersByIds;
