import { awaitRequests, promise } from '../../../helpers';
import { firebaseDelete } from '../firebase';
import { afterDeleteFacilitatorsItem } from './index';

const deleteFacilitatorsItem = (docId, where) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // after delete
        const afterDelete = item => {
            const reqs = [];

            if (item.length > 0) {
                item.forEach(element => {
                    reqs.push(dispatch(afterDeleteFacilitatorsItem({ managersIds: element.managersIds })));
                });
            } else if (item && item.id) {
                reqs.push(dispatch(afterDeleteFacilitatorsItem({ managersIds: item.managersIds })));
            }

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- delete by docId
        if (docId) {
            return dispatch(firebaseDelete({ collection: 'facilitators', docId })).then(item => afterDelete(item));
        }

        // -- delete where
        if (where) {
            return dispatch(firebaseDelete({ collection: 'facilitators', where })).then(deletedItems =>
                afterDelete(deletedItems),
            );
        }

        return promise();
    };
};

export default deleteFacilitatorsItem;
