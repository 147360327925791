import { awaitRequests, promise } from '../../../helpers';
import updateLearningCommunityGroupList from './updateLearningCommunityGroupList';

const addLearningCommunityGroup = ({ data, groups }) => {
    return (dispatch, getState, getFirebase) => {
        // -- add
        const add = d => {
            return getFirebase()
                .firestore()
                .collection('learningCommunityGroups')
                .add(d)
                .then(docRef => promise({ ...d, id: docRef.id }));
        };

        // after add
        const afterAdd = item => {
            const reqs = [];

            // -- update group items list
            reqs.push(dispatch(updateLearningCommunityGroupList({ docId: item.id, groups })));

            return awaitRequests(reqs).then(res => {
                const { groupsIds } = res[0];

                return promise({ item, groupsIds });
            });
        };

        // --
        if (data) {
            return add(data).then(item => afterAdd(item));
        }

        return promise();
    };
};

export default addLearningCommunityGroup;
