import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import SubMenuLink from './subMenuLink';

function CourseMenuItems({ isMobile, urlParams }) {
    const hasUrlParams = !!urlParams;
    const hasCourseIdParam = hasUrlParams && !!urlParams.courseId;
    const isModelEditMode = !!useRouteMatch('/admin/curso/');

    const { courseId } = hasUrlParams && urlParams;

    const course = useSelector(state => {
        return courseId && state.firestore.data[`course-${courseId}`];
    });

    const isQuickCourse = course && course.isQuickCourse;

    if (hasCourseIdParam) {
        let menu = [];
        if (isModelEditMode) {
            if (isQuickCourse) {
                menu = [
                    { name: 'Itinerario del curso', url: `/admin/curso/${courseId}/itinerario`, icon: '' },
                    { name: 'Libros', url: `/admin/curso/${courseId}`, icon: '' },
                    { name: 'Biblioteca de recursos', url: `/admin/curso/${courseId}/recursos`, icon: '' },
                    { name: 'Clases grabadas', url: `/admin/curso/${courseId}/clases-grabadas`, icon: '' },
                    { name: 'Tareas', url: `/admin/curso/${courseId}/tareas`, icon: '' },
                    { name: 'Exámenes', url: `/admin/curso/${courseId}/examenes`, icon: '' },
                ];
            } else {
                menu = [
                    { name: 'Itinerario del curso', url: `/admin/curso/${courseId}/itinerario`, icon: '' },
                    { name: 'Biblioteca de recursos', url: `/admin/curso/${courseId}/recursos`, icon: '' },
                    { name: 'Clases grabadas', url: `/admin/curso/${courseId}/clases-grabadas`, icon: '' },
                    { name: 'Clases presenciales', url: `/admin/curso/${courseId}/clases-presenciales`, icon: '' },
                    { name: 'Asesorías / Tutorías', url: `/admin/curso/${courseId}/asesorias`, icon: '' },
                    { name: 'Tareas', url: `/admin/curso/${courseId}/tareas`, icon: '' },
                    { name: 'Tareas en equipo', url: `/admin/curso/${courseId}/tareas-en-equipo`, icon: '' },
                    { name: 'Exámenes', url: `/admin/curso/${courseId}/examenes`, icon: '' },
                    { name: 'Foro', url: `/admin/curso/${courseId}/chat/foros`, icon: '' },
                ];
            }
        } else if (isQuickCourse) {
            menu = [
                { name: 'Itinerario del curso', url: `/curso/${courseId}/itinerario`, icon: '' },
                { name: 'Libros', url: `/curso/${courseId}`, icon: '' },
                { name: 'Biblioteca de recursos', url: `/curso/${courseId}/recursos`, icon: '' },
                { name: 'Clases grabadas', url: `/curso/${courseId}/clases-grabadas`, icon: '' },
                { name: 'Tareas', url: `/curso/${courseId}/tareas`, icon: '' },
                { name: 'Exámenes', url: `/curso/${courseId}/examenes`, icon: '' },
                { name: 'Tabla de resultados', url: `/curso/${courseId}/resultados`, icon: '' },
                { name: 'Certificado', url: `/curso/${courseId}/certificado`, icon: '' },
            ];
        } else {
            menu = [
                { name: 'Itinerario del curso', url: `/curso/${courseId}/itinerario`, icon: '' },
                { name: 'Calendario', url: `/curso/${courseId}/calendario`, icon: '' },
                { name: 'Libros', url: `/curso/${courseId}`, icon: '' },
                { name: 'Biblioteca de recursos', url: `/curso/${courseId}/recursos`, icon: '' },
                { name: 'Clases grabadas', url: `/curso/${courseId}/clases-grabadas`, icon: '' },
                { name: 'Clases presenciales', url: `/curso/${courseId}/clases-presenciales`, icon: '' },
                { name: 'Asesorías / Tutorías', url: `/curso/${courseId}/asesorias`, icon: '' },
                { name: 'Tareas', url: `/curso/${courseId}/tareas`, icon: '' },
                { name: 'Tareas en equipo', url: `/curso/${courseId}/tareas-en-equipo`, icon: '' },
                { name: 'Exámenes', url: `/curso/${courseId}/examenes`, icon: '' },
                { name: 'Chat', url: `/curso/${courseId}/chat/general`, icon: '' },
                { name: 'Foro', url: `/curso/${courseId}/chat/foros`, icon: '' },
                { name: 'Tabla de resultados', url: `/curso/${courseId}/resultados`, icon: '' },
                { name: 'Certificado', url: `/curso/${courseId}/certificado`, icon: '' },
            ];
        }

        return menu.map((menuItem, index) => {
            const key = `submenu-course-${index}`;

            return <SubMenuLink key={key} menuItem={menuItem} isMobile={isMobile} />;
        });
    }

    return <></>;
}

CourseMenuItems.propTypes = {
    isMobile: PropTypes.bool,
    urlParams: PropTypes.any,
};

CourseMenuItems.defaultProps = {
    isMobile: undefined,
    urlParams: undefined,
};

export default CourseMenuItems;
