import PropTypes from 'prop-types';
import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { updateProfileManagementRelations } from '../general';
import {
    getCorporateEntities,
    saveCorporateEntityFiles,
    sendCorporateEntityManagerInvitation,
    updateCorporateEntityRelations,
} from './index';

const updateCorporateEntity = ({ docId, where, data, prevManagerEmail, uploadList }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'corporateEntities', docId: id, data: d }));

        // -- after update
        const afterUpdate = item => {
            const { managerEmail } = item;
            const reqs = [];

            // update related data inside collections
            reqs.push(dispatch(updateCorporateEntityRelations({ docId: item.id, data: item })));

            // -- send invitation manager when is created or updated
            if (prevManagerEmail !== managerEmail) {
                reqs.push(dispatch(sendCorporateEntityManagerInvitation(item)));
            }

            // upload files
            reqs.push(dispatch(saveCorporateEntityFiles(item, uploadList)));

            // update auth profileId assignations
            reqs.push(dispatch(updateProfileManagementRelations({ email: managerEmail })));

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- single update
        if (data && docId) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getCorporateEntities({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise();
    };
};

updateCorporateEntity.propTypes = {
    uploadList: PropTypes.array,
};

updateCorporateEntity.defaultProps = {
    uploadList: [],
};

export default updateCorporateEntity;
