import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useMappedRows } from './hooks';
import AdminTableRow from './tr';

function AdminTableRows({
    rows,
    rowLevel,
    withColoredCircles,
    onClickViewRow,
    onClickEditRow,
    onClickDeleteRow,
    onClickSendRow,
    columnsSettings,
    requestsSettings,
}) {
    const mappedRows = useMappedRows(rows, requestsSettings, rowLevel);

    return (
        <>
            {mappedRows.map((item, index) => {
                const key = `tr-${index}`;

                return (
                    <AdminTableRow
                        key={key}
                        index={index}
                        row={item}
                        rowLevel={rowLevel}
                        columnsSettings={columnsSettings}
                        requestsSettings={requestsSettings}
                        withColoredCircles={withColoredCircles}
                        onClickViewRow={onClickViewRow}
                        onClickEditRow={onClickEditRow}
                        onClickDeleteRow={onClickDeleteRow}
                        onClickSendRow={onClickSendRow}
                    />
                );
            })}
        </>
    );
}

AdminTableRows.propTypes = {
    rows: PropTypes.any,
    rowLevel: PropTypes.number,
    withColoredCircles: PropTypes.bool,
    onClickViewRow: PropTypes.func,
    onClickEditRow: PropTypes.func,
    onClickDeleteRow: PropTypes.func,
    onClickSendRow: PropTypes.func,
    columnsSettings: PropTypes.any,
    requestsSettings: PropTypes.any,
};

AdminTableRows.defaultProps = {
    rows: undefined,
    rowLevel: 0,
    withColoredCircles: undefined,
    onClickViewRow: () => {},
    onClickEditRow: () => {},
    onClickDeleteRow: () => {},
    onClickSendRow: () => {},
    columnsSettings: undefined,
    requestsSettings: undefined,
};

let storeAsRefs = [];

const mapStateToProps = ({ firestore }, props) => {
    const stateData = {};
    const { rowLevel, requestsSettings } = props;

    // prepares the state data from request
    if (requestsSettings && requestsSettings[rowLevel]) {
        const reqSetting = requestsSettings[rowLevel];

        if (reqSetting.firebase) {
            if (storeAsRefs.length) {
                stateData.rows = storeAsRefs.reduce((res, storeAsRef) => {
                    if (!firestore.ordered[storeAsRef]) return res;
                    return [...res, ...firestore.ordered[storeAsRef]];
                }, []);
            }
        }
    }

    return stateData;
};

const splitInQueries = whereFilters => {
    const splitFilters = whereFilters.reduce(
        (res, item) => {
            const { whereEquality } = item;
            res[whereEquality === 'in' ? 'in' : 'other'].push(item);
            return res;
        },
        { in: [], other: [] },
    );
    if (splitFilters.in.length > 0) {
        const queries = splitFilters.in.map(filter => {
            const newFilter = { ...filter };
            newFilter.whereEquality = '==';
            return filter.whereValue.map(whereValue => {
                return [{ ...newFilter, whereValue }, ...splitFilters.other];
            });
        });
        return queries[0];
    }
    return [splitFilters.other];
};

const firestoreQuery = (state, props) => {
    const fsQuery = [];
    const { rowLevel, requestsSettings } = props;

    // prepares the request data
    if (requestsSettings && requestsSettings[rowLevel]) {
        const reqSetting = requestsSettings[rowLevel];

        if (reqSetting.firebase) {
            const { collection, whereFilters } = reqSetting.firebase;

            storeAsRefs = [];

            if (whereFilters && whereFilters.length) {
                const queries = splitInQueries(whereFilters);

                queries.forEach(query => {
                    const whereList = [];
                    const filtersReferences = query.map(whereFilter => {
                        const { whereField, whereEquality, whereValue } = whereFilter;

                        whereList.push([whereField, whereEquality, whereValue]);

                        return `${whereField}-${whereValue}`;
                    });
                    const storeAsRef = `${collection}-${filtersReferences.join('-')}`;
                    storeAsRefs.push(storeAsRef);

                    fsQuery.push({
                        collection,
                        where: whereList,
                        storeAs: storeAsRef,
                    });
                });
            } else {
                fsQuery.push({
                    collection,
                    storeAs: collection,
                });
                storeAsRefs.push(collection);
            }
        }
    }

    return fsQuery;
};

const enhance = compose(connect(mapStateToProps), firestoreConnect(firestoreQuery));

export default enhance(AdminTableRows);
