let idx = 0;

const getContrastingColor = () => {
    const goldenAngle = 180 * (3 - Math.sqrt(5));

    const makeColor = number => {
        const hue = number * goldenAngle + 60; // use golden angle approximation
        return hue;
    };

    function hslToHex(h, s, l) {
        // eslint-disable-next-line no-param-reassign
        l /= 100;
        const a = (s * Math.min(l, 1 - l)) / 100;
        const f = n => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color)
                .toString(16)
                .padStart(2, '0'); // convert to Hex and prefix "0" if needed
        };
        return `${f(0)}${f(8)}${f(4)}`;
    }

    idx += 1;

    return hslToHex(makeColor(idx), 100, 50);
};

export default getContrastingColor;
