import { getFacilitatorsGroupsItem } from './index';
import { getFacilitatorManager } from '../facilitators-managers';
import { getProfile } from '../profiles';

const getFacilitatorGroupProfile = ({ docId, where }) => {
    return dispatch => {
        const get = async () => {
            const facilitatorGroup = await dispatch(getFacilitatorsGroupsItem({ docId, where }));
            if (!facilitatorGroup || !facilitatorGroup.facilitatorId) return undefined;

            const facilitator = await dispatch(getFacilitatorManager({ docId: facilitatorGroup.facilitatorId }));
            if (!facilitator || !facilitator.profileId) return undefined;

            const profile = await dispatch(getProfile({ docId: facilitator.profileId }));

            return profile;
        };

        return get();
    };
};

export default getFacilitatorGroupProfile;
