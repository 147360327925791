import { awaitRequests, promise } from '../../../helpers';

const afterDeleteFacilitatorsGroupsItem = ({ docId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (docId) {
            const reqs = [];

            return awaitRequests(reqs).then(() => {
                return promise();
            });
        }

        return promise();
    };
};

export default afterDeleteFacilitatorsGroupsItem;
