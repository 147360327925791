import { getResources, addResource } from '../resources';
import { exportActivities } from './index';

const exportResources = data => {
    return async dispatch => {
        let categories;
        const getterCategories = ({ where }) => {
            return async () => {
                categories = await dispatch(getResources({ where: [...where, ['isCategory', '==', true]] }));
                return categories;
            };
        };
        const newCategories = await dispatch(exportActivities({ ...data, getter: getterCategories, adder: addResource }));

        const resources = [];

        categories.forEach((cat, idx) => {
            const getterResources = ({ where }) => {
                return async () => {
                    const res = await dispatch(getResources({ where: [...where, ['parentResourceId', '==', cat.id]] }));
                    const newRes = res.map(r => {
                        return { ...r, ...{ parentResourceId: newCategories[idx].id } };
                    });
                    return newRes;
                };
            };
            resources.push(dispatch(exportActivities({ ...data, getter: getterResources, adder: addResource })));
        });

        return [...newCategories, ...resources];
    };
};

export default exportResources;
