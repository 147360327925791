import styled from 'styled-components';
import { Chart, Card, Badge } from 'react-rainbow-components';

export const StyledCard = styled(Card)`
    width: 100%;
    ${props => `color: ${props.counterColor} !important`}
`;

export const StyledChart = styled(Chart)`
    height: 300px;
`;

export const StyledChartHeading = styled.div`
    padding-left: 7px;
    padding-right: 7px;

    h1 {
        font-size:1.5em;
        font-weight:bold;
        color: #a1a1a1;
    }

    @media (max-width: 991px) {
        flex-direction: column;
        &:nth-child(1) {
            text-align: center;
        }
    }
`;

function getCorrectTextColor(hex) {
    const threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

    function cutHex(h) {
        return h.charAt(0) === '#' ? h.substring(1, 7) : h;
    }
    function hexToR(h) {
        return parseInt(cutHex(h).substring(0, 2), 16);
    }
    function hexToG(h) {
        return parseInt(cutHex(h).substring(2, 4), 16);
    }
    function hexToB(h) {
        return parseInt(cutHex(h).substring(4, 6), 16);
    }

    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);

    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
        return '#000000';
    }
    return '#ffffff';
}

export const StyledBadge = styled(Badge)`
    ${props => `background-color: ${props.backgroundColor} !important`};
    ${props => `color: ${getCorrectTextColor(props.backgroundColor)} !important`};
    font-weight: bold;
`;
