import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { updateManagersRelations } from '../general';

const updateCountryRelations = ({ item, docId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (item && docId) {
            const { managerName, managerEmail } = item;
            const reqs = [];

            // update country name in related corporate entities
            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'corporateEntities',
                        where: [['countryId', '==', docId]],
                        data: { countryName: item.name || '--' },
                    }),
                ),
            );

            // update managers data in related collections
            reqs.push(dispatch(updateManagersRelations(managerName, managerEmail)));

            return awaitRequests(reqs);
        }

        return promise();
    };
};

export default updateCountryRelations;
