import { promise } from '../../../helpers';
import { getFacilitators } from './index';

const validateOnSaveFacilitatorsItem = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const { id: facilitatorId, corporateEntityId, regionalEntityId, learningCommunityId } = item;

        return dispatch(
            getFacilitators({
                where: [
                    ['corporateEntityId', '==', corporateEntityId],
                    ['regionalEntityId', '==', regionalEntityId],
                    ['learningCommunityId', '==', learningCommunityId],
                ],
            }),
        ).then(items => {
            if (items.length) {
                // "is unique" validation
                const isUnique =
                    items.filter(facilitators => {
                        if (facilitators.id === facilitatorId) {
                            return false;
                        }

                        return true;
                    }).length === 0;

                if (!isUnique) {
                    return promise({
                        success: false,
                        errorMsg:
                            'La comunidad de aprendizaje, entidad regional y entidad corporativa enviados ya se encuentran registrados.',
                    });
                }
            }

            return promise({ success: true });
        });
    };
};

export default validateOnSaveFacilitatorsItem;
