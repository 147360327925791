import { tutorialTypes } from '../types';

const initialState = {
    viewedTutorial: [],
};

export default function tutorialReducer(state = initialState, action) {
    switch (action.type) {
        case tutorialTypes.VIEWED_TUTORIAL:
            return {
                ...state,
                viewedTutorial: [...state.viewedTutorial, action.payload],
            };
        case tutorialTypes.SHOW_TUTORIAL:
            return {
                ...state,
                showTutorial: action.payload,
            };
        default:
            return state;
    }
}
