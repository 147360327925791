import { addTeamsForTask, saveTeamTaskFiles } from '.';
import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';

const updateTeamTask = ({ docId, data, uploadList, teams }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) => dispatch(firebaseUpdate({ collection: 'teamTasks', docId: id, data: d }));

        // -- after update
        const afterUpdate = item => {
            const reqs = [];

            // update teams
            reqs.push(dispatch(addTeamsForTask(item, teams)));

            // upload files
            reqs.push(dispatch(saveTeamTaskFiles(item, uploadList)));

            return awaitRequests(reqs).then(() => promise(item));
        };

        // -- single update
        if (docId && data) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        return promise();
    };
};

export default updateTeamTask;
