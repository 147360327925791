import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';

const updateLearningCommunityRelations = (item, docId) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (item && docId) {
            const reqs = [];

            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'facilitators',
                        where: [['learningCommunityId', '==', docId]],
                        data: { learningCommunityName: item.name || '' },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'learningCommunityGroups',
                        where: [['learningCommunityId', '==', docId]],
                        data: { learningCommunityName: item.name || '' },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'groupsParticipants',
                        where: [['learningCommunityId', '==', docId]],
                        data: { learningCommunityName: item.name || '' },
                    }),
                ),
            );

            reqs.push(
                dispatch(
                    firebaseUpdate({
                        collection: 'facilitatorsGroups',
                        where: [['learningCommunityId', '==', docId]],
                        data: { learningCommunityName: item.name || '' },
                    }),
                ),
            );

            return awaitRequests(reqs);
        }

        return promise();
    };
};

export default updateLearningCommunityRelations;
