import { awaitRequests, promise } from '../../../helpers';
import { getGroup } from './index';

const findGroupsByIds = groupsIds => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        if (groupsIds && groupsIds.length) {
            return awaitRequests(groupsIds.map(docId => dispatch(getGroup({ docId }))));
        }

        return promise([]);
    };
};

export default findGroupsByIds;
