import PropTypes from 'prop-types';
import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { getLearningCommunityGroups, updateLearningCommunityGroupList } from './index';

const updateLearningCommunityGroup = ({ data, docId, where, groups, prevGroups }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- update
        const update = (d, id) =>
            dispatch(
                firebaseUpdate({
                    collection: 'learningCommunityGroups',
                    docId: id,
                    data: d,
                }),
            );

        // -- after update
        const afterUpdate = item => {
            const reqs = [];

            // update the actual learning community groups
            reqs.push(dispatch(updateLearningCommunityGroupList({ docId: item.id, groups, prevGroups })));

            return awaitRequests(reqs).then(res => {
                const updatedGroups = res[0];
                const { groupsIds, groupsAdded } = updatedGroups;

                return promise({ item, groupsIds, groupsAdded });
            });
        };

        // -- single update
        if (data && docId) {
            return update(data, docId).then(updatedItem => afterUpdate(updatedItem));
        }

        // -- multiple update
        if (where && data) {
            return dispatch(getLearningCommunityGroups({ where })).then(items => {
                return awaitRequests(items.map(item => update(data, item.id).then(updatedItem => afterUpdate(updatedItem))));
            });
        }

        return promise({ item: undefined, groupsIds: [], groupsAdded: [] });
    };
};

updateLearningCommunityGroup.propTypes = {
    groups: PropTypes.array,
    prevGroups: PropTypes.array,
};

updateLearningCommunityGroup.defaultProps = {
    groups: [],
    prevGroups: [],
};

export default updateLearningCommunityGroup;
