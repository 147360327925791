import { awaitRequests, promise } from '../../../helpers';
import { getCourseLicense } from '../courses-licenses';
import { getLearningCommunityCourseLicensesUsage } from '../learning-communities';
import { getFacilitatorsGroupsItems } from './index';

const validateOnSaveFacilitatorGroupItem = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const {
            id: facilitatorGroupId,
            corporateEntityId,
            regionalEntityId,
            learningCommunityId,
            courseLicenseId,
            courseId,
            courseName,
            facilitatorId,
            groupId,
            isEditing,
        } = item;

        const reqs = [];

        // available licenses
        reqs.push(
            dispatch(
                getCourseLicense({
                    docId: courseLicenseId,
                }),
            ).then(courseLicense => promise(courseLicense.licenses)),
        );

        // used licenses
        reqs.push(
            dispatch(
                getLearningCommunityCourseLicensesUsage({
                    courseLicenseId,
                    learningCommunityId,
                }),
            ),
        );

        return awaitRequests(reqs).then(res => {
            // -- available licenses
            const licenses = res[0];

            // -- used licenses
            const usedLicenses = res[1];

            const licenseLimit = isEditing ? licenses + 1 : licenses;

            if (usedLicenses >= licenseLimit) {
                return promise({
                    success: false,
                    errorMsg: `El curso '${courseName}' no cuenta con licencias disponibles para esta asignación.`,
                });
            }

            return dispatch(
                getFacilitatorsGroupsItems({
                    where: [
                        ['corporateEntityId', '==', corporateEntityId],
                        ['regionalEntityId', '==', regionalEntityId],
                        ['learningCommunityId', '==', learningCommunityId],
                        ['courseId', '==', courseId],
                        ['facilitatorId', '==', facilitatorId],
                        ['groupId', '==', groupId],
                    ],
                }),
            ).then(items => {
                if (items.length) {
                    // "is unique" validation
                    const isUnique =
                        items.filter(facilitatorGroup => {
                            if (facilitatorGroup.id === facilitatorGroupId) {
                                return false;
                            }

                            return true;
                        }).length === 0;

                    if (!isUnique) {
                        return promise({
                            success: false,
                            errorMsg:
                                'El grupo ya fue asignado a otro facilitador para la comunidad de aprendizaje seleccionada.',
                        });
                    }
                }

                return promise({ success: true });
            });
        });
    };
};

export default validateOnSaveFacilitatorGroupItem;
