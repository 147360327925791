import { awaitRequests, promise } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { getCountries } from '../countries';
import { getCorporateEntities } from '../corporate-entities';
import { getFacilitatorsManagers } from '../facilitators-managers';
import { getManagers } from '../managers';
import { getParticipants } from '../participants';
import { getProfile } from '../profiles';

const updateProfileManagementRelations = ({ email }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- run auth profileId management data relations
        const runUpdates = profileId => {
            const reqs = [];

            // -- update profile id to related country managers
            reqs.push(
                dispatch(getCountries({ where: [['managerEmail', '==', email]] })).then(countries =>
                    awaitRequests(
                        countries.map(item =>
                            dispatch(
                                firebaseUpdate({
                                    collection: 'countries',
                                    docId: item.id,
                                    data: { managerProfileId: profileId },
                                }),
                            ),
                        ),
                    ),
                ),
            );

            // -- update profile id to related corporate entity managers
            reqs.push(
                dispatch(getCorporateEntities({ where: [['managerEmail', '==', email]] })).then(corporateEntities => {
                    return awaitRequests(
                        corporateEntities.map(item =>
                            dispatch(
                                firebaseUpdate({
                                    collection: 'corporateEntities',
                                    docId: item.id,
                                    data: { managerProfileId: profileId },
                                }),
                            ),
                        ),
                    );
                }),
            );

            // -- update profile id into the related managers list
            reqs.push(
                dispatch(getManagers({ where: [['email', '==', email]] })).then(managers =>
                    awaitRequests(
                        managers.map(item =>
                            dispatch(firebaseUpdate({ collection: 'managers', docId: item.id, data: { profileId } })),
                        ),
                    ),
                ),
            );

            // -- update profile id into the related facilitatorsManagers collection
            reqs.push(
                dispatch(getFacilitatorsManagers({ where: [['email', '==', email]] })).then(facilitatorsManagers =>
                    awaitRequests(
                        facilitatorsManagers.map(item =>
                            dispatch(firebaseUpdate({ collection: 'facilitatorsManagers', docId: item.id, data: { profileId } })),
                        ),
                    ),
                ),
            );

            // -- update profile id into the related participants collection
            reqs.push(
                dispatch(getParticipants({ where: [['email', '==', email]] })).then(participants =>
                    awaitRequests(
                        participants.map(item =>
                            dispatch(firebaseUpdate({ collection: 'participants', docId: item.id, data: { profileId } })),
                        ),
                    ),
                ),
            );

            return awaitRequests(reqs);
        };

        if (email) {
            return dispatch(getProfile({ where: [['email', '==', email]] })).then(profile => {
                if (profile) {
                    return runUpdates(profile.id);
                }

                return promise();
            });
        }

        return promise();
    };
};

export default updateProfileManagementRelations;
