import { promise } from '../../../helpers';
import { getGroupsParticipantsItems } from './index';

const validateOnSaveGroupsParticipantsItem = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const { id: groupsParticipantsItemId, corporateEntityId, regionalEntityId, learningCommunityId, groupId } = item;

        return dispatch(
            getGroupsParticipantsItems({
                where: [
                    ['corporateEntityId', '==', corporateEntityId],
                    ['regionalEntityId', '==', regionalEntityId],
                    ['learningCommunityId', '==', learningCommunityId],
                    ['groupId', '==', groupId],
                ],
            }),
        ).then(items => {
            if (items.length) {
                // "is unique" validation
                const isUnique =
                    items.filter(groupsParticipantsItem => {
                        if (groupsParticipantsItem.id === groupsParticipantsItemId) {
                            return false;
                        }

                        return true;
                    }).length === 0;

                if (!isUnique) {
                    return promise({
                        success: false,
                        errorMsg: 'El grupo que elegiste ya ha sido asignado a la comunidad de aprendizaje que has seleccionado.',
                    });
                }
            }

            return promise({ success: true });
        });
    };
};

export default validateOnSaveGroupsParticipantsItem;
