import { matchPath } from 'react-router-dom';

export default function isModelCourse(match) {
    const { path } = match;
    return matchPath(path, {
        path: '/admin/curso',
        exact: false,
        strict: false,
    });
}
