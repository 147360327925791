import { promise } from '../../../helpers';
import { updateLearningCommunity } from './index';

const updateLearningCommunities = props => {
    const { where } = props;

    if (where) {
        return updateLearningCommunity(props);
    }

    return promise();
};

export default updateLearningCommunities;
