import { promise } from '../../../helpers';
import { updateCourseAssignation } from './index';

const updateCoursesAssignations = props => {
    const { where } = props;

    if (where) {
        return updateCourseAssignation(props);
    }

    return promise();
};

export default updateCoursesAssignations;
