import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import RenderIf from '../render-if';
import { useOrderedMessages, useMessageGroupProfile } from './hooks';
import { StyledMessageAvatar, StyledMessageGroup } from './styled';
import ChatMessage from './message';
import { getProfile } from '../../store/actions/profiles';

function ChatMessageGroup({ messageGroup, chatParticipants }) {
    const messages = !!messageGroup && !!messageGroup.messages ? messageGroup.messages : [];
    const orderedMessages = useOrderedMessages(messages);
    const mesageGroupProfile = useMessageGroupProfile(messageGroup, chatParticipants);
    const [profile, setProfile] = useState({});
    const dispatch = useDispatch();
    const profileId = useSelector(state => state.auth.profile.id);

    useEffect(() => {
        let isCancelled = false;
        if (mesageGroupProfile.name) {
            setProfile(mesageGroupProfile);
        } else {
            dispatch(getProfile({ docId: messageGroup.profileId })).then(p => {
                if (!isCancelled) setProfile(p);
            });
        }
        return () => {
            isCancelled = true;
        };
    }, [mesageGroupProfile, messageGroup.profileId, dispatch]);

    if (messageGroup && mesageGroupProfile) {
        const { name, image } = profile;
        let { isSender } = profile;
        if (!isSender && profileId === messageGroup.profileId) {
            isSender = true;
        }

        return (
            <>
                <StyledMessageGroup className="d-flex" sender={isSender}>
                    <div className="pt-4">
                        <StyledMessageAvatar name={name} imgSrc={image} sender={isSender} size="small" />
                    </div>

                    {/* messages */}
                    <RenderIf isTrue={!!orderedMessages.length}>
                        <div>
                            <p>{name}</p>
                            {orderedMessages.map((m, index) => {
                                const key = `chat-message-${index}`;
                                const { message, date } = m;

                                return <ChatMessage key={key} isSender={isSender} message={message} date={date} />;
                            })}
                        </div>
                    </RenderIf>
                </StyledMessageGroup>
            </>
        );
    }

    return <></>;
}

ChatMessageGroup.propTypes = {
    messageGroup: PropTypes.any,
    chatParticipants: PropTypes.any,
};

ChatMessageGroup.defaultProps = {
    messageGroup: undefined,
    chatParticipants: undefined,
};

export default ChatMessageGroup;
