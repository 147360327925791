import { promise } from '../../../helpers';
import { getCourseAssignation } from '../courses-assignations';
import { getCourseLicense } from './index';

const getCourseAvailableLicensesBy = ({ by, courseId, corporateEntityId, regionalEntityId, learningCommunityId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // available licenses of a course assigned to a learning community
        if (by === 'learning-community' && corporateEntityId && courseId && learningCommunityId) {
            return dispatch(
                getCourseLicense({
                    where: [
                        ['corporateEntityId', '==', corporateEntityId],
                        ['courseId', '==', courseId],
                        ['leaningCommunityId', '==', learningCommunityId],
                        ['type', '==', 'learning-community'],
                    ],
                }),
            ).then(item => {
                if (item && item.licenses) {
                    return promise(item.licenses);
                }

                return promise(0);
            });
        }

        // available licenses of a course assigned to a regional entity
        if (by === 'regional-entity' && corporateEntityId && courseId && regionalEntityId) {
            return dispatch(
                getCourseLicense({
                    where: [
                        ['corporateEntityId', '==', corporateEntityId],
                        ['courseId', '==', courseId],
                        ['regionalEntityId', '==', regionalEntityId],
                        ['type', '==', 'regional-entity'],
                    ],
                }),
            ).then(item => {
                if (item && item.licenses) {
                    return promise(item.licenses);
                }

                return promise(0);
            });
        }

        // available licenses of a course assigned to a corporate entity
        if (by === 'corporate-entity' && corporateEntityId && courseId) {
            return dispatch(
                getCourseAssignation({
                    where: [
                        ['corporateEntityId', '==', corporateEntityId],
                        ['courseId', '==', courseId],
                    ],
                }),
            ).then(item => {
                if (item && item.licenses) {
                    return promise(item.licenses);
                }

                return promise(0);
            });
        }

        // ...temporal (used in facilitators groups assignations)
        if (by === 'learning-community' && corporateEntityId) {
            return dispatch(
                getCourseAssignation({
                    where: [['corporateEntityId', '==', corporateEntityId]],
                }),
            ).then(item => {
                if (item && item.licenses) {
                    return promise(item.licenses);
                }

                return promise(0);
            });
        }

        return promise(0);
    };
};

export default getCourseAvailableLicensesBy;
