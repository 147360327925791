import { awaitRequests, promise, objectKeysToArray } from '../../../helpers';
import { firebaseUpdate } from '../firebase';
import { addCourseLicense, getCourseLicense } from './index';

const updateCoursesLicensesBy = ({ by, coursesLicenses, regionalEntityId, learningCommunityId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const prepareItems = () => {
            if (Array.isArray(coursesLicenses)) {
                return coursesLicenses;
            }

            return objectKeysToArray(coursesLicenses);
        };

        // -- by learning-community
        if (by === 'learning-community' && learningCommunityId && coursesLicenses) {
            return awaitRequests(
                prepareItems().map(item => {
                    const { itemId, itemType, corporateEntityId, courseId } = item;

                    return dispatch(
                        getCourseLicense({
                            where: [
                                ['corporateEntityId', '==', corporateEntityId],
                                ['courseId', '==', courseId],
                                ['learningCommunityId', '==', learningCommunityId],
                                ['itemId', '==', itemId],
                                ['itemType', '==', itemType],
                                ['type', '==', 'learning-community'],
                            ],
                        }),
                    ).then(license => {
                        const data = {
                            ...item,
                            learningCommunityId,
                            type: 'learning-community',
                        };

                        if (license) {
                            return dispatch(
                                firebaseUpdate({
                                    collection: 'coursesLicenses',
                                    docId: license.id,
                                    data,
                                }),
                            );
                        }

                        return dispatch(addCourseLicense({ data }));
                    });
                }),
            );
        }

        // -- by regional-entity
        if (by === 'regional-entity' && regionalEntityId && coursesLicenses) {
            return awaitRequests(
                prepareItems().map(courseAssignation => {
                    const { itemId, itemType, corporateEntityId, courseId } = courseAssignation;

                    return dispatch(
                        getCourseLicense({
                            where: [
                                ['corporateEntityId', '==', corporateEntityId],
                                ['courseId', '==', courseId],
                                ['regionalEntityId', '==', regionalEntityId],
                                ['itemId', '==', itemId],
                                ['itemType', '==', itemType],
                                ['type', '==', 'regional-entity'],
                            ],
                        }),
                    ).then(license => {
                        const data = {
                            ...courseAssignation,
                            regionalEntityId,
                            type: 'regional-entity',
                        };

                        if (license) {
                            return dispatch(
                                firebaseUpdate({
                                    collection: 'coursesLicenses',
                                    docId: license.id,
                                    data,
                                }),
                            );
                        }

                        return dispatch(addCourseLicense({ data }));
                    });
                }),
            );
        }

        return promise();
    };
};

export default updateCoursesLicensesBy;
