import { findParticipantsProfilesByIds } from './index';

const findParticipantProfileById = participantId => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        return dispatch(findParticipantsProfilesByIds([participantId]));
    };
};

export default findParticipantProfileById;
