import { getTeamTasks, addTeamTask } from '../teamTasks';
import { getGroupsParticipantsItem } from '../groups-participants';
import { exportActivities } from './index';

const exportTeamTasks = data => {
    return exportActivities({
        ...data,
        getter: getTeamTasks,
        adder: item => {
            return async dispatch => {
                // necesitamos meter la tarea en un solo equipo
                const ids = ['team1'];
                const { participantsIds = [] } = await dispatch(
                    getGroupsParticipantsItem({ where: [['groupId', '==', data.toGroupId]] }),
                );
                const parts = participantsIds.map(pId => ({ team: 'team1', participantId: pId }));
                return dispatch(addTeamTask({ data: item.data, teams: { ids, data: parts } }));
            };
        },
    });
};

export default exportTeamTasks;
