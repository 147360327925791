import { promise } from '../../../helpers';

const validateOnSaveTask = () => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        return promise({ success: true });
    };
};

export default validateOnSaveTask;
