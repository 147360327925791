import { promise } from '../../../helpers';
import { getCoursesLicenses } from './index';

const getCourseUsedLicensesBy = ({ filterBy, courseId, corporateEntityId, courseLicenseId }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // -- count licenses
        const countUsedLicenses = items => {
            // eslint-disable-next-line
            let usedLicenses = 0;

            items.forEach(item => {
                if (!courseLicenseId || (courseLicenseId && item.id !== courseLicenseId)) {
                    usedLicenses += (item && item.licenses) || 0;
                }
            });

            return usedLicenses;
        };

        // -- get courses licenses usage for a regional entity
        if (filterBy === 'regional-entity' && courseId && corporateEntityId) {
            return dispatch(
                getCoursesLicenses({
                    where: [
                        ['courseId', '==', courseId],
                        ['corporateEntityId', '==', corporateEntityId],
                        ['type', '==', 'learning-community'],
                    ],
                }),
            ).then(items => promise(countUsedLicenses(items)));
        }

        // -- get courses licenses usage for a corporate entity (the licenses saved for a course and a regional entity)
        if (filterBy === 'corporate-entity' && courseId && corporateEntityId) {
            return dispatch(
                getCoursesLicenses({
                    where: [
                        ['courseId', '==', courseId],
                        ['corporateEntityId', '==', corporateEntityId],
                        ['type', '==', 'regional-entity'],
                    ],
                }),
            ).then(items => promise(countUsedLicenses(items)));
        }

        return promise(0);
    };
};

export default getCourseUsedLicensesBy;
