export default function getItemsFromSnapshot(snapshot) {
    const isValidSnapshot = snapshot && snapshot.docs && snapshot.docs.length;

    if (isValidSnapshot) {
        const rows = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
        });

        return rows;
    }

    return [];
}
