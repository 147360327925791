import { promise } from '../../../helpers';
import { firebaseUpdateFiles } from '../firebase';

const saveItineraryFile = ({ item, uploadList }) => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        // --
        if (uploadList && uploadList.length) {
            // --

            return dispatch(firebaseUpdateFiles({ collection: 'itineraries', docId: item.id, uploadList })).then(
                uploadedFilesData => {
                    if (uploadedFilesData) {
                        return promise({
                            ...item,
                            uploadedFilesData,
                        });
                    }

                    return promise(item);
                },
            );
        }

        return promise(item);
    };
};

export default saveItineraryFile;
