import { promise } from '../../../helpers';
import { getDocFromSnapshot } from '../../../helpers/firebase';

const getCorporateEntitiesCounters = (docId, entityId) => {
    return (dispatch, getState, getFirebase) => {
        if (docId && entityId) {
            return getFirebase()
                .firestore()
                .collection('counters')
                .doc('participantsLoginByCorporateEntity')
                .collection(entityId)
                .doc(docId)
                .get()
                .then(snapshot => promise(getDocFromSnapshot(snapshot)));
        }

        return promise(null);
    };
};

export default getCorporateEntitiesCounters;
