import { promise } from '../../../helpers';
import { getCountries } from './index';

const validateOnSaveCountry = item => {
    // eslint-disable-next-line
    return (dispatch, getState, getFirebase) => {
        const { id: countryId, name } = item;

        return dispatch(
            getCountries({
                where: [['name', '==', name]],
            }),
        ).then(items => {
            if (items.length) {
                // "is unique" validation
                const isUnique =
                    items.filter(country => {
                        if (country.id === countryId) {
                            return false;
                        }

                        return true;
                    }).length === 0;

                if (!isUnique) {
                    return promise({
                        success: false,
                        errorMsg: 'El nombre del país ya está siendo utilizado.',
                    });
                }
            }

            return promise({ success: true });
        });
    };
};

export default validateOnSaveCountry;
